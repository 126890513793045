define("pilots/components/validated-checkbox-auto-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ValidatedCheckboxAutoSaveComponent;
  ValidatedCheckboxAutoSaveComponent = Ember.Component.extend({
    classNames: ['roundCheckbox'],
    didInsertElement: function () {
      return this.element.querySelectorAll('input').attr('class', null);
    },
    actions: {
      validateProperty: function (changeset, property) {
        return changeset.validate(property).then(function () {
          if (changeset.get('isValid')) {
            return changeset.save();
          }
        });
      }
    }
  });
  var _default = ValidatedCheckboxAutoSaveComponent;
  _exports.default = _default;
});
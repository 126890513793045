define("pilots/components/pilot-log", ["exports", "pilots/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    didRender() {
      return (0, _jquery.default)('.top-row').on('click', e => {
        return this.toggleMissionListing(e);
      });
    },
    toggleMissionListing(e) {
      e.stopImmediatePropagation();
      if ((0, _jquery.default)(e.target).text().trim() === "(setup stripe)") return;
      const element = (0, _jquery.default)(e.target).closest('.mission-listing').children('.expanded-mission');
      element.css('display') === 'none' ? element.css('display', 'flex') : element.css('display', 'none');
      return this.get('metrics').trackEvent({
        event: 'event',
        category: 'Completed Missions',
        action: 'click',
        label: "Toggle Mission"
      });
    },
    actions: {
      setUpStripe: function () {
        this.set('pendingRequest', true);
        return _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/pilots/account/payment_accounts`,
          type: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(response => {
          this.set('pendingRequest', false);
          window.open(response.stripe_link, "_self");
        }, response => {
          console.log('fail: response ', response);
          this.set('pendingRequest', false);
        });
      }
    }
  });
  _exports.default = _default;
});
define("pilots/components/pilot-mission-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global moment */
  var _default = Ember.Component.extend({
    classNames: ['mission-list-data'],
    missionPage: 0,
    sortMissions: function (a, b) {
      const missionOrder = ['pilots_notified', 'pilot_accepted', 'flight_complete', 'assets_uploaded'];
      if (a.get('status') === b.get('status')) {
        if (a.get('status') === 'pilots_notified') {
          if (a.get('mission_type') !== b.get('mission_type')) {
            if (a.get('mission_type') === 'training') {
              return 1;
            }
            return -1;
          }
        }
        if (a.get('scheduled_at_start') && b.get('scheduled_at_start')) {
          return moment(a.get('scheduled_at_start')).valueOf() - moment(b.get('scheduled_at_start')).valueOf();
        }
        if (a.get('scheduled_at_start')) {
          return 1;
        }
        if (a.get('fly_by_date') && b.get('fly_by_date')) {
          return moment(a.get('fly_by_date')).valueOf() >= moment(b.get('fly_by_date')).valueOf() ? 1 : -1;
        }
        return -1;
      }
      return missionOrder.indexOf(a.get('status')) - missionOrder.indexOf(b.get('status'));
    },
    filterMissions: function (mission) {
      return mission.get('status') !== 'created' && !Ember.isPresent(mission.get('work_order')) && mission.get('status') !== 'canceled' && !mission.get('sla_expired');
    },
    sortedMissions: Ember.computed('missions', 'trainingMissions', function () {
      return this.missions.toArray().concat(this.trainingMissions ? this.trainingMissions : []).filter(this.filterMissions).sort(this.sortMissions);
    }),
    missionPages: Ember.computed('sortedMissions', function () {
      let missionPages = [];
      let i = 0;
      let j = 0;
      while (j < this.get('sortedMissions').length / 10) {
        missionPages.push([]);
        j++;
      }
      j = 0;
      while (j * 10 + i < this.get('sortedMissions').length) {
        missionPages[j].push(this.get('sortedMissions')[j * 10 + i]);
        i++;
        if (i >= 10) {
          i = 0;
          j++;
        }
      }
      return missionPages;
    }),
    displayMissions: Ember.computed('missionPages', 'missionPage', function () {
      return this.get('missionPages')[this.get('missionPage')];
    }),
    displayMissionsMobile: Ember.computed('missionPages', 'missionPage', function () {
      let i = 0;
      let missions = [];
      while (i <= this.get('missionPage')) {
        missions = missions.concat(this.get('missionPages')[i]);
        i++;
      }
      return missions;
    }),
    needMorePages: Ember.computed('sortedMissions', function () {
      return this.get('sortedMissions').length > 10;
    }),
    actions: {
      changePage: function (pageNumber) {
        this.set('missionPage', pageNumber);
        (0, _jquery.default)(".mission-page").removeClass('selected');
        (0, _jquery.default)(`#mission-page${pageNumber}`).addClass('selected');
      },
      showMoreMissions: function () {
        this.set('missionPage', this.get('missionPage') + 1);
      },
      deleteMission: function (mission) {
        this.deleteAction(mission);
      }
    }
  });
  _exports.default = _default;
});
define("pilots/validations/pilot", ["exports", "ember-changeset-validations/validators", "pilots/validations/custom_validators/boolean"], function (_exports, _validators, _boolean) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'email': (0, _validators.validateFormat)({
      type: 'email',
      message: 'Email must be valid'
    }),
    'first_name': (0, _validators.validatePresence)(true),
    'last_name': (0, _validators.validatePresence)(true),
    'address': (0, _validators.validatePresence)({
      presence: true,
      message: 'Please select your address from the search results'
    }),
    'city': (0, _validators.validatePresence)({
      presence: true,
      message: 'Please enter city'
    }),
    'country': (0, _validators.validatePresence)({
      presence: true,
      message: 'Please enter country'
    }),
    'longitude': (0, _validators.validatePresence)({
      presence: true,
      message: 'Please select your address from the search results'
    }),
    'latitude': (0, _validators.validatePresence)({
      presence: true,
      message: 'Please select your address from the search results'
    }),
    'terms_and_conditions': (0, _boolean.default)('Please agree to our Terms and Conditions')
  };
  _exports.default = _default;
});
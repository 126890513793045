define("pilots/adapters/image", ["exports", "pilots/adapters/application", "pilots/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ImageAdapter;
  ImageAdapter = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + "/" + _environment.default.api.namespace + "/pilots",
    urlTemplate: '{+namespace}/missions/{missionId}/images{/id}',
    urlSegments: {
      missionId: function (type, id, snapshot, query) {
        if (query === void 0) {
          return snapshot.belongsTo('mission', {
            id: true
          });
        } else {
          return query.missionId;
        }
      }
    }
  });
  var _default = ImageAdapter;
  _exports.default = _default;
});
define("pilots/components/image-asset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ImageAssetComponent;
  ImageAssetComponent = Ember.Component.extend({
    tagName: '',
    thumbnailObserver: Ember.observer('image.file.thumbnail', function () {
      return this.get('image').setProperties({
        generatingNativeThumbnail: false,
        nativeThumbnail: this.get('image.file.thumbnail')
      });
    }),
    actions: {
      deleteAsset: function (asset, proxyAsset) {
        if (window.confirm('Are you sure? This will permanently remove this asset.')) {
          this.deselectAll();
          return asset.destroyRecord().then(function (response) {
            return window.location.reload(true);
          }, function (response) {
            return alert('There was an issue deleting this asset');
          });
        }
      }
    }
  });
  var _default = ImageAssetComponent;
  _exports.default = _default;
});
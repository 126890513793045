define("pilots/adapters/pilot", ["exports", "pilots/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotAdapter;
  PilotAdapter = _application.default.extend({
    accountUrl: function () {
      return this.urlPrefix() + "/pilots/account";
    },
    updateRecord: function (store, type, snapshot) {
      var data, serializer;
      data = {};
      serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      return this.ajax(this.accountUrl(), 'PATCH', {
        data: data
      });
    },
    findRecord: function () {
      return this.ajax(this.accountUrl(), 'GET');
    }
  });
  var _default = PilotAdapter;
  _exports.default = _default;
});
define("pilots/adapters/license", ["exports", "pilots/adapters/application", "pilots/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LicenseAdapter;
  LicenseAdapter = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + "/" + _environment.default.api.namespace + "/public",
    urlTemplate: '{+namespace}/licenses'
  });
  var _default = LicenseAdapter;
  _exports.default = _default;
});
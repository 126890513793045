define("pilots/mixins/s3-asset-uploads", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    sessionAccount: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    headers: {},
    lookupAsset: function (fileType, file) {
      var asset, store;
      asset = null;
      store = this.get('store');
      store.peekAll(fileType).filter(function (a) {
        if (a.get('id') === file.get('id')) {
          return asset = a;
        }
      });
      return asset;
    },
    actions: {
      addAsset: function (file, shot_id, model, _timecode) {
        const queue = this;
        const store = queue.get('store');
        const mission_id = model.mission ? model.mission.id : model.id;
        if (file != null) {
          const fileType = file.get('type').match(/video|image/)[0];
          const asset = queue.lookupAsset(fileType, file);
          return file.completeUpload(mission_id, shot_id, fileType, this.get('sessionAccount.headers')).then(function (response) {
            var shot;
            if (asset.get('shot.id')) {
              shot = asset.get('shot');
            } else if (response.data.relationships.shot.data) {
              shot = store.peekRecord('shot', response.data.relationships.shot.data.id);
            } else {
              shot = null;
            }
            const newAsset = store.createRecord(asset.get('constructor.modelName'), Ember.merge(asset.toJSON(), {
              id: response.data.id,
              name: response.data.attributes.name,
              uploaded: true,
              nativeThumbnail: asset.nativeThumbnail,
              mission: asset.get('mission'),
              shot: shot
            }));
            asset.deleteRecord();
            if (newAsset.get('mission.id')) {
              newAsset.saveAsUpdate();
            }
          }, function (error) {
            console.error("Error uploading: " + error);
          });
        }
      },
      removeAsset: function (uploader, file) {
        return uploader.removeFile(file.content);
      },
      startUpload: function (uploader) {
        return uploader.start();
      },
      willTransition: function () {
        this.set('headers', {});
        return this.set('s3SignedUrls', {});
      }
    }
  });
  _exports.default = _default;
});
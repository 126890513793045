define("pilots/templates/components/moment-format-local-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ObyyhCJM",
    "block": "[[[1,[28,[35,0],[[33,1],[33,2]],[[\"timeZone\"],[[33,3]]]]],[1,\"\\n\"]],[],false,[\"moment-format\",\"model\",\"format\",\"timezone\"]]",
    "moduleName": "pilots/templates/components/moment-format-local-time.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/pilot-dashboard", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    store: Ember.inject.service(),
    perPage: 50,
    blogUrl: _environment.default.BLOG_URL,
    trainingMissions: Ember.computed('model.missions', function () {
      if (!this.get('model.missions')) {
        return [];
      }
      return this.get('model.missions').filter(mission => mission.get('mission_type') === 'training').filter(mission => mission.get('status') !== 'rejected' && mission.get('status') !== 'canceled' && !mission.get('sla_expired'));
    }),
    showMapView: false,
    filterClientMission: function (mission) {
      return mission.mission_type === 'client';
    },
    clientMissions: Ember.computed('model.missions', function () {
      if (!this.get('model.missions')) {
        return [];
      }
      return this.get('model.missions').filter(this.filterClientMission);
    }),
    init() {
      this._super(...arguments);
      if (this.get('model.missions.meta.total_pages') > 1) {
        for (let i = 2; i <= this.get('model.missions.meta.total_pages'); i++) {
          this.get('store').query('mission', {
            status: 'available,pilot_accepted,flight_complete,assets_uploaded',
            per_page: this.get('perPage'),
            page: i
          }).then(res => {
            return this.set('clientMissions', this.clientMissions.concat(res.filter(this.filterClientMission)));
          });
        }
      }
    },
    showNoMissionsInfoGraph: Ember.computed('model.pilot.total_client_missions.[]', 'model.pilot.total_training_missions.[]', 'model.pilot.missing_profile_data', function () {
      return !this.get('model.pilot.missing_profile_data') && (this.get('model.pilot.total_client_missions') || this.get('model.pilot.total_training_missions'));
    }),
    actions: {
      showMapView(missionList, missionType) {
        this.set('mapViewMissions', missionList);
        this.set('mapViewMissionType', missionType);
        return this.set('showMapView', true);
      },
      hideMapView() {
        return this.set('showMapView', false);
      },
      deleteMission(mission) {
        if (mission.get('mission_type') === 'client' || mission.get('mission_type') === 'training') {
          return _jquery.default.ajax({
            url: `${_environment.default.api.host}/v1/pilots/missions/${mission.id}/invitation`,
            type: 'DELETE',
            dataType: 'json',
            headers: this.get('sessionAccount.headers')
          }).then(() => {
            mission.deleteRecord();
            return this.get('model.missions').removeObject(mission);
          }, response => console.log('fail: response ', response));
        } else {
          return _jquery.default.ajax({
            url: `${_environment.default.api.host}/v2/pilots/missions/${mission.id}`,
            type: 'DELETE',
            dataType: 'json',
            headers: this.get('sessionAccount.headers')
          }).then(() => {
            mission.deleteRecord();
            return this.get('model.missions').removeObject(mission);
          }, function () {
            alert("Something went wrong. Please try again or contact: pilots@zeitview.com");
          });
        }
      }
    }
  });
  _exports.default = _default;
});
define("pilots/components/pilot-profile-address-lookup", ["exports", "pilots/mixins/google-init-mixin"], function (_exports, _googleInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileAddressLookupComponent;
  PilotProfileAddressLookupComponent = Ember.Component.extend(_googleInitMixin.default, {
    classNames: ['form-group'],
    formattedAddress: Ember.computed('model', function () {
      return ['address', 'city', 'state', 'postal_code', 'country'].map(function (_this) {
        return function (field) {
          return _this.get("model." + field);
        };
      }(this)).filter(function (obj) {
        return obj;
      }).join(', ');
    }),
    afterGoogleLoaded: function () {
      var autocomplete, input;
      input = this.element.querySelectorAll('input')[0];
      autocomplete = new google.maps.places.SearchBox(input);
      google.maps.event.addDomListener(input, 'keydown', function (e) {
        if (e.keyCode === 13) {
          return e.preventDefault();
        }
      });
      return autocomplete.addListener('places_changed', function (_this) {
        return function () {
          var address_components, city, defaultCityField, place, ukAndSwedenCityField;
          place = autocomplete.getPlaces()[0];
          if (!(place && place.geometry)) {
            _this.set('error', 'Not a valid address. Please try again.');
            _this.set('showError', true);
            return;
          }
          address_components = {};
          Em.$.each(place.address_components, function (k, v1) {
            Em.$.each(v1.types, function (k2, v2) {
              address_components[v2] = v1;
            });
          });
          defaultCityField = address_components.locality || address_components.administrative_area_level_3 || address_components.neighborhood;
          ukAndSwedenCityField = address_components.postal_town;
          if (address_components.street_number && address_components.route) {
            _this.set('changeset.address', address_components.street_number.long_name + " " + address_components.route.long_name);
          } else if (address_components.route) {
            _this.set('changeset.address', address_components.route.long_name);
          } else {
            _this.set('changeset.address', null);
          }
          if (city = defaultCityField || ukAndSwedenCityField) {
            _this.set('changeset.city', city.long_name);
          } else {
            _this.set('changeset.city', null);
          }
          if (address_components.administrative_area_level_1) {
            _this.set('changeset.state', address_components.administrative_area_level_1.long_name);
          } else {
            _this.set('changeset.state', null);
          }
          if (address_components.country) {
            _this.set('changeset.country', address_components.country.short_name);
          } else {
            _this.set('changeset.country', null);
          }
          if (address_components.postal_code) {
            _this.set('changeset.postal_code', address_components.postal_code.long_name);
          } else {
            _this.set('changeset.postal_code', null);
          }
          _this.set('changeset.longitude', place.geometry.location.lng());
          _this.set('changeset.latitude', place.geometry.location.lat());
          ['address', 'city', 'country', 'longitude', 'latitude'].forEach(function (field) {
            return _this.get('changeset').validate(field);
          });
          if (_this.get('changeset.isValid')) {
            return _this.get('changeset').save();
          }
        };
      }(this));
    },
    actions: {
      showError: function () {
        return this.set('showError', true);
      },
      checkEmpty: function () {
        if (this.element.querySelectorAll('input')[0].value.trim() === '') {
          this.set('changeset.address', null);
          return this.get('changeset').validate('address');
        }
      }
    }
  });
  var _default = PilotProfileAddressLookupComponent;
  _exports.default = _default;
});
define("pilots/components/mission-weather-forecast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    forecast: null,
    withBoundValues: false
  });
  _exports.default = _default;
});
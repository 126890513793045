define("pilots/components/coverage-check/uploader", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: 'gps-uploader-container',
    filesAdded: 0,
    filesProcessed: 0,
    failedImages: Ember.A([]),
    didInsertElement: function () {
      this._super();
      this.set('failedImages', Ember.A([]));
      Ember.run.scheduleOnce('afterRender', this, 'initDragDrop');
    },
    initDragDrop: function (target) {
      let elementName = target || this.get('shot_type') || 'upload-target';
      elementName = `.${elementName}`;
      let uploadElement = (0, _jquery.default)(elementName);
      uploadElement.on('drag dragend dragover dragenter dragleave drop', function (e) {
        e.preventDefault();
        e.stopPropagation();
      }).on('dragover dragenter', function (e) {
        // # Don't allow drags from within the page, only from the desktop
        if (e.dataTransfer.types.length !== 3) {
          uploadElement.addClass('is-dragover');
        }
      }).on('dragleave dragend drop', function () {
        uploadElement.removeClass('is-dragover');
      }).on('drop', e => this.addFiles(e.originalEvent.dataTransfer.files));
    },
    addFiles: function (droppedFiles) {
      const filesAdded = this.get('filesAdded');
      this.set('filesAdded', filesAdded + droppedFiles.length);
      const _this = this;
      for (const file of droppedFiles) {
        const bin = new FileReader();
        bin.onloadend = function () {
          try {
            _this.incrementProperty('filesProcessed');
            var data = new JpegMeta.JpegFile(this.result, this.file.name);
            var replaceFirst = 2;
            var date = data.tiff.DateTime.value.replace(/:/g, s => replaceFirst && replaceFirst-- && '/' || s);
            _this.get('images').pushObject({
              name: `${_this.get('images').length}-${data.filename}`,
              showName: data.filename,
              taken_at: date,
              gps_latitude: data.gps.latitude.value,
              gps_longitude: data.gps.longitude.value
            });
            if (!_this.get('mission.passed_gps_check')) {
              _this.logGpsCheckPassed();
              _this.set('mission.passed_gps_check', true);
            }
          } catch (a) {
            _this.get('failedImages').addObject(this.file.name);
          }
        };
        bin.onerror = function () {
          _this.get('failedImages').addObject(this.file.name);
        };
        bin.file = file;
        bin.readAsBinaryString(file);
      }
    },
    progress: Ember.computed('filesAdded', 'filesProcessed', function () {
      const percent = this.get('filesProcessed') / this.get('filesAdded') || 0;
      return Math.floor(percent * 100);
    }),
    showInstructions: Ember.computed('filesAdded', function () {
      return this.get('filesAdded') === 0;
    }),
    isProcessing: Ember.computed('filesAdded', 'progress', function () {
      return this.get('filesAdded') > 0 && this.get('progress') < 100;
    }),
    setProcessingCompleted: Ember.observer('progress', 'isProcessing', function () {
      this.set('processingCompleted', !this.get('showInstructions') && !this.get('isProcessing'));
    }),
    hideFailedImages: Ember.computed('isProcessing', 'failedImages.length', function () {
      return this.get('isProcessing') || this.get('failedImages.length') === 0;
    }),
    actions: {
      addAssetsButton: function (event) {
        this.addFiles(event.target.files);
      },
      closeFailedImages: function () {
        this.set('failedImages', Ember.A([]));
      }
    }
  });
  _exports.default = _default;
});
define("pilots/components/pilot-profile-device", ["exports", "pilots/config/environment", "ember-changeset"], function (_exports, _environment, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileDeviceComponent;
  PilotProfileDeviceComponent = Ember.Component.extend({
    pilotDeviceNumber: Ember.computed('index', {
      get: function () {
        if (this.get('index') >= 0) {
          return this.get('index') + 1;
        }
      },
      set: function (key, value) {
        return this._pilotDroneNumber = value;
      }
    }),
    actions: {
      destroy: function (device) {
        this.set('pilotDeviceNumber', this.get('pilotDeviceNumber') - 1);
        return this.destroyDevice(device);
      }
    }
  });
  var _default = PilotProfileDeviceComponent;
  _exports.default = _default;
});
define("pilots/helpers/titleize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.titleize = titleize;
  function titleize(params) {
    const [string] = params;
    if (!string) {
      return '';
    }
    return string.split('_').map(st => st.charAt(0).toUpperCase() + st.slice(1)).join(' ');
  }
  var _default = Ember.Helper.helper(titleize);
  _exports.default = _default;
});
define("pilots/templates/components/collapsed-list-basic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GrUyd91V",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,2],[14,0,\"large-list-item\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null],[41,[51,[33,4]],[[[42,[28,[37,1],[[28,[37,1],[[33,5]],null]],null],null,[[[1,\"    \"],[10,2],[14,0,\"small-list-item\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null]],[]],null],[41,[33,7],[[[1,\"  \"],[11,0],[24,0,\"toggle-collapse-list-basic\"],[4,[38,8],[[30,0],\"toggleCollapsedState\"],null],[12],[1,\"\\n\"],[41,[33,4],[[[1,\"      See full list\\n\"]],[]],[[[1,\"      See less\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"listItem\",\"listItem\"],false,[\"each\",\"-track-array\",\"visible\",\"unless\",\"collapsed\",\"hidden\",\"if\",\"needsCollapsing\",\"action\"]]",
    "moduleName": "pilots/templates/components/collapsed-list-basic.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/helpers/is-android", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IsAndroidHelper;
  IsAndroidHelper = Ember.Helper.helper(function () {
    var userAgent;
    userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android/i.test(userAgent);
  });
  var _default = IsAndroidHelper;
  _exports.default = _default;
});
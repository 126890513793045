define("pilots/templates/components/validated-checkbox-image-auto-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C6TzutKw",
    "block": "[[[11,\"img\"],[24,\"src\",\"/assets/images/ui/checkmark_selected.svg\"],[4,[38,0],[[30,0],\"validateProperty\",[33,1],[33,2]],null],[12],[13],[1,\"\\n\"]],[],false,[\"action\",\"changeset\",\"propertyName\"]]",
    "moduleName": "pilots/templates/components/validated-checkbox-image-auto-save.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/helpers/number-in-dollars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NumberInDollarsHelper;
  NumberInDollarsHelper = Ember.Helper.helper(function (arg) {
    var number;
    number = arg[0];
    if (number === null) {
      return Ember.String.htmlSafe("<span class='dollarsign'>$</span>0");
    }
    return Ember.String.htmlSafe("<span class='dollarsign'>$</span>" + number / 100);
  });
  var _default = NumberInDollarsHelper;
  _exports.default = _default;
});
define("pilots/routes/signupwebview", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // jshint ignore:line

  const SignupWebViewRoute = Ember.Route.extend({
    tagName: '',
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    model() {
      const urlParams = new URL(window.location).searchParams;
      const source = urlParams.get('source');
      this.controllerFor('application').set('source', source);
      const tokens = this.sessionAccount.getAuthTokens(urlParams);
      if (Ember.isPresent(tokens.password)) {
        return this.get('session').authenticate('authenticator:devise', tokens.email, tokens.password).then(() => {
          this.sessionAccount.setAuth0Tokens(tokens);
          this.transitionTo(window.location.pathname);
          console.log('authed');
          const pilot = this.store.findRecord('pilot', 'stubId');
          if (pilot.missing_profile_data) {
            this.store.findAll('pilot-license');
            this.store.findAll('pilot-drone');
            this.store.findAll('pilot-device');
            let minimumStep = 1;
            if (this.get('model.pilotDrones') && this.get('model.pilotDevices')) {
              minimumStep++;
            }
            if (this.get('model.pilotLicenses')) {
              minimumStep++;
            }
            if (minimumStep === 1) {
              this.replaceWith('signup-flow-devices', {});
            } else if (minimumStep === 2) {
              this.replaceWith('signup-flow-certification', {});
            } else {
              this.replaceWith('signup-flow-preferences', {});
            }
          } else {
            // Navigate away back into app.
          }
        });
      }
      return this.replaceWith('fourOhFour', {});
    },
    setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('hideNav', true);
      return this.controllerFor('application').set('hideNav', true);
    }
  });
  var _default = SignupWebViewRoute;
  _exports.default = _default;
});
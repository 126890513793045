define("pilots/templates/clientmissiongpscheck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qWHNLS0T",
    "block": "[[[10,0],[14,0,\"client-mission-gps-check\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"mission\",\"images\",\"processingCompleted\",\"logGpsCheckPassed\"],[[33,1,[\"mission\"]],[33,2],[33,3],[28,[37,4],[[30,0],\"logCheckPassed\"],null]]]]],[1,\"\\n\\n  \"],[1,[28,[35,5],null,[[\"mission\",\"pilot\"],[[33,1,[\"mission\"]],[33,1,[\"pilot\"]]]]]],[1,\"\\n\\n\"],[41,[33,3],[[[1,\"    \"],[1,[28,[35,7],null,[[\"images\",\"mission\",\"startCheckOver\"],[[33,2],[33,1,[\"mission\"]],[28,[37,4],[[30,0],\"startOver\"],null]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"coverage-check/uploader\",\"model\",\"images\",\"processingCompleted\",\"action\",\"mission-address\",\"if\",\"coverage-check/flight-information\"]]",
    "moduleName": "pilots/templates/clientmissiongpscheck.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
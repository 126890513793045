define("pilots/templates/components/mission-weather-forecast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KTXqf/SY",
    "block": "[[[41,[33,1],[[[41,[28,[37,2],[[33,3,[\"minTemperature\"]],[33,3,[\"maxTemperature\"]]],null],[[[1,\"    \"],[1,[28,[35,4],[[33,3,[\"minTemperature\"]],\"F\"],null]],[1,\" / \"],[1,[28,[35,4],[[33,3,[\"maxTemperature\"]],\"F\"],null]],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[33,3,[\"maxPrecipProbability\"]],[[[1,\"    \"],[1,[33,3,[\"maxPrecipProbability\"]]],[1,\" rain\"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,2],[[33,3,[\"minCloudCover\"]],[33,3,[\"maxCloudCover\"]]],null],[[[1,\"    \"],[1,[33,3,[\"minCloudCover\"]]],[1,\"-\"],[1,[33,3,[\"maxCloudCover\"]]],[1,\"% cloudy\"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,2],[[33,3,[\"minWindSpeed\"]],[33,3,[\"maxWindSpeed\"]]],null],[[[1,\"    \"],[1,[33,3,[\"minWindSpeed\"]]],[1,\"-\"],[1,[33,3,[\"maxWindSpeed\"]]],[1,\" mph wind\\n\"]],[]],null]],[]],[[[41,[33,3,[\"temperature\"]],[[[1,\"    \"],[1,[28,[35,4],[[33,3,[\"temperature\"]],\"F\"],null]],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[33,3,[\"precipProbability\"]],[[[1,\"    \"],[1,[33,3,[\"precipProbability\"]]],[1,\"% rain\"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[33,3,[\"cloudCover\"]],[[[1,\"    \"],[1,[33,3,[\"cloudCover\"]]],[1,\"% cloudy\"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[33,3,[\"windSpeed\"]],[[[1,\"    \"],[1,[33,3,[\"windSpeed\"]]],[1,\" mph wind\"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"withBoundValues\",\"and\",\"forecast\",\"temperature\"]]",
    "moduleName": "pilots/templates/components/mission-weather-forecast.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
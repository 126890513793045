define("pilots/services/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Config;
  Config = Ember.Service.extend({
    env: Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration('config:environment');
    })
  });
  var _default = Config;
  _exports.default = _default;
});
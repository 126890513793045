define("pilots/serializers/pilot", ["exports", "pilots/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotSerializer;
  PilotSerializer = _application.default.extend();
  var _default = PilotSerializer;
  _exports.default = _default;
});
define("pilots/models/video", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Video;
  Video = _emberData.default.Model.extend({
    url: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    version_urls: _emberData.default.attr(),
    processing: _emberData.default.attr('boolean'),
    source_type: _emberData.default.attr('string'),
    mission: _emberData.default.belongsTo('mission'),
    shot: _emberData.default.belongsTo('shot'),
    generatingNativeThumbnail: false,
    uploaded: false,
    file: null,
    uploadNumber: null,
    downloadUrl: Ember.computed('version_urls.{}', function () {
      return this.get('version_urls.download');
    }),
    final: Ember.computed('source_type', function () {
      return this.get('source_type') === 'edit';
    }),
    isSelected: false,
    hasProperId: Ember.computed('name', 'id', function () {
      return this.get('id').indexOf('DB') > -1;
    }),
    saveAsUpdate: function () {
      this.send('willCommit');
      this.send('didCommit');
      return this.save();
    }
  });
  var _default = Video;
  _exports.default = _default;
});
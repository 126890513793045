define("pilots/templates/clientmission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "00y9oMZN",
    "block": "[[[10,0],[14,0,\"client-mission\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"page-title\"],[12],[1,\"\\n    Mission Details\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@model\",\"@onfileadd\",\"@onstartupload\",\"@updateAction\"],[[30,1],[28,[37,1],[[30,0],[33,2],\"addAsset\"],null],[28,[37,1],[[30,0],[33,2],\"startUpload\"],null],[28,[37,1],[[30,0],[33,2],\"update\"],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@model\"],false,[\"client-mission\",\"action\",\"send\"]]",
    "moduleName": "pilots/templates/clientmission.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/templates/components/dashboard-map-filter-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "G+nsNiqq",
    "block": "[[[10,1],[15,0,[29,[[33,0,[\"fontIcon\"]],\" \",[33,0,[\"active\"]],\" \",[36,1]]]],[12],[13],[1,\"\\n\"],[1,[33,0,[\"name\"]]],[1,\"\\n\"]],[],false,[\"missionType\",\"selected\"]]",
    "moduleName": "pilots/templates/components/dashboard-map-filter-button.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/models/post-process", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    process_type: (0, _model.attr)('string'),
    slug: (0, _model.attr)('string')
  });
  _exports.default = _default;
});
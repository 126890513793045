define("pilots/helpers/addition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AdditionHelper;
  AdditionHelper = Ember.Helper.helper(function (arg) {
    var value1, value2;
    value1 = arg[0], value2 = arg[1];
    return value1 + value2;
  });
  var _default = AdditionHelper;
  _exports.default = _default;
});
define("pilots/templates/availablemission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cEwDSKHj",
    "block": "[[[10,0],[14,0,\"available-mission\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"page-title\"],[12],[1,\"\\n    Mission Request\\n  \"],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@model\",\"@acceptMission\",\"@declineMission\"],[[36,1],[28,[37,2],[[30,0],[33,3],\"acceptMission\"],null],[28,[37,2],[[30,0],[33,3],\"declineMission\"],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"available-mission\",\"model\",\"action\",\"send\"]]",
    "moduleName": "pilots/templates/availablemission.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/bulk-upload", ["exports", "jquery", "pilots/utils/w", "pilots/config/environment", "pilots/mixins/s3-asset-uploads"], function (_exports, _jquery, _w, _environment, _s3AssetUploads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_s3AssetUploads.default, {
    store: Ember.inject.service(),
    queueLookup: Ember.inject.service('upload-queue-manager'),
    classNames: 'bulk-upload',
    shot_id: null,
    fileProgress: 0,
    showNativeThumbnail: false,
    uploading: false,
    filesUploaded: 0,
    filesSelected: 0,
    timeRemaningIndex: 0,
    previousUploadTime: null,
    timeRemaining: null,
    autoSubmitMission: false,
    finishingUp: false,
    filtering: false,
    cancelled: false,
    cancelBtn: 'Cancel',
    onlyOneEmptyShot: false,
    filteringFiles: Ember.observer('queue.filteringFiles', function () {
      return this.set('filtering', this.get('queue.filteringFiles'));
    }),
    autoSubmitCheckboxSvg: Ember.computed('autoSubmitMission', function () {
      if (this.get('autoSubmitMission')) {
        return 'selected';
      } else {
        return 'unselected';
      }
    }),
    updateOnlyOneEmptyShot: Ember.observer('shot_id', function () {
      let empty = 0;
      this.get('mission.pilotShots').forEach(shot => {
        if (!shot.get('images.length') && !shot.get('videos.length') && this.get('shot_id') !== shot.id) {
          return empty = empty + 1;
        }
      });
      if (empty < 1) {
        return this.set('onlyOneEmptyShot', true);
      } else {
        this.set('onlyOneEmptyShot', false);
        return this.set('autoSubmitMission', false);
      }
    }),
    runtimes: (0, _w.default)(['html5', 'html4']),
    extensions: (0, _w.default)(),
    'max-file-size': 0,
    'no-duplicates': true,
    failedUploads: Ember.computed.reads('queue.failedUploads'),
    failedUploadsCount: Ember.computed('failedUploads', function () {
      return (this.get('failedUploads') || []).length;
    }),
    hasFailedUploads: Ember.computed.gt('failedUploadsCount', 0),
    inProgress: Ember.observer('queue.progress', function () {
      if (this.get('cancelled')) {
        return;
      }
      const queue = this.get('queue');
      const uploader = queue.get('uploader');
      if (queue && uploader && !this.finishingUp) {
        if (!this.get('numFilesUploading')) {
          this.set('numFilesUploading', queue.numFilesUploading);
        }
        this.set('filesUploaded', uploader.total.uploaded);
        this.notifyPropertyChange('filesUploaded');
        if (queue.started && queue.numFilesUploading === this.filesUploaded) {
          // last file
          this.set('finishingUp', true);
          if (this.get('autoSubmitMission')) {
            this.set('finishingUpText', 'Uploading done. Updating mission...');
            this.progressToProcessingShots(this.get('mission.id')).then(() => {
              queue.clearNumUploading();
              return this.toggleBulkUploaderModalAction();
            });
          } else {
            this.set('finishingUpText', 'Updating mission...');
            queue.clearNumUploading();
            this.toggleBulkUploaderModalAction();
          }
        }
        let progress = queue.progress;
        if (!progress || progress <= 0) {
          progress = 0;
        }
        this.set('uploadProgress', progress);
        if (this.filesUploaded !== this.get('timeRemaningIndex') && queue.numFilesUploading > 10) {
          if (!this.get('previousUploadTime')) {
            this.set('previousUploadTime', Date.now());
          }
          if (this.filesUploaded % 5 === 0) {
            this.set('timeRemaningIndex', this.filesUploaded);
            const timeUploadingInMin = Math.abs(Date.now() - this.get('previousUploadTime')) / 1000 / 60 / 5; // milli to sec to min div by 3 files
            const timeRemaingingInMin = Math.ceil(timeUploadingInMin * (queue.numFilesUploading - this.filesUploaded));
            if (!this.get('timeRemaining') || timeRemaingingInMin < this.get('timeRemaining')) {
              this.set('timeRemaining', timeRemaingingInMin);
            }
            return this.set('previousUploadTime', Date.now());
          }
        }
      }
    }),
    config: Ember.computed('elementId', 'extensions.length', 'fileFilters', 'max-file-size', 'no-duplicates', 'runtimes', 'uploadedCount', function () {
      const config = {
        disableAutoUpload: true,
        url: true,
        browse_button: "bulk-uploader",
        filters: {
          max_file_size: this.get('max-file-size'),
          prevent_duplicates: this.get('no-duplicates')
        },
        multi_selection: true,
        runtimes: this.get('runtimes').join(','),
        container: this.get('elementId'),
        uploadCount: this.get('uploadedCount')
      };
      const filters = this.get('fileFilters') || {};
      Object.keys(filters).forEach(function (filter) {
        if (this.get(filter)) {
          return config.filters[filter] = this.get(filter);
        }
      });
      if (this.get('extensions.length')) {
        config.filters.mime_types = [{
          extensions: this.get('extensions').map(ext => ext.toLowerCase()).join(',')
        }];
      }
      return config;
    }),
    didInsertElement() {
      this._super();
      return Ember.run.scheduleOnce('afterRender', this, 'attachUploadQueue');
    },
    attachUploadQueue() {
      const queueLookup = this.get('queueLookup');
      const queueName = 'bulk-uploader';
      const queue = queueLookup.findOrCreate(queueName, this, this.get('config'));
      this.set('queue', queue);
      return (0, _jquery.default)('#bulk-uploader').hide();
    },
    uploadsQueue: Ember.observer('queue.uploadsQueued', function () {
      const uploadsQueued = this.get('queue.uploadsQueued');
      if (uploadsQueued) {
        const queue = this.get('queue');
        const uploader = queue.get('uploader');
        this.set('filesSelected', uploader.files.length);
        if (this.get('filesSelected')) {
          return (0, _jquery.default)('.moxie-shim').hide();
        }
      } // hide custom file selector button
    }),
    startUploader() {
      const queue = this.get('queue');
      const uploader = queue.get('uploader');
      return Ember.run.later(function () {
        console.log('uploader started');
        return uploader.start();
      });
    },
    progressToProcessingShots(mission_id) {
      return this.updateStatusAndReload('processing_shots', mission_id);
    },
    updateStatusAndReload(newStatus, mission_id) {
      return new Promise((resolve, reject) => {
        return _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/pilots/missions/${mission_id}/status/${newStatus}`,
          type: 'PATCH',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(() => resolve(this.set('submitButtonDisabled', false)), () => reject(resolve(console.log(`failed to update status: ${newStatus} for mission: ${mission_id}`))));
      });
    },
    actions: {
      setShotId(id) {
        if (id) {
          this.set('shot_id', id);
          return (0, _jquery.default)('#bulk-uploader').show();
        } else {
          this.set('shot_id', false);
          return (0, _jquery.default)('#bulk-uploader').hide();
        }
      },
      cancel() {
        this.set('cancelBtn', 'Canceling...');
        this.set('cancelled', true);
        this.get('queue.uploader').stop();
        this.get('queue').clearNumUploading();
        this.toggleBulkUploaderModalAction();
        return window.location.reload(true);
      },
      upload() {
        this.set('uploading', true);
        const queue = this.get('queue');
        queue.forEach(filep => {
          filep.shot_id = this.get('shot_id');
          return this.addAssetAction(filep, this.get('shot_id'));
        });
        return this.startUploader();
      },
      toggleAutoSubmit() {
        return this.toggleProperty('autoSubmitMission');
      }
    }
  });
  _exports.default = _default;
});
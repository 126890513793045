define("pilots/templates/components/notification-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+WGYSQ2W",
    "block": "[[[10,\"td\"],[15,0,[29,[\"notification-list notification-list-icon \",[36,0]]]],[12],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[29,[[36,1]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"notification-list notification-list-content\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"notification-list-content-title \",[36,0]]]],[12],[1,\"\\n    \"],[1,[33,2,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"notification-list-content-message \",[36,0]]]],[12],[1,\"\\n    \"],[1,[33,2,[\"body\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"notification-list-content-time\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[[33,2,[\"created_at\"]],\"dddd, MMMM Do, YYYY\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"read\",\"icon\",\"model\",\"moment-format\"]]",
    "moduleName": "pilots/templates/components/notification-dropdown.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/models/flight-app", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FlightApp;
  FlightApp = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    app: _emberData.default.attr('string'),
    data_type: _emberData.default.attr('string'),
    value: _emberData.default.attr(),
    delivery_to: _emberData.default.attr('string'),
    delivery_to_url: _emberData.default.attr('string'),
    pilot_flight_description: _emberData.default.attr(''),
    pilot_delivery_description: _emberData.default.attr(''),
    pilot_flight_instruction: _emberData.default.attr(''),
    pilot_delivery_instruction: _emberData.default.attr('')
  });
  var _default = FlightApp;
  _exports.default = _default;
});
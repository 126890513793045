define("pilots/components/client-mission-upload", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  var ClientMissionComponent;
  ClientMissionComponent = Ember.Component.extend({
    tagName: '',
    sessionAccount: Ember.inject.service(),
    hideScheduling: true,
    submitButtonDisabled: false,
    callbackLink: window.location.origin + '/airmapcallback/',
    isShowingModal: false,
    orderOfSteps: ['Preflight', 'Shotlist', 'Flyapp', 'Onsite'],
    readyToSubmit: Ember.computed('model.mission.status', 'assetsClassified', function () {
      return this.get('model.mission.status') === 'assets_uploaded' && this.get('assetsClassified');
    }),
    allowedExtensions: Ember.computed('model.mission.require_raw_images', function () {
      if (this.get('model.mission.require_raw_images')) {
        return "mov mp4 dng";
      } else {
        return "mov mp4 jpg jpeg tif tiff png";
      }
    }),
    percentEncode: function (string) {
      return string.replace(/#/g, "%23").replace(/\[/g, "%5B").replace(/]/g, "%5D").replace(/&/g, "%26").replace(/'/g, "%27").replace(/=/g, "%3D");
    },
    updateStatus: function (newStatus) {
      var _data, _mission, _this, headers;
      _this = this;
      _this.set('submitButtonDisabled', true);
      _mission = this.get('model.mission');
      headers = this.get('sessionAccount.headers');
      _data = {
        pilot_comment: this.get('model.mission.pilot_comment')
      };
      return _jquery.default.ajax({
        url: _environment.default.api.host + "/v1/pilots/missions/" + (_mission.id + "/status/" + newStatus),
        type: 'PATCH',
        dataType: 'json',
        headers: headers,
        data: _data
      }).then(function (response) {
        return _mission.reload();
      }, function (response) {
        _this.set('submitButtonDisabled', false);
        return console.log('fail');
      });
    },
    actions: {
      toggleModal: function () {
        return this.set('isShowingModal', !this.get('isShowingModal'));
      },
      doneClassifying: function () {
        return this.set('assetsClassified', true);
      },
      back: function () {
        return this.set('assetsClassified', false);
      },
      addAsset: function (file, shot_id) {
        return this.onfileadd(file, shot_id, this.get('model'));
      },
      startUpload: function (uploader) {
        return this.sendAction('onstartupload', uploader);
      },
      submitMission: function () {
        return this.updateStatus('processing_shots');
      }
    }
  });
  var _default = ClientMissionComponent;
  _exports.default = _default;
});
define("pilots/controllers/pilotaccount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotAccountController;
  PilotAccountController = Ember.Controller.extend;
  var _default = PilotAccountController;
  _exports.default = _default;
});
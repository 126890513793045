define("pilots/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ApplicationSerializer;
  ApplicationSerializer = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function (attr) {
      return Ember.String.underscore(attr);
    },
    keyForRelationship: function (rawKey) {
      return Ember.String.underscore(rawKey);
    }
  });
  var _default = ApplicationSerializer;
  _exports.default = _default;
});
define("pilots/components/select-validated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SelectValidatedComponent;
  SelectValidatedComponent = Ember.Component.extend({
    content: null,
    prompt: null,
    optionValuePath: 'value',
    optionLabelPath: 'label',
    action: Ember.K,
    classNames: ['form-group'],
    selectClass: 'form-control input-lg',
    value: null,
    didInitAttrs: function (attrs) {
      this._super(attrs);
      if (!this.get('content')) {
        return this.set('content', []);
      }
    },
    didInsertElement: function () {
      return Ember.run.schedule('afterRender', this, function () {
        return this.element.querySelectorAll('select').val(this.get('value'));
      });
    },
    actions: {
      updateValue: function () {
        this.set('value', this.element.querySelectorAll('select').val());
        this.set('showError', true);
        if (this.get('value') === "") {
          return this.element.querySelectorAll('select').addClass('error');
        } else {
          return this.element.querySelectorAll('select').removeClass('error');
        }
      }
    }
  });
  var _default = SelectValidatedComponent;
  _exports.default = _default;
});
define("pilots/router", ["exports", "pilots/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    metrics: Ember.inject.service(),
    notifications: Ember.inject.service(),
    routeDidChange() {
      return Ember.run.scheduleOnce('afterRender', this, () => {
        const page = this.url;
        const title = page;
        this.metrics.trackPage({
          page,
          title
        });
        return this.notifications.findAllNotifications();
      });
    }
  });
  Router.map(function () {
    this.route('dashboard', {
      path: '/active-missions'
    });
    this.route('pilotlog', {
      path: '/pilotlog'
    });
    this.route('pilotlog', {
      path: '/completed-missions'
    });
    this.route('clientmission', {
      path: '/clientmission/:mission_id'
    });
    this.route('clientmissionupload', {
      path: '/clientmission/:mission_id/upload'
    });
    this.route('clientmissiongpscheck', {
      path: '/clientmission/:mission_id/gps-check'
    });
    this.route('availablemission', {
      path: '/availablemission/:mission_id'
    });
    this.route('profile', {
      path: '/pilotaccount'
    });
    this.route('profile', {
      path: '/profile'
    });
    this.route('pilot.new', {
      path: '/register'
    });
    this.route('login');
    this.route('authenticationCallback');
    this.route('welcome');
    this.route('welcomewebview');
    this.route('signup-flow-devices');
    this.route('signup-flow-certification');
    this.route('signup-flow-preferences');
    this.route('signupwebview');
    this.route('terms_and_conditions', {
      path: 'terms'
    });
    this.route('clientmissionintro', {
      path: '/client-missions'
    });
    this.route('fourOhFour', {
      path: '/*path'
    });
    this.route('airmapcallback', {
      path: '/airmapcallback/:mission_id'
    });
    this.route('availablemissionwebview', {
      path: '/availablemissionwebview/:mission_id'
    });
    this.route('clientmissionwebview', {
      path: '/clientmissionwebview/:mission_id'
    });
    this.route('clientmissionwebviewoffline', {
      path: '/clientmissionwebviewoffline/:mission_id'
    });
    this.route('work-orders.show', {
      path: '/work-orders/:id'
    });
    return this.route('unavailable-mission');
  });
  var _default = Router;
  _exports.default = _default;
});
define("pilots/templates/components/schedule-mission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KzyKOZLg",
    "block": "[[[10,0],[14,0,\"schedule-mission-datetime-picker calendar-only\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"subsection-header\"],[12],[1,\"\\n    \"],[1,[52,[33,1,[\"admin_scheduled\"]],\"Flight Date and Time\",\"Select Flight Date\"]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[51,[33,1,[\"admin_scheduled\"]]],[[[1,\"    \"],[10,0],[14,0,\"form-group schedule-mission-datetime-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"input-group date\"],[14,1,\"scheduleTimeDatePicker\"],[12],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"type\",\"id\",\"class\",\"placeholder\",\"value\"],[\"text\",\"dateTimePickerInput\",\"form-control schedule-mission-datetime-field\",\"MM/DD/YYYY\",[33,4]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\\n\"],[41,[33,5],[[[1,\"    \"],[1,[34,6]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[1,[28,[35,7],null,[[\"model\",\"timeslots\",\"selectedTimeSlot\",\"admin_scheduled\",\"timezoneId\"],[[33,1],[33,8],[33,9],[33,1,[\"admin_scheduled\"]],[33,1,[\"location\",\"timezone_id\"]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"model\",\"unless\",\"input\",\"selectedDate\",\"timeslotsLoading\",\"loading\",\"time-window-weather\",\"dayTimeslots\",\"selectedTimeSlot\"]]",
    "moduleName": "pilots/templates/components/schedule-mission.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
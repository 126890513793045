define("pilots/templates/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/AE0Amkp",
    "block": "[[[10,0],[14,0,\"dashboard\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[1,[54,[\"after signup\"]]],[1,\"\\n    \"],[1,[28,[35,3],[\"registrationCompleted\"],null]],[1,\"\\n    \"],[3,\" Google Code for Pilot Sign Up Conversion Page \"],[1,\"\\n    \"],[10,0],[14,5,\"display:inline;\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,\"height\",\"1\"],[14,\"width\",\"1\"],[14,5,\"border-style:none;\"],[14,\"alt\",\"\"],[14,\"src\",\"//www.googleadservices.com/pagead/conversion/956776802/?label=ZrrwCK_Mh2wQ4oKdyAM&guid=ON&script=0\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[28,[35,4],null,[[\"model\"],[[33,5]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"showRegistrationCode\",\"log\",\"clear-cookie\",\"pilot-dashboard\",\"model\"]]",
    "moduleName": "pilots/templates/dashboard.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
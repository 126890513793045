define("pilots/components/daily-message", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  var DailyMessageComponent;
  DailyMessageComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    initMessage: Ember.on('init', function () {
      var _this;
      _this = this;
      return _jquery.default.ajax({
        url: _environment.default.api.host + "/v1/pilots/daily_messages/random",
        type: 'GET',
        dataType: 'json',
        headers: _this.get('sessionAccount.headers')
      }).then(function (response) {
        return _this.set('message', response.data.attributes.message);
      });
    })
  });
  var _default = DailyMessageComponent;
  _exports.default = _default;
});
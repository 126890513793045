define("pilots/controllers/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    get showRegistrationCode() {
      return Cookies.get('registrationCompleted');
    }
  });
  _exports.default = _default;
});
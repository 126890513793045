define("pilots/helpers/moment-format-unix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MomentFormatUnixHelper;
  MomentFormatUnixHelper = Ember.Helper.helper(function (arg) {
    var format, time;
    time = arg[0], format = arg[1];
    return moment.unix(time).format(format);
  });
  var _default = MomentFormatUnixHelper;
  _exports.default = _default;
});
define("pilots/templates/components/expandable-unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yel56f9D",
    "block": "[[[10,0],[14,0,\"expandable-unit\"],[12],[1,\"\\n  \"],[11,0],[16,0,[29,[\"expandable-unit-header \",[52,[51,[33,1]],[52,[33,3],\"expanded-header\"]]]]],[4,[38,4],[[30,0],\"toggleExpanded\"],null],[12],[1,\"\\n\"],[41,[33,5],[[[1,\"      \"],[10,\"img\"],[14,0,\"step-number\"],[14,\"src\",\"/assets/images/checkmark-icon.svg\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[33,6],[[[1,\"        \"],[10,0],[14,0,\"step-number\"],[12],[1,\"\\n          \"],[1,[34,6]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[33,1],[[[1,\"        \"],[10,\"img\"],[14,0,\"icon\"],[15,\"src\",[36,1]],[12],[13],[1,\"\\n      \"]],[]],null]],[]]]],[]]],[1,\"    \"],[10,0],[15,0,[29,[\"title \",[52,[33,5],\"completed-title\"]]]],[12],[1,\"\\n      \"],[1,[34,7]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[33,8],[[[1,\"      \"],[10,\"img\"],[14,\"src\",\"/assets/images/clients/alert-icon.svg\"],[14,0,\"alert-icon\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[33,3],[[[1,\"      \"],[10,\"img\"],[14,0,\"expand-icon\"],[14,\"src\",\"/assets/images/caret-arrow-expanded.svg\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"img\"],[14,0,\"expand-icon\"],[14,\"src\",\"/assets/images/caret-arrow.svg\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[10,0],[14,0,\"content\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"unless\",\"iconSrc\",\"if\",\"expanded\",\"action\",\"completed\",\"number\",\"title\",\"actionRequired\",\"yield\"]]",
    "moduleName": "pilots/templates/components/expandable-unit.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
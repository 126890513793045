define("pilots/components/signup-select-devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SignupSelectDevices = Ember.Component.extend({
    classNames: 'device-list',
    store: Ember.inject.service(),
    pages: ['MAIN', 'DRONES', 'CAMERAS', 'DEVICES'],
    currentPage: 'MAIN',
    router: Ember.inject.service(),
    pageComplete: Ember.computed('currentSelectedDrones.[]', 'currentSelectedDevices.[]', function () {
      return this.get('currentSelectedDrones.length') && this.get('currentSelectedDevices.length');
    }),
    setupPage: Ember.on('init', function () {
      this.set('currentSelectedDrones', this.get('model.pilotDrones').toArray());
      this.set('currentSelectedDevices', this.get('model.pilotDevices').toArray());
    }),
    selectedDrones: Ember.computed('currentSelectedDrones.[]', function () {
      let selectedDrones = new Map();
      if (this.get('currentSelectedDrones')) {
        this.get('currentSelectedDrones').forEach(function (pilotDrone) {
          selectedDrones.set(pilotDrone.get('drone.full_name'), pilotDrone);
        });
      }
      return selectedDrones;
    }),
    selectedCameras: Ember.computed('currentSelectedCameras.[]', function () {
      let selectedCameras = new Map();
      if (this.get('currentSelectedCameras')) {
        this.get('currentSelectedCameras').forEach(function (camera) {
          selectedCameras.set(camera.get('name'), camera);
        });
      }
      return selectedCameras;
    }),
    selectedDevices: Ember.computed('currentSelectedDevices.[]', function () {
      let selectedDevices = new Map();
      if (this.get('currentSelectedDevices')) {
        this.get('currentSelectedDevices').forEach(function (pilotDevice) {
          selectedDevices.set(pilotDevice.get('device').get('name'), pilotDevice);
        });
      }
      return selectedDevices;
    }),
    actions: {
      showMainPage() {
        this.set('currentPage', this.get('pages')[0]);
        this.set('currentDroneChanges', []);
        this.set('currentDeviceChanges', []);
        this.setCurrentPage(this.get('currentPage'));
      },
      showDronePage() {
        this.set('currentDroneChanges', []);
        this.set('currentCameraChanges', []);
        this.set('currentPage', this.get('pages')[1]);
        this.setCurrentPage(this.get('currentPage'));
      },
      showCameraPage(pilotDrone) {
        this.set('currentPage', this.get('pages')[2]);
        this.set('currentPilotDrone', pilotDrone);
        this.set('currentSelectedCameras', pilotDrone.get('cameras'));
        this.setCurrentPage(this.get('currentPage'));
      },
      showDevicesPage() {
        this.set('currentDeviceChanges', []);
        this.set('currentPage', this.get('pages')[3]);
        this.setCurrentPage(this.get('currentPage'));
      },
      addCameras() {
        this.set('currentPilotDrone', null);
        this.send('showDronePage');
      },
      addSelectedDrones() {
        this.addSelectedDrones();
      },
      toggleDrone(drone) {
        const selectedDrones = this.get('selectedDrones');
        const currentSelectedDrones = this.get('currentSelectedDrones');
        let pilotDrone;
        const currentDroneChanges = this.get('currentDroneChanges');
        if (selectedDrones.get(drone.get('full_name'))) {
          pilotDrone = selectedDrones.get(drone.get('full_name'));
          currentDroneChanges.push({
            pilotDrone: pilotDrone,
            added: false
          });
          this.send('removeDrone', pilotDrone);
        } else {
          pilotDrone = this.get('store').createRecord('pilot-drone', {
            drone: drone,
            pilot: this.get('model.pilot')
          });
          currentSelectedDrones.addObject(pilotDrone);
          currentDroneChanges.push({
            pilotDrone: pilotDrone,
            added: true
          });
          this.set('currentSelectedDrones', currentSelectedDrones);
          if (drone.get('optional_cameras').length) {
            this.send('showCameraPage', pilotDrone);
          }
        }
        this.set('currentDroneChanges', currentDroneChanges);
      },
      removeDrone(pilotDrone) {
        const currentSelectedDrones = this.get('currentSelectedDrones');
        currentSelectedDrones.removeObject(pilotDrone);
        this.set('currentSelectedDrones', currentSelectedDrones);
        this.set('showRemoveDroneModal', false);
      },
      toggleCameras(camera) {
        const selectedCameras = this.get('selectedCameras');
        const currentSelectedCameras = this.get('currentSelectedCameras');
        const currentCameraChanges = this.get('currentCameraChanges');
        if (selectedCameras.get(camera.get('name'))) {
          currentSelectedCameras.removeObject(camera);
          currentCameraChanges.push({
            camera: camera,
            added: false
          });
        } else {
          currentSelectedCameras.addObject(camera);
          currentCameraChanges.push({
            camera: camera,
            added: true
          });
        }
        this.set('currentSelectedCameras', currentSelectedCameras);
        this.set('currentCameraChanges', currentCameraChanges);
      },
      removeDevice(device) {
        const currentSelectedDevices = this.get('currentSelectedDevices');
        currentSelectedDevices.removeObject(device);
        this.set('currentSelectedDevices', currentSelectedDevices);
      },
      toggleDevice(device) {
        const selectedDevices = this.get('selectedDevices');
        const currentSelectedDevices = this.get('currentSelectedDevices');
        const currentDeviceChanges = this.get('currentDeviceChanges');
        let pilotDevice;
        if (selectedDevices.get(device.get('name'))) {
          pilotDevice = selectedDevices.get(device.get('name'));
          currentDeviceChanges.push({
            pilotDevice: pilotDevice,
            added: false
          });
          this.send('removeDevice', pilotDevice);
        } else {
          pilotDevice = this.get('store').createRecord('pilot-device', {
            device: device,
            pilot: this.get('model.pilot')
          });
          currentSelectedDevices.addObject(pilotDevice);
          currentDeviceChanges.push({
            pilotDevice: pilotDevice,
            added: true
          });
          this.set('currentSelectedDevices', currentSelectedDevices);
        }
      },
      addDevices() {
        this.send('showMainPage');
      },
      toggleRemoveDroneModal(pilotDrone) {
        this.set('showRemoveDroneModal', !this.get('showRemoveDroneModal'));
        if (this.get('showRemoveDroneModal')) {
          this.set('pilotDroneToRemove', pilotDrone);
        } else {
          this.set('pilotDroneToRemove', null);
        }
      },
      skipAddDevice() {
        this.get('currentDeviceChanges').forEach(change => {
          if (change.added) {
            this.send('removeDevice', change.pilotDevice);
          } else {
            const currentSelectedDevices = this.get('currentSelectedDevices');
            currentSelectedDevices.addObject(change.pilotDevice);
            this.set('currentSelectedDevices', currentSelectedDevices);
          }
        });
        this.send('showMainPage');
      },
      skipAddCamera() {
        const currentSelectedCameras = this.get('currentSelectedCameras');
        this.get('currentCameraChanges').forEach(change => {
          if (change.added) {
            currentSelectedCameras.removeObject(change.camera);
          } else {
            currentSelectedCameras.addObject(change.camera);
          }
        });
        this.set('currentSelectedCameras', currentSelectedCameras);
        if (!currentSelectedCameras.length) {
          this.send('removeDrone', this.get('currentPilotDrone'));
        }
        this.send('showDronePage');
      },
      skipAddDrone() {
        this.get('currentDroneChanges').forEach(change => {
          if (change.added) {
            this.send('removeDrone', change.pilotDrone);
          } else {
            const currentSelectedDrones = this.get('currentSelectedDrones');
            currentSelectedDrones.addObject(change.pilotDrone);
            this.set('currentSelectedDrones', currentSelectedDrones);
          }
        });
        this.send('showMainPage');
      },
      save() {
        if (this.get('pageComplete')) {
          if (this.get('model.pilotDrones')) {
            this.get('model.pilotDrones').forEach(pilotDrone => {
              if (!this.get('selectedDrones').get(pilotDrone.get('drone').get('full_name'))) {
                pilotDrone.destroyRecord();
              }
            });
          }
          if (this.get('model.pilotDevices')) {
            this.get('model.pilotDevices').forEach(pilotDevice => {
              if (!this.get('selectedDevices').get(pilotDevice.get('device').get('name'))) {
                pilotDevice.destroyRecord();
              }
            });
          }
          this.get('currentSelectedDrones').forEach(pilotDrone => {
            pilotDrone.save();
          });
          this.get('currentSelectedDevices').forEach(pilotDevice => {
            if (!pilotDevice.get('id')) {
              pilotDevice.save();
            }
          });
          this.get('router').transitionTo('signup-flow-certification');
          // go to next page
        }
      },
      toggleShowSkipModal() {
        this.toggleShowSkipModal();
      }
    }
  });
  var _default = SignupSelectDevices;
  _exports.default = _default;
});
define("pilots/authenticators/admin-pretender", ["exports", "ember-simple-auth/authenticators/devise", "pilots/config/environment"], function (_exports, _devise, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AdminPretender = _devise.default.extend({
    serverTokenEndpoint: _environment.default.api.host + '/v1/impersonate',
    resourceName: 'pilot',
    authenticate(email, token, pilotEmail) {
      const useResponse = this.rejectWithResponse;
      let {
        resourceName,
        identificationAttributeName,
        tokenAttributeName
      } = this;
      const data = {};
      data[resourceName] = {
        token
      };
      data[resourceName][identificationAttributeName] = email;
      data['pilot_email'] = pilotEmail;
      return new Promise((resolve, reject) => {
        // jshint ignore:line

        return this.makeRequest(data).then(response => {
          if (response.ok) {
            return response.json().then(json => {
              if (this._validate(json)) {
                resourceName = this.resourceName;
                const _json = json[resourceName] ? json[resourceName] : json;
                _json['state'] = 'dashboard';
                return Ember.run(null, resolve, _json);
              } else {
                return Ember.run(null, reject, `Check that server response includes ${tokenAttributeName} and ${identificationAttributeName}`);
              }
            });
          } else {
            if (useResponse) {
              return Ember.run(null, reject, response);
            } else {
              return response.json().then(json => Ember.run(null, reject, json));
            }
          }
        }).catch(error => Ember.run(null, reject, error));
      });
    },
    makeRequest(data, options) {
      if (options == null) {
        options = {};
      }
      Ember.merge(options, {
        data,
        headers: {
          'X-API-TOKEN': _environment.default.api.app_token,
          'accept': 'application/json',
          'content-type': 'application/json'
        }
      });
      return this._super(data, options);
    }
  });
  var _default = AdminPretender;
  _exports.default = _default;
});
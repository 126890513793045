define("pilots/components/pilot-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotDetailsComponent;
  PilotDetailsComponent = Ember.Component.extend({
    tagName: '',
    displayedLicense: Ember.computed('licenses.[]', function () {
      if (this.get('licenses') && this.get('licenses').objectAt(0)) {
        return this.get('licenses').objectAt(0).get('license_number');
      }
    })
  });
  var _default = PilotDetailsComponent;
  _exports.default = _default;
});
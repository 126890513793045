define("pilots/components/pilot-profile-device-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileDeviceNewComponent;
  PilotProfileDeviceNewComponent = Ember.Component.extend({
    pilotDevicesNumber: Ember.computed('model.pilotDevices.length', {
      get: function () {
        return this.get('model.pilotDevices.length') + 1;
      },
      set: function (key, value) {
        return this._pilotDevicesNumber = value;
      }
    }),
    observepilotDevicesNumber: Ember.observer('model.pilotDevices.length', function () {
      return this.set('pilotDevicesNumber', this.get('model.pilotDevices.length') + 1);
    }),
    availableDevices: Ember.computed('model.pilotDevices.[]', function () {
      var pilotDevices;
      pilotDevices = [];
      this.get('model.devices').forEach(function (_this) {
        return function (device) {
          if (!_this.get('model.pilotDevices').mapBy('device').mapBy('content').includes(device)) {
            return pilotDevices.push(device);
          }
        };
      }(this));
      return pilotDevices.sort(function (a, b) {
        return a.get('name').localeCompare(b.get('name'));
      });
    }),
    cta: Ember.computed('model.pilotDevices.length', function () {
      if (this.get('model.pilotDevices.length') > 0) {
        return 'Add Another Device';
      } else {
        return 'Choose Device Type';
      }
    }),
    didInsertElement: function () {
      return this.element.querySelector('.select-device').addEventListener('click', function () {
        event.stopPropagation();
        $('.device-dropdown').toggle();
        return $('html').one('click', function () {
          return $('.device-dropdown').hide();
        });
      });
    },
    actions: {
      create: function (device) {
        $('.device-dropdown').toggle();
        return this.createDevice(device);
      }
    }
  });
  var _default = PilotProfileDeviceNewComponent;
  _exports.default = _default;
});
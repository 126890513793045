define("pilots/models/image", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-classic-classes
  var _default = _model.default.extend({
    url: (0, _model.attr)('string'),
    name: (0, _model.attr)('string'),
    processing: (0, _model.attr)('boolean'),
    version_urls: (0, _model.attr)(),
    gps_latitude: (0, _model.attr)('number'),
    gps_longitude: (0, _model.attr)('number'),
    gps_altitude: (0, _model.attr)('number'),
    mission: (0, _model.belongsTo)('mission'),
    shot: (0, _model.belongsTo)('shot'),
    isMissingGpsInfo: Ember.computed('gps_altitude', 'gps_latitude', 'gps_longitude', 'processing', function () {
      return (!this.gps_latitude || !this.gps_longitude || !this.gps_altitude) && !this.processing;
    }),
    file: null,
    uploadNumber: null,
    isSelected: false,
    generatingNativeThumbnail: false,
    nativeThumbnail: null,
    uploaded: false,
    hasProperId: Ember.computed('name', 'id', function () {
      return this.id.indexOf('DB') > -1;
    }),
    recursiveReload: function (counter) {
      if (this.cannotBeReloaded() && counter < 3) {
        return Ember.run.later(() => this.recursiveReload(counter + 1), Math.pow(2, counter) * 1000);
      } else {
        this.reload().then(response => {
          if (response.processing && counter < 3) {
            Ember.run.later(() => this.recursiveReload(counter + 1), Math.pow(2, counter) * 1000);
          }
        });
      }
    },
    cannotBeReloaded: function () {
      // looks like we can resolve issue only after update ember version, issue details:
      // Attempted to handle event 'reloadRecord' on ... while in state root.loaded.updated.inFlight.
      // https://github.com/emberjs/data/pull/5410
      const currentStateName = this.get('currentState.stateName');
      return currentStateName === 'root.loaded.updated.inFlight' || currentStateName === 'root.deleted.saved';
    },
    saveAsUpdate: function () {
      let self = this;
      this.send('willCommit');
      this.send('didCommit');
      this.save().then(response => {
        if (response.processing) {
          Ember.run.later(() => self.recursiveReload(1), 2000);
        }
      });
    }
  });
  _exports.default = _default;
});
define("pilots/components/pilot-profile-drone-cameras", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileDroneCamerasComponent;
  PilotProfileDroneCamerasComponent = Ember.Component.extend({
    hidden: true,
    selectedCameras: Ember.computed('pilotDrone.cameras.[]', function () {
      var cameras;
      cameras = [];
      this.get('pilotDrone.cameras').forEach(function (camera) {
        if (!camera.get('stock')) {
          return cameras.pushObject(camera);
        }
      });
      return cameras;
    }),
    didInsertElement: function () {
      this.element.querySelectorAll('.camera-section, .camera-dropdown-arrow').forEach(function (_this) {
        return function (el) {
          return el.addEventListener('click', function (event) {
            event.stopPropagation();
            if (event.target !== event.currentTarget) {
              return;
            }
            _this.toggleProperty('hidden');
            return $('html').one('click', function () {
              return _this.set('hidden', true);
            });
          });
        };
      }(this));
      if (this.get('pilotDrone.isNew')) {
        return this.toggleProperty('hidden');
      }
    },
    actions: {
      createCamera: function (camera) {
        this.toggleProperty('hidden');
        this.get('pilotDrone.cameras').pushObject(camera);
        return this.get('pilotDrone').save();
      },
      destroyCamera: function (camera) {
        if (this.get('pilotDrone.cameras.length') === 1) {
          alert('You need to have at least one non-stock camera for this drone.');
          return;
        }
        this.get('pilotDrone.cameras').removeObject(camera);
        return this.get('pilotDrone').save();
      }
    }
  });
  var _default = PilotProfileDroneCamerasComponent;
  _exports.default = _default;
});
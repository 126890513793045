define("pilots/models/pilot", ["exports", "ember-data", "pilots/models/rescheduler"], function (_exports, _emberData, _rescheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _rescheduler.default.extend({
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    postal_code: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    birthday: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    longitude: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('string'),
    travel_distance: _emberData.default.attr('number'),
    drone_system: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    payout_total: _emberData.default.attr('number'),
    total_client_missions: _emberData.default.attr('number'),
    total_training_missions: _emberData.default.attr('number'),
    total_panorama_missions: _emberData.default.attr('number'),
    total_ar_blocks: _emberData.default.attr('number'),
    paypal_email: _emberData.default.attr('string'),
    payment_processor: _emberData.default.attr('string'),
    first_flight_on: _emberData.default.attr('date'),
    timezone_id: _emberData.default.attr('string'),
    mission_breakdown: _emberData.default.attr(''),
    is_available_weekdays: _emberData.default.attr('boolean'),
    is_available_weekends: _emberData.default.attr('boolean'),
    missing_profile_data: _emberData.default.attr(''),
    image: _emberData.default.attr('string'),
    stripe_onboarding: _emberData.default.attr('boolean'),
    pilot_licenses: _emberData.default.hasMany('license', {
      async: false
    }),
    drones: _emberData.default.hasMany('pilot_drone', {
      async: false
    }),
    devices: _emberData.default.hasMany('pilot_device', {
      async: false
    }),
    licenses: _emberData.default.hasMany('pilot_license', {
      async: false
    }),
    opt_ins: _emberData.default.hasMany('pilot_opt_in', {
      async: false
    }),
    pilot_badges: _emberData.default.hasMany('pilot_badge', {
      async: false
    }),
    stripe_accounts: _emberData.default.hasMany('stripe_account', {
      async: false
    }),
    stripe_north_america_available: _emberData.default.attr('boolean'),
    stripe_europe_available: _emberData.default.attr('boolean'),
    terms_and_conditions: false,
    full_name: Ember.computed('first_name', 'last_name', function () {
      return `${this.get('first_name')} ${this.get('last_name')}`;
    }),
    created: Ember.computed('status', function () {
      return this.get('status') === 'created';
    }),
    approved: Ember.computed('status', function () {
      return this.get('status') === 'approved';
    }),
    rejected: Ember.computed('status', function () {
      return this.get('status') === 'rejected';
    }),
    evaluation_complete: Ember.computed('status', function () {
      return this.get('status') === 'evaluation_completed';
    }),
    displayedLicense: Ember.computed('pilot_licenses.[]', function () {
      if (this.get('pilot_licenses') && this.get('pilot_licenses').objectAt(0)) {
        return this.get('pilot_licenses').objectAt(0).get('license_number');
      }
    })
  });
  _exports.default = _default;
});
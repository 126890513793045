define("pilots/templates/components/simple-weather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QyRC7UqS",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,1],[14,0,\"temperature\"],[12],[1,[34,1]],[13],[10,1],[12],[1,\"F\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,2],[[[1,\"  \"],[10,0],[14,0,\"hidden-xs\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"icon\"],[[33,2]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"visible-xs\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"icon\",\"width\"],[[33,2],35]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"degree\",\"icon\",\"weather-icon\"]]",
    "moduleName": "pilots/templates/components/simple-weather.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/helpers/format-hour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global moment */

  let formatHourHelper = Ember.Helper.helper(function (_ref) {
    let [minutes] = _ref;
    let hour = parseInt(minutes) / 60;
    return moment(new Date().setHours(hour)).format('hA');
  });
  var _default = formatHourHelper;
  _exports.default = _default;
});
define("pilots/routes/authentication-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    model() {
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', 'stubId'),
        pilotDrones: this.store.findAll('pilot-drone'),
        pilotDevices: this.store.findAll('pilot-device'),
        pilotLicenses: this.store.findAll('pilot-license')
      });
    },
    afterModel(model) {
      const state = this.get('session').get('data.authenticated.state');
      let redirectTo = state;
      const pilot = model.pilot;
      if (state === undefined || state === '/login' || state === '/') {
        redirectTo = 'dashboard';
      }
      if (pilot.drones.length === 0 || pilot.devices.length === 0 || pilot.licenses.length === 0 || !pilot.travel_distance || !pilot.is_available_weekdays && !pilot.is_available_weekends) {
        redirectTo = 'welcome';
      }
      return this.transitionTo(redirectTo);
    }
  });
  _exports.default = _default;
});
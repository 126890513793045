define("pilots/templates/components/moment-format-local-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hB3MA0si",
    "block": "[[[1,[28,[35,0],[[33,1,[\"scheduled_at_start\"]],[33,2]],[[\"timeZone\"],[[33,1,[\"location\",\"timezone_id\"]]]]]],[1,\"\\n\"]],[],false,[\"moment-format\",\"model\",\"format\"]]",
    "moduleName": "pilots/templates/components/moment-format-local-date.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
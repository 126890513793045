define("pilots/routes/welcomewebview", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // jshint ignore:line
  var _default = Ember.Route.extend({
    tagName: '',
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    model() {
      const urlParams = new URL(window.location).searchParams;
      const source = urlParams.get('source');
      this.controllerFor('application').set('source', source);
      const tokens = this.sessionAccount.getAuthTokens(urlParams);
      if (Ember.isPresent(tokens.password)) {
        return this.get('session').authenticate('authenticator:devise', tokens.email, tokens.password).then(() => {
          this.sessionAccount.setAuth0Tokens(tokens);
          this.transitionTo(window.location.pathname);
          console.log('authed');
          return Ember.RSVP.hash({
            source,
            pilotDrones: this.store.findAll('pilot-drone')
          });
        });
      }
      return this.replaceWith('fourOhFour', {});
    },
    setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('hideNav', true);
      return this.controllerFor('application').set('hideNav', true);
    }
  });
  _exports.default = _default;
});
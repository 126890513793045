define("pilots/models/pilot-pilot-equipment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotPilotEquipment;
  PilotPilotEquipment = _emberData.default.Model.extend({
    pilot_equipment: _emberData.default.belongsTo('pilot-equipment')
  });
  var _default = PilotPilotEquipment;
  _exports.default = _default;
});
define("pilots/helpers/human-readable-filesize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HumanReadableFilesizeHelper;
  HumanReadableFilesizeHelper = Ember.Helper.helper(function (arg) {
    var i, size;
    size = arg[0];
    i = Math.floor(Math.log(size) / Math.log(1024));
    if (i < 0) {
      i = 0;
    }
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  });
  var _default = HumanReadableFilesizeHelper;
  _exports.default = _default;
});
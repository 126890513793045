define("pilots/components/pilot-profile-equipment-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileEquipmentNewComponent;
  PilotProfileEquipmentNewComponent = Ember.Component.extend({
    pilotEquipmentNumber: Ember.computed('model.pilotEquipment.length', {
      get: function () {
        return this.get('model.pilotEquipment.length') + 1;
      },
      set: function (key, value) {
        return this._pilotEquipmentNumber = value;
      }
    }),
    observepilotEquipmentNumber: Ember.observer('model.pilotEquipment.length', function () {
      return this.set('pilotEquipmentNumber', this.get('model.pilotEquipment.length') + 1);
    }),
    availableEquipment: Ember.computed('model.pilotEquipment.[]', function () {
      var pilotEquipment;
      pilotEquipment = [];
      this.get('model.equipment').forEach(function (_this) {
        return function (equipment) {
          if (!_this.get('model.pilotEquipment').mapBy('pilot_equipment').mapBy('content').includes(equipment)) {
            return pilotEquipment.push(equipment);
          }
        };
      }(this));
      return pilotEquipment.sort(function (a, b) {
        return a.get('name').localeCompare(b.get('name'));
      });
    }),
    didInsertElement: function () {
      return $('.select-equipment').on('click', function () {
        event.stopPropagation();
        $('.equipment-dropdown').toggle();
        return $('html').one('click', function () {
          return $('.equipment-dropdown').hide();
        });
      });
    },
    actions: {
      create: function (equipment) {
        $('.equipment-dropdown').toggle();
        return this.createEquipment(equipment);
      }
    }
  });
  var _default = PilotProfileEquipmentNewComponent;
  _exports.default = _default;
});
define("pilots/components/collapsed-list-basic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CollapsedListBasic;
  CollapsedListBasic = Ember.Component.extend({
    collapsed: true,
    needsCollapsing: Ember.computed('hidden', function () {
      return this.get('hidden') && this.get('hidden').length > 0;
    }),
    actions: {
      toggleCollapsedState: function () {
        return this.set('collapsed', !this.get('collapsed'));
      }
    }
  });
  var _default = CollapsedListBasic;
  _exports.default = _default;
});
define("pilots/models/mission-package", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionPackage;
  MissionPackage = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    devices: _emberData.default.hasMany("device"),
    droneCameras: _emberData.default.hasMany("drone_camera"),
    drones: _emberData.default.hasMany("drone"),
    pilotEquipments: _emberData.default.hasMany("pilot_equipment"),
    badge: _emberData.default.belongsTo("badge"),
    badge_required: _emberData.default.attr("boolean"),
    tablets: Ember.computed('devices.@each.device_type', function () {
      return this.get('devices').filter(function (device) {
        return device.get('device_type') === 'tablet';
      });
    }),
    hasRequiredDrones: Ember.computed('drones.[]', function () {
      return this.get('drones.length') > 0;
    }),
    hasRequiredEquipment: Ember.computed('pilotEquipments[]', function () {
      return this.get('pilotEquipments.length') > 0;
    }),
    hasRequiredCameras: Ember.computed('droneCameras.[]', function () {
      return this.get('droneCameras.length') > 0;
    }),
    hasRequiredDevices: Ember.computed('devices.[]', function () {
      return this.get('devices.length') > 0;
    }),
    phones: Ember.computed('devices.@each.device_type', function () {
      return this.get('devices').filter(function (device) {
        return device.get('device_type') === 'phone';
      });
    })
  });
  var _default = MissionPackage;
  _exports.default = _default;
});
define("pilots/models/legal-entity", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const COUNTRIES_PER_LEGAL_ENTITY = {
    'us': 'All countries in the Americas EXCEPT Brazil',
    'de': 'Everywhere else in the world EXCEPT India'
  };
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    displayCountries: Ember.computed('name', function () {
      return COUNTRIES_PER_LEGAL_ENTITY[this.get('slug')];
    })
  });
  _exports.default = _default;
});
define("pilots/components/reschedule-mission", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  ;
  var RescheduleMissionComponent;
  RescheduleMissionComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    reschedulingStep: 0,
    lastDate: "",
    updateScheduledAt: Ember.observer('selectedDate', 'selectedTimeSlot', function () {
      if (this.get('selectedTimeSlot')) {
        this.set('model.reschedule.scheduled_at_start', this.get('selectedTimeSlot.scheduled_at_start'));
        return this.set('model.reschedule.scheduled_at_end', this.get('selectedTimeSlot.scheduled_at_end'));
      }
    }),
    canReschedule: Ember.computed('model.reschedule', 'model.reschedule.reschedule_reason', 'notesRequires', 'model.reschedule.notes', 'model.reschedule.scheduled_at_start', 'model.reschedule.scheduled_at_end', function () {
      if (this.get('model.reschedule') && this.get('model.reschedule.reschedule_reason') && this.get('model.reschedule.reschedule_reason.id')) {
        if (this.get('model.reschedule.scheduled_at_start') && this.get('model.reschedule.scheduled_at_end')) {
          if (this.get('notesRequires')) {
            if (this.get('model.reschedule.notes.length') > 0) {
              return true;
            }
          } else {
            return true;
          }
        }
      }
      return false;
    }),
    dayTimeslots: Ember.computed('selectedDate', 'timeslots', function () {
      var i, len, ref, timeslot;
      if (this.get('timeslots')) {
        ref = this.get('timeslots');
        for (i = 0, len = ref.length; i < len; i++) {
          timeslot = ref[i];
          if (timeslot.date === this.get('selectedDate')) {
            return timeslot;
          }
        }
      }
    }),
    didInsertElement: function () {
      var headers, reschedule;
      headers = this.get('sessionAccount.headers');
      _jquery.default.ajax({
        url: _environment.default.api.host + "/v1/pilots/missions/" + this.get('model.mission.id') + "/timeslots",
        type: 'GET',
        dataType: 'json',
        headers: headers
      }).then(function (_this) {
        return function (response) {
          var datepicker, disabledDays, line, maxDate, minDate, timeslots;
          _this.set('timeslots', response);
          timeslots = _this.get('timeslots');
          _this.set('selectedDate', timeslots[0].date);
          minDate = timeslots[0].date;
          maxDate = timeslots.at(-1).date;
          disabledDays = [];
          if (_this.get('model.mission.weekend_schedule_filter') === 'no_weekends') {
            disabledDays = [0, 6];
          } else if (_this.get('model.mission.weekend_schedule_filter') === 'weekends_only') {
            disabledDays = [1, 2, 3, 4, 5];
          }
          $('.rescheduleTimeDatePicker').datetimepicker({
            collapse: false,
            viewMode: 'days',
            format: 'YYYY-MM-DD',
            inline: true,
            minDate: minDate,
            maxDate: maxDate,
            daysOfWeekDisabled: disabledDays,
            date: _this.get('selectedDate'),
            icons: {
              date: "fa fa-calendar",
              previous: "icon-DB_icon_ArrowStroke_left",
              next: "icon-DB_icon_ArrowStroke_right",
              up: "icon-Arrow2_down",
              down: "icon-Arrow2_up",
              close: "icon-Xmark"
            }
          });
          datepicker = document.getElementsByClassName('datepicker')[0];
          line = document.createElement('div');
          line.className = "separator-line";
          datepicker.appendChild(line);
          return $('.rescheduleTimeDatePicker').on('dp.change', function (e) {
            return _this.set('selectedDate', e.date.format('YYYY-MM-DD'));
          });
        };
      }(this), function (response) {
        return console.log('failed to retrieve timeslots');
      });
      $('.reschedule-reason').niceSelect();
      reschedule = this.get('model.mission.store').createRecord('mission-reschedule', {
        mission: this.get('model.mission'),
        rescheduler: this.get('sessionAccount.account')
      });
      return this.set('model.reschedule', reschedule);
    },
    actions: {
      toggleReScheduling: function () {
        return this.send('toggleRescheduling');
      },
      toggleRescheduleReason: function () {
        return this.set('showRescheduleReason', !this.get('showRescheduleReason'));
      },
      toggleShowRescheduleNotes: function () {
        return this.set('showRescheduleNotes', !this.get('showRescheduleNotes'));
      },
      setReschedulingStep: function (step) {
        this.set('reschedulingStep', step);
        if (step === 1) {
          return Em.run.later(function (_this) {
            return function () {
              return _this.send('initializeDatePicker');
            };
          }(this), 2);
        }
      },
      setRescheduleReason: function (reasonId) {
        var reason;
        if (reasonId) {
          reason = this.get('store').peekRecord('reschedule-reason', reasonId);
          this.set('model.reschedule.reschedule_reason', reason);
          if (reason.get('short') === 'Other') {
            this.set('notesRequires', true);
          } else {
            this.set('notesRequires', false);
          }
          return this.set('showRescheduleNotes', true);
        }
      },
      reSchedule: function () {
        return this.updateAction();
      },
      cancelReschedule: function () {
        return this.cancelReschedule();
      }
    }
  });
  var _default = RescheduleMissionComponent;
  _exports.default = _default;
});
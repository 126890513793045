define("pilots/models/pilot-license", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotLicense;
  PilotLicense = _emberData.default.Model.extend({
    license_number: _emberData.default.attr('string'),
    pilot: _emberData.default.belongsTo('pilot'),
    license: _emberData.default.belongsTo('license'),
    reference_date: _emberData.default.attr('string'),
    expiration_date: _emberData.default.attr('string'),
    issuer_type: _emberData.default.attr('string'),
    issuing_body: _emberData.default.attr('string'),
    image: _emberData.default.attr('')
  });
  var _default = PilotLicense;
  _exports.default = _default;
});
define("pilots/templates/components/pilot-profile-drone-cameras", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jiEDQaq/",
    "block": "[[[10,0],[14,0,\"cameras-section\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"cameras-section-title\"],[12],[1,\"\\n    Cameras:\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"cameras-section-pills\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"      \"],[10,\"button\"],[14,0,\"btn btn-xs camera-pill\"],[15,\"onClick\",[28,[37,3],[[28,[37,4],[[30,0],\"destroyCamera\",[30,1]],null]],null]],[12],[1,[30,1,[\"name\"]]],[1,\" \"],[10,1],[14,0,\"delete-me icon-Xmark\"],[12],[13],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,1],[14,0,\"camera-dropdown-arrow field-arrow icon-Arrow2_down\"],[12],[13],[1,\"\\n\"],[10,\"ul\"],[15,0,[29,[\"camera-dropdown \",[52,[33,6],\"hidden\"]]]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,7,[\"drone\",\"optional_cameras\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[1,[28,[35,8],null,[[\"camera\",\"createCamera\"],[[30,2],[28,[37,4],[[30,0],\"createCamera\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"camera\",\"camera\"],false,[\"each\",\"-track-array\",\"selectedCameras\",\"disable-bubbling\",\"action\",\"if\",\"hidden\",\"pilotDrone\",\"pilot-profile-drone-camera\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-drone-cameras.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/pilot-profile-drone-system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileDroneSystemComponent;
  PilotProfileDroneSystemComponent = Ember.Component.extend({
    classNames: ['drone-system'],
    pilotDroneNumber: Ember.computed('index', {
      get: function () {
        if (this.get('index') >= 0) {
          return this.get('index') + 1;
        }
      },
      set: function (key, value) {
        return this._pilotDroneNumber = value;
      }
    }),
    actions: {
      destroy: function (drone) {
        this.set('pilotDroneNumber', this.get('pilotDroneNumber') - 1);
        return this.destroyDrone(drone);
      }
    }
  });
  var _default = PilotProfileDroneSystemComponent;
  _exports.default = _default;
});
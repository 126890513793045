define("pilots/services/upload-queue-manager", ["exports", "pilots/utils/uploader/queue"], function (_exports, _queue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UploadQueueManagerService = Ember.Service.extend({
    uploadQueue: _queue.default,
    rollbar: Ember.inject.service(),
    init() {
      this._super();
      return this.set('queues', Ember.Object.create());
    },
    findOrCreate(name, component, config) {
      let queue = this.queues.get(name);
      if (queue) {
        if (config !== null) {
          queue.set('target', component);
          queue.configureUploader(config);
        }
      } else {
        queue = this.get('uploadQueue').create({
          name,
          target: component
        });
        this.queues.set(name, queue);
        queue.configureUploader(config);
      }
      return queue;
    }
  });
  var _default = UploadQueueManagerService;
  _exports.default = _default;
});
define("pilots/templates/components/work-order/upload-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5Eag1uZr",
    "block": "[[[10,\"td\"],[12],[1,[33,0,[\"mission_name\"]]],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,[33,0,[\"mission_number\"]]],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"download-container\"],[12],[1,\"\\n    \"],[8,[39,1],[[4,[38,2],[[30,0],\"getDuckFile\",[33,0,[\"mission_number\"]]],null]],[[\"@type\",\"@size\"],[\"link\",\"medium\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/credential_icon.svg\"],[14,0,\"download-icon\"],[12],[13],[1,\"Download\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flexer\"],[12],[1,\"\\n    \"],[10,\"input\"],[15,1,[29,[\"copy-\",[33,0,[\"mission_number\"]]]]],[15,2,[33,0,[\"value\",\"secret_key\"]]],[14,\"readonly\",\"\"],[14,4,\"text\"],[12],[13],[1,\"\\n    \"],[11,3],[24,6,\"#\"],[4,[38,2],[[30,0],\"copyKey\",[33,0,[\"mission_number\"]]],null],[12],[10,\"img\"],[14,\"src\",\"/assets/images/copy_link_icon.svg\"],[14,0,\"link-icon\"],[12],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"actions\"],[12],[1,\"\\n\"],[41,[33,4],[[[1,\"    \"],[10,0],[14,0,\"loader\"],[12],[13],[1,\"\\n    \"],[10,0],[12],[1,\"In Progress\"],[13],[1,\"\\n\"]],[]],[[[41,[33,5],[[[1,\"      \"],[10,\"img\"],[14,\"src\",\"/assets/images/checkmark-icon.svg\"],[12],[13],[1,\" Complete\\n\"]],[]],[[[1,\"      \"],[8,[39,1],[[4,[38,2],[[30,0],\"doneUploading\",[33,0,[\"mission_number\"]]],null]],null,[[\"default\"],[[[[1,\"\\n        Done uploading\\n      \"]],[]]]]],[1,\"\\n\"]],[]]]],[]]],[13],[1,\"\\n\"]],[],false,[\"app\",\"button\",\"action\",\"if\",\"startingUploading\",\"complete\"]]",
    "moduleName": "pilots/templates/components/work-order/upload-table-row.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/pilot-profile-drone-systems", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileDroneSystemsComponent;
  PilotProfileDroneSystemsComponent = Ember.Component.extend({
    classNames: ['profile-component'],
    store: Ember.inject.service(),
    onInit: Ember.on('init', function () {}),
    missingInfo: Ember.computed('model.pilotDrones.[]', function () {
      return this.get('model.pilotDrones.length') === 0;
    }),
    sortedPilotDrones: Ember.computed('model.pilotDrones.[]', function () {
      return this.get('model.pilotDrones').sortBy('drone.full_name');
    }),
    actions: {
      createDrone: function (drone) {
        var pilotDrone;
        pilotDrone = this.get('store').createRecord('pilot-drone', {
          drone: drone,
          pilot: this.get('model.pilot')
        });
        return pilotDrone.save();
      },
      destroyDrone: function (drone) {
        return drone.destroyRecord().then(function (_this) {
          return function () {
            return _this.get('model.pilot').reload();
          };
        }(this));
      }
    }
  });
  var _default = PilotProfileDroneSystemsComponent;
  _exports.default = _default;
});
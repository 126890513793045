define("pilots/templates/components/payout-with-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "E41hJW8V",
    "block": "[[[10,1],[14,0,\"dollarsign\"],[12],[1,[34,0]],[13],[1,\"\\n\"],[10,1],[12],[1,[28,[35,1],[[33,2],100],null]],[13],[1,\"\\n\"],[10,1],[14,0,\"currency-code\"],[12],[1,[34,3]],[13],[1,\"\\n\"]],[],false,[\"symbol\",\"division\",\"number\",\"code\"]]",
    "moduleName": "pilots/templates/components/payout-with-currency.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/services/pusher_data", ["exports", "pilots/config/environment", "pusher-js"], function (_exports, _environment, _pusherJs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PusherData;
  PusherData = Ember.Service.extend({
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    pusherEvents: ['image-updated', 'video-updated'],
    start: function (pilotId) {
      var pusher, pusherKey, store;
      pusherKey = _environment.default.pusher_key;
      pusher = new _pusherJs.default(pusherKey, {
        authEndpoint: _environment.default.api.host + "/v1/pilots/pusher/auth",
        auth: {
          headers: this.get('sessionAccount.headers')
        }
      });
      this.set('pusher', pusher);
      store = this.get('store');
      pusher.subscribe("private-pilot-pilot-" + pilotId);
      pusher.bind('image-updated', function (_this) {
        return function (payload) {
          return _this.assetUpdated(store, 'image', payload);
        };
      }(this));
      return pusher.bind('video-updated', function (_this) {
        return function (payload) {
          return _this.assetUpdated(store, 'video', payload);
        };
      }(this));
    },
    assetUpdated: function (store, type, payload) {
      var asset;
      asset = null;
      if (payload.data.id) {
        store.peekAll(type).filter(function (a) {
          if (a.get('id') === payload.data.id) {
            return asset = a;
          }
        });
        if (asset) {
          asset.setProperties(payload.data.attributes);
          if (asset.get('generatingNativeThumbnail')) {
            asset.setProperties({
              generatingNativeThumbnail: false
            });
          }
          if (!asset.get('isNew')) {
            return asset.save();
          } else if (asset.get('hasProperId') && asset.get('mission.id')) {
            return asset.saveAsUpdate();
          }
        }
      }
    },
    actions: {
      imageUpdated: function () {
        return console.log(arguments);
      }
    }
  });
  var _default = PusherData;
  _exports.default = _default;
});
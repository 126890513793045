define("pilots/adapters/reschedule-reason", ["exports", "pilots/adapters/application", "pilots/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RescheduleReasonAdapter;
  RescheduleReasonAdapter = _application.default.extend({
    namespace: _environment.default.api.namespace + '/pilots'
  });
  var _default = RescheduleReasonAdapter;
  _exports.default = _default;
});
define("pilots/mirage/factories/location", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jshint esversion: 6 */
  var _default = _emberCliMirage.Factory.extend({
    name: "Disneyland complex",
    latitude: 30.065887,
    longitude: -81.445656,
    address: "1313 Disneyland Dr",
    address2: null,
    city: "Anaheim",
    state: "California",
    postal_code: "92802",
    country: "United States",
    // properties: null,
    formatted_address: "1313 Disneyland Dr, Anaheim, California 92802, United States",
    timezone_id: "America/New_York",
    airmap_authorization: "https://app.airmap.io/create_flight/v1?geometry=%7B%22geometry%22%3A%7B%22type%22%3A%22Polygon%22%2C%22coordinates%22%3A%5B%5B%5B-81.44554414386965%2C30.06598380573018%5D%2C%5B-81.44554414408847%2C30.06579019417513%5D%2C%5B-81.44576785591153%2C30.06579019417513%5D%2C%5B-81.44576785613033%2C30.06598380573018%5D%2C%5B-81.44554414386965%2C30.06598380573018%5D%2C%5B-81.44554414386965%2C30.06598380573018%5D%5D%5D%7D%7D\u0026ruleset_ids=usa_part_107"
  });
  _exports.default = _default;
});
define("pilots/components/available-mission", ["exports", "jquery", "pilots/mixins/google-init-mixin", "pilots/config/environment"], function (_exports, _jquery, _googleInitMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  var AvailableMissionComponent,
    indexOf = [].indexOf || function (item) {
      for (var i = 0, l = this.length; i < l; i++) {
        if (i in this && this[i] === item) return i;
      }
      return -1;
    };
  AvailableMissionComponent = Ember.Component.extend(_googleInitMixin.default, {
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    tagName: '',
    expandedScope: false,
    expandedPreparation: false,
    expandedExecution: false,
    expandedScheduling: false,
    validatedScope: false,
    validatedPreparation: false,
    validatedExecution: false,
    validatedScheduling: false,
    errorScope: false,
    errorPreparation: false,
    errorExecution: false,
    errorScheduling: false,
    blogUrl: _environment.default.BLOG_URL,
    orderOfSteps: ['Scope', 'Preparation', 'Execution', 'Scheduling'],
    fadeErrorMessage: Ember.on('didRender', function () {
      if ($('.error-state').length && !$('.error-state').hasClass('loaded')) {
        setTimeout(function () {
          return $('.error-state').addClass('loaded');
        }, 50);
      }
    }),
    schedulingValidated: Ember.computed('model.mission.admin_scheduled', 'model.mission.scheduled_at_start', 'validatedScope', 'validatedPreparation', 'validatedExecution', function () {
      if (this.get('model.mission.admin_scheduled')) {
        if (this.get('validatedScope') && this.get('validatedPreparation') && this.get('validatedExecution')) {
          this.set('errorScheduling', false);
          return true;
        }
      } else {
        if (this.get('model.mission.scheduled_at_start')) {
          this.set('errorScheduling', false);
          return true;
        }
      }
    }),
    hasUnreadSections: Ember.computed('validatedScope', 'validatedPreparation', 'validatedExecution', 'schedulingValidated', function () {
      if (this.get('validatedScope') && this.get('validatedPreparation') && this.get('validatedExecution') && this.get('schedulingValidated')) {
        return false;
      }
      return true;
    }),
    scheduleTitle: Ember.computed('model.mission.admin_scheduled', function () {
      if (this.get('model.mission.admin_scheduled')) {
        return 'Accept Mission';
      } else {
        return 'Schedule Mission';
      }
    }),
    milesCountries: ['United States', 'United States of America', 'US', 'USA', 'GB'],
    call_action_copy: {
      in_advance_to_coordinate: 'You will need to speak with the client to schedule the flight date and time. This information will be provided upon accepting this mission.',
      when_you_arrive: 'You will need to call the client when you arrive onsite. This information will be provided after accepting this mission.'
    },
    afterGoogleLoaded: function () {
      var distance, distanceKM, distanceMiles, latLngA, latLngB, ref;
      if (this.get('model.mission').get('laanc_exemptions') && this.get('model.mission').get('laanc_exemptions').get('firstObject')) {
        this.set('LAANCExemption', this.get('model.mission').get('laanc_exemptions').get('firstObject'));
      }
      latLngA = new google.maps.LatLng(this.get('model.pilot.latitude'), this.get('model.pilot.longitude'));
      latLngB = new google.maps.LatLng(Number(this.get('model.mission.location.latitude')), Number(this.get('model.mission.location.longitude')));
      distance = google.maps.geometry.spherical.computeDistanceBetween(latLngA, latLngB);
      distanceMiles = (distance / 1609).toString();
      if (ref = this.get('model.pilot.country'), indexOf.call(this.milesCountries, ref) >= 0) {
        if (distanceMiles.indexOf('.') && distanceMiles.indexOf('.') + 1 < distanceMiles.length) {
          return this.set('missionDistance', distanceMiles.substring(0, distanceMiles.indexOf('.') + 2) + ' mi');
        } else {
          return this.set('missionDistance', distanceMiles + ' mi');
        }
      } else {
        distanceKM = (distance / 1000).toString();
        if (distanceKM.indexOf('.') && distanceKM.indexOf('.') + 1 < distanceKM.length) {
          return this.set('missionDistance', distanceKM.substring(0, distanceKM.indexOf('.') + 2) + ' km');
        } else {
          return this.set('missionDistance', distanceKM + ' km');
        }
      }
    },
    acceptedDrones: Ember.computed('model.mission.package.drones', 'model.pilotDrones', function () {
      var hidden, pilotDrones, visible;
      visible = [];
      hidden = [];
      pilotDrones = this.get('model.pilotDrones').map(function (drone) {
        return drone.get('drone.name');
      });
      if (this.get('model.mission.package.drones')) {
        this.get('model.mission.package.drones').forEach(function (drone) {
          if (pilotDrones.includes(drone.get('name'))) {
            return visible.push(drone.get('name'));
          } else {
            return hidden.push(drone.get('name'));
          }
        });
        return {
          visible: visible,
          hidden: hidden
        };
      }
    }),
    acceptedCameras: Ember.computed('model.mission.package.droneCameras', 'model.pilotDrones', function () {
      var hidden, pilotCameras, visible;
      visible = [];
      hidden = [];
      pilotCameras = [];
      if (this.get('model.pilotDrones') && this.get('model.mission.package.droneCameras')) {
        this.get('model.pilotDrones').forEach(function (drone) {
          return drone.get('cameras').forEach(function (camera) {
            return pilotCameras.push(camera.get('name'));
          });
        });
        this.get('model.mission.package.droneCameras').forEach(function (camera) {
          if (pilotCameras.includes(camera.get('name'))) {
            return visible.push(camera.get('name'));
          } else {
            return hidden.push(camera.get('name'));
          }
        });
        return {
          visible: visible,
          hidden: hidden
        };
      }
    }),
    acceptedDevices: Ember.computed('model.mission.package.devices', 'model.pilotDevices', function () {
      var hidden, pilotDevices, visible;
      pilotDevices = this.get('model.pilotDevices').map(function (device) {
        return device.get('device.name');
      });
      visible = [];
      hidden = [];
      if (this.get('model.mission.package.devices')) {
        this.get('model.mission.package.devices').forEach(function (device) {
          if (pilotDevices.includes(device.get('name'))) {
            return visible.push(device.get('name'));
          } else {
            return hidden.push(device.get('name'));
          }
        });
        return {
          visible: visible,
          hidden: hidden
        };
      }
    }),
    requiredEquipment: Ember.computed('model.mission.package.pilotEquipments', function () {
      var visible;
      visible = this.get('model.mission.package.pilotEquipments').map(function (equipment) {
        return equipment.get('name');
      });
      return {
        visible: visible
      };
    }),
    showOnsiteContactInfo: Ember.computed('model.mission.onsite_contact.call_action', function () {
      var call_action, sArray;
      if (this.get('model.mission.onsite_contact.call_action')) {
        sArray = this.get('model.mission.onsite_contact.call_action').split(' ');
        sArray.shift();
        call_action = sArray.join('_').toLowerCase();
        return this.call_action_copy[call_action];
      }
      return false;
    }),
    actions: {
      returnToDashboard: function () {
        return this.get('router').transitionTo('dashboard');
      },
      toggleDeclineModal: function () {
        return this.set('declineModal', !this.get('declineModal'));
      },
      toggleAcceptedModal: function () {
        return this.set('acceptedModal', !this.get('acceptedModal'));
      },
      accept: function () {
        var firstUnread, i, len, ref, step;
        firstUnread = "";
        if (this.get('hasUnreadSections')) {
          ref = this.orderOfSteps;
          for (i = 0, len = ref.length; i < len; i++) {
            step = ref[i];
            if (!this.get('validated' + step)) {
              this.set('error' + step, true);
              if (!firstUnread) {
                firstUnread = step;
              }
            }
          }
          this.set('showErrorState', true);
          setTimeout(function () {
            return $('.error-state').removeClass('loaded');
          }, 5000);
          return setTimeout(function (_this) {
            return function () {
              return _this.set('showErrorState', false);
            };
          }(this), 6000);
        } else {
          return this.send('toggleAcceptedModal');
        }
      },
      confirmAccepted: function (mission) {
        if (!this.get('hasUnreadSections')) {
          this.acceptMission(mission);
          return this.get('metrics').trackEvent({
            event: 'event',
            category: 'Available Mission',
            action: 'Accept Mission',
            label: 'Accept Available Mission'
          });
        }
      },
      confirmDeclined: function (mission) {
        return this.declineMission(mission, this);
      },
      validate: function (section) {
        var i, label, len, ref, step;
        this.set('validated' + section, true);
        label = 'Validated ' + section;
        this.get('metrics').trackEvent({
          event: 'event',
          category: 'Available Mission',
          action: 'Validation',
          label: label
        });
        this.set('error' + section, false);
        this.set('expanded' + section, false);
        ref = this.orderOfSteps;
        for (i = 0, len = ref.length; i < len; i++) {
          step = ref[i];
          if (this.get('validated' + step)) {
            this.set('expanded' + step, false);
          } else {
            this.set('expanded' + step, true);
            return;
          }
        }
      },
      expandSection: function (section) {
        var i, len, ref, results, step;
        ref = this.orderOfSteps;
        results = [];
        for (i = 0, len = ref.length; i < len; i++) {
          step = ref[i];
          if (step !== section) {
            results.push(this.set('expanded' + step, false));
          } else {
            results.push(void 0);
          }
        }
        return results;
      },
      openMaps: function () {
        var url, win;
        if (this.get('model.mission.location.formatted_address')) {
          url = 'http://maps.google.com/?q=' + this.get('model.mission.location.formatted_address');
        } else {
          url = 'http://maps.google.com/?q=' + this.get('model.mission.location.latitude') + ',' + this.get('model.mission.location.longitude');
        }
        win = window.open(url, '_blank');
        if (win) {
          return win.focus();
        }
      }
    }
  });
  var _default = AvailableMissionComponent;
  _exports.default = _default;
});
define("pilots/components/pilot-profile-payment-information", ["exports", "pilots/data/stripe_supported_slugs", "pilots/validations/pilot", "ember-changeset-validations", "ember-changeset"], function (_exports, _stripe_supported_slugs, _pilot, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_pilot.default, {
    formExpanded: false,
    classNames: ['profile-component'],
    metrics: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    initChangeSet: Ember.on('init', function () {
      this.changeset = new _emberChangeset.default(this.get('model.pilot'), (0, _emberChangesetValidations.default)(_pilot.default), _pilot.default);
    }),
    showPaypal: Ember.computed('model.pilot.paypal_email.length', 'model.pilot.stripe_onboarding', function () {
      return this.get('model.pilot.paypal_email.length') || !this.get('model.pilot.stripe_onboarding');
    }),
    legalEntitiesOptions: Ember.computed('model.availableLegalEntities.[]', 'model.pilot.stripe_accounts.[]', function () {
      const pilot = this.get('model.pilot');
      const pilotLegalEntitiesIDs = this.get('model.pilot.stripe_accounts').map(function (psa) {
        return psa.get('legal_entity.id');
      });
      return this.get('model.availableLegalEntities').filter(function (legalEntity) {
        const north_america_support = legalEntity.slug === _stripe_supported_slugs.NORTH_AMERICA_SLUG && pilot.stripe_north_america_available;
        const europe_support = legalEntity.slug === _stripe_supported_slugs.EUROPE_SLUG && pilot.stripe_europe_available;
        if (!pilotLegalEntitiesIDs.includes(legalEntity.id) && (north_america_support || europe_support)) {
          return !pilotLegalEntitiesIDs.includes(legalEntity.id);
        }
      });
    }),
    actions: {
      expandForm: function () {
        this.set('formExpanded', true);
      },
      hideForm: function () {
        this.set('formExpanded', false);
      }
    }
  });
  _exports.default = _default;
});
define("pilots/templates/components/dashboard/work-order-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7V2idv7e",
    "block": "[[[10,0],[15,0,[29,[\"mission-list-columns \",[52,[33,1,[\"location\",\"uniqueLocationName\"]],\"hasLocationName\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"mission-location\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,0,\"pin\"],[14,\"src\",\"/assets/images/ams/wind_turbine.svg\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"mission-address\"],[12],[1,\"\\n\"],[41,[33,1,[\"location\",\"uniqueLocationName\"]],[[[1,\"        \"],[1,[33,1,[\"location\",\"uniqueLocationName\"]]],[1,\" \"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[33,1,[\"location\",\"formatted_address\"]],[[[1,\"        \"],[1,[33,1,[\"location\",\"formatted_address\"]]],[1,\"\\n\"]],[]],[[[1,\"        Location Unknown\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mission-status\"],[12],[1,\"\\n    IN PROGRESS\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mission-flight-date\"],[12],[1,\"\\n      N/A\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mission-payout\"],[12],[1,\"\\n      N/A\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mission-action\"],[12],[1,\"\\n    \"],[8,[39,2],[[16,6,[29,[\"/work-orders/\",[33,1,[\"id\"]]]]]],[[\"@tagName\"],[\"a\"]],[[\"default\"],[[[[1,\"Upload\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"workOrder\",\"button\"]]",
    "moduleName": "pilots/templates/components/dashboard/work-order-info.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/helpers/clear-cookie", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ClearCookieHelper;
  ClearCookieHelper = Ember.Helper.helper(function (arg) {
    var name;
    name = arg[0];
    return Cookies.remove(name);
  });
  var _default = ClearCookieHelper;
  _exports.default = _default;
});
define("pilots/templates/components/pilot-profile-device", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "O1TJv2HH",
    "block": "[[[10,0],[14,0,\"item-row devices\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"device-item item-row btm-border-line\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"field-name\"],[12],[1,\"DEVICE \"],[1,[34,0]],[13],[1,\"\\n    \"],[10,1],[14,0,\"field-spacer\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"device-name\"],[12],[1,\"\\n      \"],[1,[33,1,[\"device\",\"name\"]]],[1,\"\\n      \"],[11,1],[24,0,\"delete-me icon-Xmark\"],[4,[38,2],[[30,0],\"destroy\",[33,1]],null],[12],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"pilotDeviceNumber\",\"pilotDevice\",\"action\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-device.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/data/stripe_supported_slugs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NORTH_AMERICA_SLUG = _exports.EUROPE_SLUG = void 0;
  const NORTH_AMERICA_SLUG = "us";
  _exports.NORTH_AMERICA_SLUG = NORTH_AMERICA_SLUG;
  const EUROPE_SLUG = "de";
  _exports.EUROPE_SLUG = EUROPE_SLUG;
});
define("pilots/components/dashboard-map-filter-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardMapFilterButtonComponent;
  DashboardMapFilterButtonComponent = Ember.Component.extend({
    metrics: Ember.inject.service(),
    classNames: ['map-filter-button'],
    selected: Ember.computed('missionType.selected', function () {
      if (this.get('missionType').selected) {
        return this.get('missionType').color;
      }
    }),
    click: function (event) {
      event.stopPropagation();
      event.preventDefault();
      this.sendAction('updateFilterAction', this.get('missionType'));
      if (this.get('missionType').selected) {
        return this.get('metrics').trackEvent({
          event: 'event',
          category: 'Active Missions',
          action: 'click',
          label: "Filter Show " + this.get('missionType').name
        });
      } else {
        return this.get('metrics').trackEvent({
          event: 'event',
          category: 'Active Missions',
          action: 'click',
          label: "Filter Hide " + this.get('missionType').name
        });
      }
    },
    actions: {
      updateFilter: function (mission_type) {
        return this.sendAction('updateFilterAction', mission_type);
      }
    }
  });
  var _default = DashboardMapFilterButtonComponent;
  _exports.default = _default;
});
define("pilots/templates/components/dashboard-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DU2w0E75",
    "block": "[[[10,0],[14,1,\"map-canvas\"],[15,0,[29,[[52,[51,[33,1]],\"map-canvas-dashboard\"]]]],[12],[13],[1,\"\\n\"],[10,0],[14,1,\"info-window-node\"],[12],[13],[1,\"\\n\"]],[],false,[\"unless\",\"pilotlog\"]]",
    "moduleName": "pilots/templates/components/dashboard-map.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
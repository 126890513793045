define("pilots/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Notification;
  Notification = _emberData.default.Model.extend({
    app_route_url: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    meta: _emberData.default.attr('string'),
    notification_type: _emberData.default.attr('string'),
    read_status: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    web_route_url: _emberData.default.attr('string'),
    fontIcon: Ember.computed('notification_type', function () {
      switch (this.get('notification_type')) {
        case 'blog/new':
          return 'blog_notification.svg';
        case 'app/updated':
          return 'app_update_notification.svg';
        case 'pilot/payout/new':
        case "pilot/profile/stripe_onboarding":
          return 'payout_notification.svg';
        case 'pilot/mission/updated':
        case 'pilot/mission/new':
        case 'pilot/mission/getty_accept':
        case 'pilot/mission/getty_reject':
          return 'available_mission_notification.svg';
        case 'pilot/mission/pano_reject':
        case 'pilot/mission/pano_accept':
          return '';
      }
    })
  });
  var _default = Notification;
  _exports.default = _default;
});
define("pilots/templates/components/mission-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gKW9hHwf",
    "block": "[[[10,0],[14,0,\"map-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,1,\"map-canvas\"],[12],[13],[1,\"\\n  \"],[10,0],[14,1,\"info-window-node\"],[12],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"kml-button hidden-xs \",[52,[28,[37,1],[[33,2],[33,3]],null],\"hidden\"]]]],[12],[1,\"\\n    \"],[8,[39,4],[[16,\"disabled\",[52,[33,5,[\"map_features\"]],false,true]],[4,[38,6],[[30,0],\"downloadKML\"],null]],[[\"@type\"],[\"secondary\"]],[[\"default\"],[[[[1,\"\\n      Download KML\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"or\",\"hide-download-kml\",\"is-mobile\",\"button\",\"mission\",\"action\"]]",
    "moduleName": "pilots/templates/components/mission-map.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
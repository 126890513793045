define("pilots/serializers/pilot-license", ["exports", "pilots/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    serialize() {
      var json = this._super(...arguments);
      if (typeof json.data.attributes.image === "string" || json.data.attributes.image === null) {
        json.data.attributes.tempSrc = json.data.attributes.image;
        delete json.data.attributes.image;
      }
      if (json.data.attributes.reference_date === null) {
        delete json.data.attributes.reference_date;
      }
      return json;
    }
  });
  _exports.default = _default;
});
define("pilots/templates/clientmissionwebview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BHh2aXQa",
    "block": "[[[10,0],[14,0,\"client-mission available-mission-web-view\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"model\",\"onfileadd\",\"goToUpload\",\"updateAction\",\"startLAANCAuth\",\"openMaps\"],[[33,1],[28,[37,2],[[30,0],[33,3],\"addAsset\"],null],[28,[37,2],[[30,0],[33,3],\"goToUpload\"],null],[28,[37,2],[[30,0],[33,3],\"update\"],null],[28,[37,2],[[30,0],[33,3],\"startLAANCAuth\"],null],[28,[37,2],[[30,0],[33,3],\"openMaps\"],null]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"client-mission\",\"model\",\"action\",\"send\"]]",
    "moduleName": "pilots/templates/clientmissionwebview.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/models/location", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('string'),
    longitude: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    postal_code: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    formatted_address: _emberData.default.attr('string'),
    timezone_id: _emberData.default.attr('string'),
    airmap_authorization: _emberData.default.attr('string'),
    missions: _emberData.default.hasMany('missions', {
      async: false
    }),
    uniqueLocationName: Ember.computed('name', 'address', 'formatted_address', function () {
      if (this.get('name') !== this.get('formatted_address') && this.get('name') !== this.get('address')) {
        return this.get('name');
      }
    })
  });
  _exports.default = _default;
});
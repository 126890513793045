define("pilots/controllers/signup-flow-certification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    currentPage: 'MAIN',
    router: Ember.inject.service(),
    actions: {
      setCurrentPage(page) {
        this.set('currentPage', page);
      },
      toggleShowSkipModal() {
        this.toggleProperty('showSkipModal');
      },
      skipStep() {
        this.get('router').transitionTo('signup-flow-preferences');
      }
    }
  });
  _exports.default = _default;
});
define("pilots/templates/components/pilot-work-location-existing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RflLNSkR",
    "block": "[[[10,0],[14,0,\"item-container work-location\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"title\"],[12],[1,\"WORK LOCATION \"],[1,[34,0]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"vertical-divider\"],[12],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"work-location-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"work-location-name\"],[12],[1,\"\\n      \"],[1,[33,1,[\"legal_entity\",\"name\"]]],[1,\"\\n\"],[41,[33,1,[\"legal_entity\",\"displayCountries\"]],[[[1,\"        \"],[10,1],[14,0,\"countries-list\"],[12],[1,\"(\"],[1,[33,1,[\"legal_entity\",\"displayCountries\"]]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"stripe-account-management\"],[12],[1,\"\\n      \"],[8,[39,3],[[4,[38,4],[[30,0],\"setUpStripe\"],null]],[[\"@type\",\"@size\"],[\"secondary\",\"medium\"]],[[\"default\"],[[[[1,\"\\n        Manage Stripe account\\n      \"]],[]]]]],[1,\"\\n\\n\"],[41,[51,[33,1,[\"autopay_enabled\"]]],[[[1,\"        \"],[10,0],[14,0,\"onboarded\"],[12],[1,\"\\n          \"],[10,\"img\"],[14,\"src\",\"/assets/images/clients/info-icon-purple.svg\"],[12],[13],[1,\"\\n          Please onboard Stripe account\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"workLocationNumber\",\"stripeAccount\",\"if\",\"button\",\"action\",\"unless\"]]",
    "moduleName": "pilots/templates/components/pilot-work-location-existing.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
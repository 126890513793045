define("pilots/helpers/type-of-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.typeOfItem = typeOfItem;
  function typeOfItem(_ref) {
    let [item] = _ref;
    if (item) {
      return typeof item;
    }
    return "object";
  }
  var _default = Ember.Helper.helper(typeOfItem);
  _exports.default = _default;
});
define("pilots/components/video-player", ["exports", "pilots/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VideoPlayerComponent;
  VideoPlayerComponent = Ember.Component.extend({
    didInsertElement: function () {
      if (this.get('autoplay')) {
        return this.element.querySelectorAll('video')[0].play();
      }
    },
    actions: {
      fullScreen: function () {
        if (this.element.querySelectorAll('video')[0].requestFullscreen) {
          return this.element.querySelectorAll('video')[0].requestFullscreen();
        } else {
          if (this.element.querySelectorAll('video')[0].mozRequestFullScreen) {
            return this.element.querySelectorAll('video')[0].mozRequestFullScreen();
          } else {
            if (this.element.querySelectorAll('video')[0].webkitRequestFullscreen) {
              return Math.floor(this.element.querySelectorAll('video')[0].webkitRequestFullscreen() / Chrome) && Safari;
            }
          }
        }
      },
      showVideoControls: function () {
        return this.element.querySelectorAll('.video-controls').css('opacity', '1');
      },
      hideVideoControls: function () {
        return setTimeout(function () {
          return this.element.querySelectorAll('.video-controls').css('opacity', '0');
        }, 500);
      },
      togglePlayPause: function () {
        if (this.element.querySelectorAll('video')[0].paused === true) {
          this.element.querySelectorAll('.play').css('opacity', 1);
          setTimeout(function () {
            return this.element.querySelectorAll('.play').css('opacity', 0);
          }, 500);
          return this.element.querySelectorAll('video')[0].play();
        } else {
          this.element.querySelectorAll('.pause').css('opacity', 1);
          setTimeout(function () {
            return this.element.querySelectorAll('.pause').css('opacity', 0);
          }, 500);
          return this.element.querySelectorAll('video')[0].pause();
        }
      },
      toggleVolume: function () {
        console.log('here');
        console.log(this.element.querySelectorAll('video')[0].muted);
        if (this.element.querySelectorAll('video')[0].muted) {
          this.element.querySelectorAll('video')[0].muted = false;
          $('#volume-on').css('display', 'none');
          return $('#mute').css('display', 'flex');
        } else {
          this.element.querySelectorAll('video')[0].muted = true;
          $('#mute').css('display', 'none');
          return $('#volume-on').css('display', 'flex');
        }
      }
    }
  });
  var _default = VideoPlayerComponent;
  _exports.default = _default;
});
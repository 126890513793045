define("pilots/components/dropdown-menu", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement() {
      this.element.querySelectorAll('.select-list').forEach(el => {
        el.addEventListener('click', event => {
          event.stopPropagation();
          this.toggleProperty('show');
          console.log('show', this.get('show'));
          (0, _jquery.default)('html').one('click', () => {
            this.set('show', false);
          });
        });
      });
    },
    display: Ember.computed('selectedItem', function () {
      if (this.get('selectedItem')) {
        if (this.get('propertyName')) {
          return this.get('selectedItem')[this.get('propertyName')];
        } else {
          return this.get('selectedItem');
        }
      } else {
        return this.get('placeholder');
      }
    }),
    actions: {
      selectItem(item) {
        this.set('selectedItem', item);
        this.selectItem(item);
      }
    }
  });
  _exports.default = _default;
});
define("pilots/templates/components/input-inplace-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6HMxsuHX",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,1],[14,0,\"field-label\"],[12],[1,[34,2]],[13],[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[1,[28,[35,4],null,[[\"value\"],[[33,5]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,6],null,[[\"type\",\"value\"],[[33,7],[33,5]]]]],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[10,0],[14,0,\"inline-input\"],[12],[1,\"\\n\"],[41,[33,5],[[[41,[33,3],[[[1,\"        \"],[1,[28,[35,8],[[33,5]],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[34,5]],[1,\"\\n\"]],[]]]],[]],[[[1,\"      \"],[10,1],[14,0,\"placeholder\"],[12],[1,\"\\n        \"],[1,[34,2]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"isEditing\",\"placeholder\",\"typeTextArea\",\"textarea-trigger-save\",\"value\",\"input-trigger-save\",\"type\",\"markdown-to-html\"]]",
    "moduleName": "pilots/templates/components/input-inplace-edit.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
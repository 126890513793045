define("pilots/mixins/google-init-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    googleInit: Ember.inject.service(),
    didInsertElement: function () {
      return Ember.run.scheduleOnce('afterRender', this, this.loadScript);
    },
    loadScript: function () {
      if (this.googleInit.loaded) {
        return this.afterGoogleLoaded();
      } else {
        this.googleInit.loadScript();
        return Ember.run.later(this, this.loadScript, 100);
      }
    }
  });
  _exports.default = _default;
});
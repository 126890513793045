define("pilots/serializers/pilot-drone-camera", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPISerializer.extend({
    normalizeCreateRecordResponse(store, primaryModelClass, payload) {
      var newId = Math.floor(Math.random() * 100000001);
      payload.data.id = newId;
      payload.data.type = 'pilot_drone_camera';
      payload.data.relationships.camera = payload.data.relationships.cameras.data.get('lastObject');
      return this._super(...arguments);
    }
  });
  _exports.default = _default;
});
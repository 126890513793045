define("pilots/adapters/drone-camera", ["exports", "pilots/adapters/application", "pilots/config/environment", "ember-data-url-templates", "ember-data"], function (_exports, _application, _environment, _emberDataUrlTemplates, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DroneCameraAdapter;
  DroneCameraAdapter = _application.default.extend(_emberDataUrlTemplates.default, _emberData.default, {
    namespace: _environment.default.api.host + "/" + _environment.default.api.namespace + "/pilots/pilot_drones",
    urlTemplate: '{+namespace}/{pilotDroneId}/cameras{/id}',
    urlSegments: {
      pilotDroneId: function (type, id, snapshot, query) {
        return snapshot.belongsTo('pilotDrone', {
          id: true
        });
      },
      droneCameraId: function (type, id, snapshot, query) {
        return snapshot.belongsTo('camera', {
          id: true
        });
      }
    },
    deleteRecord: function (store, type, snapshot) {
      var id, pilotDroneId;
      id = snapshot.id;
      pilotDroneId = snapshot.adapterOptions.pilotDroneId;
      return this.ajax(this.get('namespace') + "/" + pilotDroneId + "/cameras/" + id, "DELETE");
    },
    handleResponse: function (status, headers, payload, requestData) {
      if (requestData.method === 'DELETE') {
        status = 204;
        payload = {};
        payload.meta = {};
        payload.meta.success = true;
        headers = {};
      }
      return this._super(status, headers, payload, requestData);
    }
  });
  var _default = DroneCameraAdapter;
  _exports.default = _default;
});
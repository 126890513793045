define("pilots/controllers/signup-flow-preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    actions: {
      toggleShowSkipModal() {
        this.toggleProperty('showSkipModal');
      },
      skipStep() {
        this.get('router').transitionTo('dashboard');
      }
    }
  });
  _exports.default = _default;
});
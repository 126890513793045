define("pilots/templates/components/pilot-profile-equipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "F4anYpjp",
    "block": "[[[10,0],[14,0,\"section-header\"],[12],[1,\"\\n  OTHER EQUIPMENT\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"secion-description\"],[12],[1,\"\\n  Let us know what other equipment\\n  you have that will help us match you with missions\\n\"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2,[\"pilotEquipment\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"model\",\"pilotEquipment\",\"index\",\"destroyEquipment\"],[[33,2],[30,1],[30,2],[28,[37,4],[[30,0],\"destroyEquipment\"],null]]]]],[1,\"\\n\"]],[1,2]],null],[41,[33,6],[[[1,\"  \"],[1,[28,[35,7],null,[[\"model\",\"createEquipment\",\"missingInfo\"],[[33,2],[28,[37,4],[[30,0],\"createEquipment\"],null],[33,8]]]]],[1,\"\\n\"]],[]],null]],[\"equipment\",\"index\"],false,[\"each\",\"-track-array\",\"model\",\"pilot-profile-equipment-existing\",\"action\",\"if\",\"equipmentAvailable\",\"pilot-profile-equipment-new\",\"missingInfo\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-equipment.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
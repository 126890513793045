define("pilots/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /*jshint esversion: 6 */

  function _default() {
    this.urlPrefix = 'http://test-api.lvh.me:3000';
    this.namespace = '';
    this.get('/v1/pilots/account', () => {
      return {
        "data": {
          "id": "stubId",
          "type": "pilots",
          "attributes": {
            "first_name": "Joe",
            "last_name": "Bloggs",
            "email": "joe.bloggs@dronebase.com",
            "token": "2HOrUvS46o_UORvb6CIZhks4OX8",
            "phone": "",
            "birthday": "01/01/1900",
            "address": "5539 Samantha Ave",
            "address2": "hello",
            "city": "Lakewood",
            "state": "CA",
            "postal_code": "90712",
            "country": "US",
            "latitude": 33.856502,
            "longitude": -118.151957,
            "travel_distance": 3,
            "drone_system": "",
            "status": "approved",
            "payout_total": "2400.0",
            "business_name": null,
            "payment_processor": "Qwil",
            "payment_processor_id": "5122",
            "total_payout": "21900.0",
            "total_panorama_missions": 0,
            "total_client_missions": 22,
            "total_training_missions": 2,
            "image": "https://dronebase-staging.s3.amazonaws.com/pilots/profile/stubId/67c09288b2bef1094c60f8acbe71fd5728d49314/normal_100_image.jpeg?X-Amz-Expires=1200\u0026X-Amz-Date=20190730T180515Z\u0026X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=AKIAIY5DLNKYHQMXUPFQ/20190730/us-east-1/s3/aws4_request\u0026X-Amz-SignedHeaders=host\u0026X-Amz-Signature=adb531e9f3552b964ad780b4c032ae5df3ad141f8bd206897f6136411dff2e4d",
            "first_flight_on": null,
            "total_creative_missions": 70,
            "total_ar_blocks": 0,
            "mission_breakdown": {
              "getty": 70
            },
            "timezone_id": "America/Los_Angeles",
            "is_available_weekends": true,
            "is_available_weekdays": true,
            "created_at": "2017-09-01T17:19:43Z",
            "missing_profile_data": null
          },
          "relationships": {
            "opt_ins": {
              "data": []
            },
            "pilot_badges": {
              "data": [{
                "id": "487",
                "type": "pilot_badges"
              }, {
                "id": "335",
                "type": "pilot_badges"
              }, {
                "id": "495",
                "type": "pilot_badges"
              }]
            }
          }
        },
        "included": [{
          "id": "487",
          "type": "pilot_badges",
          "attributes": {
            "created_at": "2019-05-22T20:42:33Z",
            "updated_at": "2019-05-22T20:42:33Z",
            "status": "pending"
          },
          "relationships": {
            "pilot": {
              "data": {
                "id": "stubId",
                "type": "pilots"
              }
            },
            "badge": {
              "data": {
                "id": "4",
                "type": "badges"
              }
            }
          }
        }, {
          "id": "4",
          "type": "badges",
          "attributes": {
            "name": "EagleView Onboarding"
          }
        }, {
          "id": "335",
          "type": "pilot_badges",
          "attributes": {
            "created_at": "2018-11-27T20:34:29Z",
            "updated_at": "2018-11-27T20:34:29Z",
            "status": "pending"
          },
          "relationships": {
            "pilot": {
              "data": {
                "id": "stubId",
                "type": "pilots"
              }
            },
            "badge": {
              "data": {
                "id": "22",
                "type": "badges"
              }
            }
          }
        }, {
          "id": "22",
          "type": "badges",
          "attributes": {
            "name": "Construction Onboarding"
          }
        }, {
          "id": "495",
          "type": "pilot_badges",
          "attributes": {
            "created_at": "2019-06-04T16:57:46Z",
            "updated_at": "2019-06-04T16:57:47Z",
            "status": "pending"
          },
          "relationships": {
            "pilot": {
              "data": {
                "id": "stubId",
                "type": "pilots"
              }
            },
            "badge": {
              "data": {
                "id": "18",
                "type": "badges"
              }
            }
          }
        }, {
          "id": "18",
          "type": "badges",
          "attributes": {
            "name": "CRE Onboarding"
          }
        }]
      };
    });
    this.get('/v1/pilots/notifications', schema => {
      return schema.notifications.all();
    });
    this.get('/v2/pilots/missions', schema => {
      // const missions = schema.missions.all();
      // console.log('count', missions.length)
      // missions.forEach(function(mission) {
      //   console.log('item event', mission);
      //   console.log('item event', mission.mission_event);
      // });
      return schema.missions.all();
    });
    this.get('/v1/pilots/work_orders', schema => {
      return schema.workOrders.all();
    });
    this.get('/v2/pilots/missions/:id', (schema, request) => {
      var id = request.params.id;
      return schema.missions.find(id);
    });
    this.get('v1/pilots/pilot_drones', schema => {
      return schema.pilotDrones.all();
    });
    this.get('v1/pilots/pilot_pilot_equipment', schema => {
      return schema.pilotPilotEquipment.all();
    });
    this.get('v1/pilots/pilot_devices', schema => {
      return schema.pilotDevices.all();
    });
    this.get('v1/pilots/reschedule_reasons', schema => {
      return schema.rescheduleReasons.all();
    });

    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */

    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       http://www.ember-cli-mirage.com/docs/v0.4.x/shorthands/
    */
  }
});
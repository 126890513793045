define("pilots/templates/components/validated-checkbox-auto-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xaY6gvMA",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[[33,2],[33,3]],null]],[[\"update\",\"onchange\",\"required\",\"data-validate\",\"id\",\"classNames\"],[[28,[37,4],[[30,0],[28,[37,5],[[28,[37,1],[[33,2],[33,3]],null]],null]],null],[28,[37,4],[[30,0],\"validateProperty\",[33,2],[33,3]],null],true,true,[33,3],[33,6]]]]],[1,\"\\n\"],[10,\"label\"],[15,\"for\",[29,[[36,3]]]],[12],[13],[1,\"\\n\"]],[],false,[\"one-way-checkbox\",\"get\",\"changeset\",\"propertyName\",\"action\",\"mut\",\"childClassNames\"]]",
    "moduleName": "pilots/templates/components/validated-checkbox-auto-save.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/utils/w", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var toArray, w;
  toArray = function (value) {
    if (typeof value === 'string') {
      value = Ember.String.w(value);
    }
    return Ember.A(value);
  };
  w = function (defaultValue) {
    defaultValue = defaultValue || [];
    return Ember.computed({
      get: function () {
        return toArray(defaultValue);
      },
      set: function (key, value) {
        return toArray(value);
      }
    });
  };
  var _default = w;
  _exports.default = _default;
});
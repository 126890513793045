define("pilots/routes/pilotlog", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-cli-pagination/remote/route-mixin"], function (_exports, _authenticatedRouteMixin, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeMixin.default, {
    model() {
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', 'stubId'),
        missions: this.findPaged('mission', {
          status: 'processing_shots,assets_classified,in_production,awaiting_payment,invoice_needed,invoiced,complete,rejected',
          per_page: 20
        }),
        pilotLicenses: this.store.findAll('pilot-license')
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      return this.controllerFor('application').set('adminMode', !!this.get('session.data.authenticated.real_admin'));
    }
  });
  _exports.default = _default;
});
define("pilots/templates/components/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6eXGZs8V",
    "block": "[[[10,0],[14,0,\"loading-page\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"loading-pane loading-data\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"box\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"sk-circle\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"spinner\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"rect1\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"rect2\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"rect3\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"rect4\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"rect5\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "pilots/templates/components/loading.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/routes/availablemission", ["exports", "jquery", "pilots/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _jquery, _environment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  ;
  var AvailableMissionRoute;
  AvailableMissionRoute = Ember.Route.extend(_authenticatedRouteMixin.default, {
    tagName: '',
    sessionAccount: Ember.inject.service(),
    model: function (params) {
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', 'stubId'),
        pilotDrones: this.store.findAll('pilot-drone'),
        pilotEquipment: this.store.findAll('pilot-pilot-equipment'),
        pilotDevices: this.store.findAll('pilot-device'),
        mission: this.store.find('mission', params.mission_id).then(function (_this) {
          return function (response) {
            if (response.get('status') !== 'pilots_notified') {
              _this.replaceWith('clientmission', response.id);
            }
            return response;
          };
        }(this), function (_this) {
          return function (error) {
            console.log('error loading AvailableMissionRoute: ', error);
            return _this.replaceWith('unavailable-mission');
          };
        }(this))
      });
    },
    actions: {
      acceptMission: function (mission) {
        var data;
        if (!mission.get('admin_scheduled')) {
          data = {
            scheduled_at_start: mission.get('scheduled_at_start'),
            scheduled_at_end: mission.get('scheduled_at_end')
          };
        }
        return _jquery.default.ajax({
          url: _environment.default.api.host + "/v1/pilots/missions/" + mission.id + "/invitation",
          type: 'PATCH',
          dataType: 'json',
          headers: this.get('sessionAccount.headers'),
          data: data
        }).then(function (_this) {
          return function (response) {
            return _this.replaceWith('clientmission', mission.id);
          };
        }(this), function (response) {
          return this.replaceWith('unavailable-mission');
        });
      },
      declineMission: function (mission, _this) {
        mission.deleteRecord();
        return mission.save().then(function (_this) {
          return function () {
            return _this.replaceWith('dashboard');
          };
        }(this));
      }
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      return this.controllerFor('application').set('adminMode', !!this.get('session.data.authenticated.real_admin'));
    }
  });
  var _default = AvailableMissionRoute;
  _exports.default = _default;
});
define("pilots/components/mission-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionFiltersComponent;
  MissionFiltersComponent = Ember.Component.extend({
    classNames: ['row']
  });
  var _default = MissionFiltersComponent;
  _exports.default = _default;
});
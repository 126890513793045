define("pilots/templates/components/pilot-profile-drone-systems", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "q0edUvLZ",
    "block": "[[[10,0],[14,0,\"section-header\"],[12],[1,\"\\n  DRONE SYSTEM\\n\"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"model\",\"pilotDrone\",\"index\",\"destroyDrone\"],[[33,4],[30,1],[30,2],[28,[37,5],[[30,0],\"destroyDrone\"],null]]]]],[1,\"\\n\"]],[1,2]],null],[1,[28,[35,6],null,[[\"model\",\"create\",\"missingInfo\"],[[33,4],[28,[37,5],[[30,0],\"createDrone\"],null],[33,7]]]]],[1,\"\\n\"]],[\"pilotDrone\",\"index\"],false,[\"each\",\"-track-array\",\"sortedPilotDrones\",\"pilot-profile-drone-system\",\"model\",\"action\",\"pilot-profile-drone-system-new\",\"missingInfo\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-drone-systems.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
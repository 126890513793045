define("pilots/components/welcome-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    metrics: Ember.inject.service(),
    // will need later for GA
    showExampleMission: false,
    actions: {
      toggleShowExampleMission() {
        this.set('showExampleMission', !this.get('showExampleMission'));
      }
    }
  });
  _exports.default = _default;
});
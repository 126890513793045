define("pilots/components/weather-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WeatherIconComponent;
  WeatherIconComponent = Ember.Component.extend({
    tagName: 'canvas',
    classNames: 'weather-icon-canvas',
    attributeBindings: ['width', 'height'],
    width: 50,
    height: Ember.computed('width', function () {
      return this.get('width') / 2;
    }),
    didInsertElement: function () {
      var skycon;
      skycon = new Skycons({
        "color": "black"
      });
      skycon.add(this.element, this.get('icon'));
      return skycon.play();
    }
  });
  var _default = WeatherIconComponent;
  _exports.default = _default;
});
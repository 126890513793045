define("pilots/templates/components/pilot-profile-license-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gf87gCPh",
    "block": "[[[10,0],[15,0,[29,[\"item-row license \",[52,[33,1],\"missing-info\"]]]],[12],[1,\"\\n  \"],[10,1],[14,0,\"field-name new-license\"],[12],[1,\"DOCUMENT \"],[1,[34,2]],[13],[10,1],[14,0,\"field-spacer\"],[12],[13],[1,\"\\n\"],[1,\"  \"],[11,0],[24,0,\"field-value select-license\"],[16,\"onclick\",[28,[37,3],[[30,0],\"openModal\"],null]],[4,[38,3],[[30,0],\"openModal\"],null],[12],[1,\"\\n    \"],[10,1],[14,0,\"field-value-empty\"],[12],[1,[34,4]],[13],[1,\"\\n    \"],[10,1],[14,0,\"field-arrow icon-Arrow2_down\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"if\",\"missingInfo\",\"pilotLicenseNumber\",\"action\",\"cta\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-license-new.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/adapters/mission-reschedule", ["exports", "pilots/adapters/application", "pilots/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionRescheduleAdapter;
  MissionRescheduleAdapter = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + "/v2/pilots",
    urlTemplate: '{+namespace}/missions/{missionId}/schedule{/id}',
    urlSegments: {
      missionId: function (type, id, snaphot, query) {
        return snaphot.belongsTo('mission', {
          id: true
        });
      }
    }
  });
  var _default = MissionRescheduleAdapter;
  _exports.default = _default;
});
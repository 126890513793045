define("pilots/components/signup-select-preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SignupSelectPreferences = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: 'device-list',
    store: Ember.inject.service(),
    pageComplete: Ember.computed('model.pilot.travel_distance', 'model.pilot.is_available_weekdays', 'model.pilot.is_available_weekends', function () {
      return this.get('model.pilot.travel_distance') > 0 && (this.get('model.pilot.is_available_weekdays') || this.get('model.pilot.is_available_weekends'));
    }),
    actions: {
      toggleShowSkipModal() {
        this.toggleShowSkipModal();
      },
      selectDistance(distance) {
        this.set('model.pilot.travel_distance', distance);
      },
      toggleAvailability(availability) {
        const pilotAvailability = 'model.pilot.' + availability;
        this.set(pilotAvailability, !this.get(pilotAvailability));
      },
      next() {
        this.get('model.pilot').save();
        this.get('router').transitionTo('dashboard');
      }
    }
  });
  var _default = SignupSelectPreferences;
  _exports.default = _default;
});
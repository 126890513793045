define("pilots/templates/components/pilot-profile-equipment-existing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qeZzg5Lw",
    "block": "[[[10,0],[14,0,\"item-row equipment\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"equipment-item item-row btm-border-line\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"field-name\"],[12],[1,\"EQUIPMENT \"],[1,[34,0]],[13],[1,\"\\n    \"],[10,1],[14,0,\"field-spacer\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"equipment-name\"],[12],[1,\"\\n      \"],[1,[33,1,[\"pilot_equipment\",\"name\"]]],[1,\"\\n      \"],[11,1],[24,0,\"delete-me icon-Xmark\"],[4,[38,2],[[30,0],\"destroy\",[33,1]],null],[12],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"pilotEquipmentNumber\",\"pilotEquipment\",\"action\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-equipment-existing.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/routes/signup-flow-devices", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SignupFlowDevicesRoute = Ember.Route.extend(_authenticatedRouteMixin.default, {
    tagName: '',
    sessionAccount: Ember.inject.service(),
    model() {
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', 'stubId'),
        drones: this.store.findAll('drone'),
        pilotDrones: this.store.findAll('pilot-drone'),
        devices: this.store.findAll('device'),
        pilotDevices: this.store.findAll('pilot-device')
      });
    },
    setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('hideNav', true);
      model.sortedDrones = model.drones.toArray().sortBy('full_name');
      return this.controllerFor('application').set('adminMode', !!this.get('session.data.authenticated.real_admin'));
    }
  });
  var _default = SignupFlowDevicesRoute;
  _exports.default = _default;
});
define("pilots/templates/unavailable-mission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1KuXYFBt",
    "block": "[[[6,[39,0],null,[[\"translucentOverlay\",\"onClickOverlay\"],[true,\"returnToDashboard\"]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"close\"],[\"returnToDashboard\"]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"modal-dialog\",\"mission-unavailable-modal\"]]",
    "moduleName": "pilots/templates/unavailable-mission.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
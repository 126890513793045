define("pilots/templates/clientmissionupload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IN4VWuL2",
    "block": "[[[10,0],[14,0,\"client-mission\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"header\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"page-title\"],[12],[1,\"\\n      Upload Assets\\n    \"],[13],[1,\"\\n\\n\"],[6,[39,0],null,[[\"bubbles\",\"route\",\"model\"],[false,\"clientmission\",[33,1,[\"mission\",\"id\"]]]],[[\"default\"],[[[[1,\"      \"],[8,[39,2],null,[[\"@type\",\"@size\"],[\"text\",\"small\"]],[[\"default\"],[[[[1,\"\\n        < Return to Mission Details\\n      \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,3],null,[[\"model\",\"onfileadd\",\"onstartupload\"],[[33,1],[28,[37,4],[[30,0],[33,5],\"addAsset\"],null],\"startUpload\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"model\",\"button\",\"client-mission-upload\",\"action\",\"send\"]]",
    "moduleName": "pilots/templates/clientmissionupload.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;
  function capitalize(string) {
    if (string && string.length) {
      return string[0].toUpperCase();
    } else {
      return '';
    }
  }
  var _default = Ember.Helper.helper(capitalize);
  _exports.default = _default;
});
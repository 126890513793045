define("pilots/utils/init-qtip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initQtip;
  initQtip = function (selector) {
    var touchDevice;
    touchDevice = 'ontouchend' in window ? true : false;
    if (!touchDevice) {
      return $(selector).qtip({
        position: {
          my: 'top center',
          at: 'bottom center'
        },
        style: 'qtip-dark',
        hide: {
          event: 'click mouseleave'
        }
      });
    }
  };
  var _default = initQtip;
  _exports.default = _default;
});
define("pilots/templates/components/mission-scheduled-at-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+Ci0A0Af",
    "block": "[[[41,[33,1],[[[1,\" at \"],[1,[28,[35,2],null,[[\"model\",\"format\",\"timezone\"],[[33,3,[\"scheduled_at_start\"]],\"hh:mmA z\",[33,3,[\"location\",\"timezone_id\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"between \"],[1,[28,[35,2],null,[[\"model\",\"timezone\"],[[33,3,[\"scheduled_at_start\"]],[33,3,[\"location\",\"timezone_id\"]]]]]],[1,\" -\\n\"],[1,[28,[35,2],null,[[\"model\",\"format\",\"timezone\"],[[33,3,[\"scheduled_at_end\"]],\"hh:mmA z\",[33,3,[\"location\",\"timezone_id\"]]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"sameStartEndTime\",\"moment-format-local-time\",\"model\"]]",
    "moduleName": "pilots/templates/components/mission-scheduled-at-time.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/templates/components/pilot-profile-pilot-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tAVIqTNO",
    "block": "[[[10,0],[15,0,[29,[\"pilot-badge \",[52,[28,[37,1],[[33,2,[\"status\"]],\"pending\"],null],\"pending\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"badge-icon\"],[12],[1,\"\\n    \"],[1,[33,2,[\"abbreviation\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"badge-name\"],[12],[1,\"\\n    \"],[1,[33,2,[\"badge\",\"name\"]]],[1,\" \"],[10,1],[14,0,\"pending\"],[12],[1,[52,[28,[37,1],[[33,2,[\"status\"]],\"pending\"],null],\"(pending)\"]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"is-equal\",\"badge\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-pilot-badge.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/asset-uploader", ["exports", "jquery", "pilots/utils/w", "pilots/config/environment"], function (_exports, _jquery, _w, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  ;
  var AssetUploaderComponent;
  AssetUploaderComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    wrongFiles: [],
    fileProgress: 0,
    showNativeThumbnail: true,
    filesUploaded: 0,
    isBusy: Ember.computed("numFilesUploading", function () {
      return this.get("numFilesUploading") > 0;
    }),
    inProgress: Ember.observer('queue.progress', function () {
      var currentFilesCount, queue, uploader;
      queue = this.get('queue');
      if (queue) {
        uploader = queue.get('uploader');
        if (uploader) {
          currentFilesCount = uploader.files.length - queue.numFilesUploading;
          this.set('filesUploaded', uploader.total.uploaded - currentFilesCount);
          this.set('numFilesUploading', queue.numFilesUploading);
          if (this.get('numFilesUploading') > 0 && uploader.total.queued === 0) {
            this.set('numFilesUploading', 0);
            queue.clearNumUploading();
            this.set('filesUploaded', 0);
          }
        }
      }
      this.set('uploadProgress', this.get('filesUploaded') / this.get('numFilesUploading') * 100 || 0);
      this.notifyPropertyChange('filesUploaded');
      return this.notifyPropertyChange('numFilesUploading');
    }),
    startUploading: Ember.observer('queue.uploadsQueued', function () {
      var queue, uploader, uploadsQueued;
      uploadsQueued = this.get('queue.uploadsQueued');
      if (uploadsQueued) {
        queue = this.get('queue');
        uploader = queue.get('uploader');
        this.set('numFilesUploading', uploader.files.length);
        if (uploader.files.length > 100) {
          this.set('listView', true);
        }
        return Em.run.later(function () {
          return uploader.start();
        });
      }
    }),
    showInstructions: Ember.computed('queue.content.length', 'images.length', 'videos.length', function () {
      return this.get('queue.content.length') === 0 && this.get('images.length') === 0 && this.get('videos.length') === 0;
    }),
    showWrongFiles: Ember.computed('wrongFiles', function () {
      return this.get('wrongFiles.length') > 0;
    }),
    queueLookup: Ember.inject.service('upload-queue-manager'),
    runtimes: (0, _w.default)(['html5', 'html4']),
    extensions: (0, _w.default)(),
    'max-file-size': 0,
    'no-duplicates': true,
    multiple: true,
    classNames: ['asset-uploader'],
    onfileadd: null,
    onerror: null,
    config: Ember.computed(function () {
      var config, filters;
      config = {
        url: true,
        browse_button: "uploader" + (this.get('shot_type') || ''),
        filters: {
          max_file_size: this.get('max-file-size'),
          prevent_duplicates: this.get('no-duplicates')
        },
        multi_selection: this.get('multiple'),
        runtimes: this.get('runtimes').join(','),
        container: this.get('elementId'),
        uploadCount: this.get('uploadedCount')
      };
      filters = this.get('fileFilters') || {};
      Object.keys(filters).forEach(function (filter) {
        if (this.get(filter)) {
          return config.filters[filter] = this.get(filter);
        }
      });
      if (this.get('extensions.length')) {
        config.filters.mime_types = [{
          extensions: this.get('extensions').map(function (ext) {
            return ext.toLowerCase();
          }).join(',')
        }];
      }
      return config;
    }),
    didInsertElement: function () {
      this._super();
      return Ember.run.scheduleOnce('afterRender', this, 'attachUploadQueue');
    },
    attachUploadQueue: function () {
      var queue, queueLookup, queueName;
      queueLookup = this.get('queueLookup');
      queueName = 'uploader';
      queue = queueLookup.findOrCreate(queueName, this, this.get('config'));
      this.initDragDrop();
      return this.set('queue', queue);
    },
    destroyUploadQueue: Ember.on('willDestroyElement', function () {
      var queue;
      queue = this.get('queue');
      if (queue) {
        queue.uploader.destroy();
        return this.set('queue', null);
      }
    }),
    addAsset: function (file, not_used, model) {
      return this.onfileadd(file, this.get('shot_id'), model);
    },
    initDragDrop: function (target) {
      var elementName, uploadElement;
      elementName = target || this.get('shot_type') || 'upload-target';
      elementName = "." + elementName;
      uploadElement = $(elementName);
      return uploadElement.on('drag dragend dragover dragenter dragleave drop', function (e) {
        e.preventDefault();
        return e.stopPropagation();
      }).on('dragover dragenter', function (e) {
        if (e.dataTransfer.types.length !== 3) {
          return uploadElement.addClass('is-dragover');
        }
      }).on('dragleave dragend drop', function () {
        return uploadElement.removeClass('is-dragover');
      }).on('drop', function (_this) {
        return function (e) {
          return _this.addFilesAndUpload(e.originalEvent.dataTransfer.files);
        };
      }(this));
    },
    addFilesAndUpload: function (droppedFiles) {
      var file, i, len, queue, uploader, wrongFiles;
      queue = this.get('queue');
      uploader = queue.get('uploader');
      uploader.bind('FilesAdded', Ember.run.bind(this, 'afterFilesAdded'));
      wrongFiles = [];
      for (i = 0, len = droppedFiles.length; i < len; i++) {
        file = droppedFiles[i];
        if (file.type.match(/video|image/)) {
          uploader.addFile(file);
        } else {
          wrongFiles.pushObject(file.name);
        }
      }
      if (wrongFiles) {
        return this.set('wrongFiles', wrongFiles);
      }
    },
    afterFilesAdded: function () {
      return Em.run.later(function (_this) {
        return function () {
          var queue, uploader;
          queue = _this.get('queue');
          uploader = queue.get('uploader');
          return uploader.start();
        };
      }(this));
    },
    uploadsComplete: function () {
      var confirm;
      confirm = window.confirm('Have you uploaded all assets for this mission?');
      if (!confirm) {
        return;
      }
      return this.updateStatus('assets_uploaded');
    },
    updateStatus: function (newStatus) {
      var _mission, headers;
      _mission = this.get('mission');
      _mission.set('status', newStatus);
      headers = this.get('sessionAccount.headers');
      return _jquery.default.ajax({
        url: _environment.default.api.host + "/v1/pilots/missions/" + _mission.id + "/status/" + newStatus,
        type: 'PATCH',
        dataType: 'json',
        headers: headers
      }).then(function (response) {
        _mission.reload();
        return console.log('success');
      }, function (response) {
        return console.log('fail');
      });
    },
    deselectAll: function () {},
    actions: {
      uploadsComplete: function () {
        return this.uploadsComplete();
      },
      startUpload: function (uploader) {
        return this.sendAction('onstartupload', uploader);
      },
      addAsset: function (file, shot_id, model) {
        return this.onfileadd(file, shot_id, model);
      },
      hideShowWrongFiles: function () {
        return this.set('wrongFiles', []);
      }
    }
  });
  var _default = AssetUploaderComponent;
  _exports.default = _default;
});
define("pilots/mirage/factories/image", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jshint esversion: 6 */
  var _default = _emberCliMirage.Factory.extend({
    mission: (0, _emberCliMirage.association)(),
    shot: (0, _emberCliMirage.association)()
  });
  _exports.default = _default;
});
define("pilots/components/upload-assets-other-app", ["exports", "jquery", "pilots/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _jquery, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  var UploadAssetsOtherAppComponent;
  UploadAssetsOtherAppComponent = Ember.Component.extend(_fileSaver.default, {
    classNames: ['upload-assets-with-other-app'],
    sessionAccount: Ember.inject.service(),
    cyberduckStatus: null,
    initCyberDuck: Ember.on('init', function () {
      if (this.get('mission.flight_app.value.secret_key')) {
        return this.set('cyberduckStatus', 'COMPLETE');
      }
    }),
    actions: {
      startUploading: function () {
        var _this;
        _this = this;
        this.set('cyberduckStatus', 'LOADING');
        return _jquery.default.ajax({
          url: _environment.default.api.host + "/v1/pilots/missions/" + this.get('mission.id') + "/cyberduck",
          type: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers')
        }).then(function (response) {
          return _this.get('mission').reload().then(function () {
            return _this.set('cyberduckStatus', 'COMPLETE');
          });
        });
      },
      copyKey: function (id) {
        var copyText;
        copyText = document.getElementById(id);
        copyText.select();
        document.execCommand("copy");
        this.set('textCopied', true);
        return setTimeout(function (_this) {
          return function () {
            return _this.set('textCopied', false);
          };
        }(this), 3000);
      },
      getDuckFile: function () {
        return _jquery.default.ajax({
          url: _environment.default.api.host + "/v1/pilots/missions/" + this.get('mission.id') + "/cyberduck",
          type: 'GET',
          headers: this.get('sessionAccount.headers')
        }).then(function (_this) {
          return function (response) {
            var bb, blob, error, filename;
            try {
              blob = new Blob([response], {
                type: 'application/octet-stream'
              });
            } catch (error1) {
              error = error1;
              window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
              if (error.name === 'TypeError' && window.BlobBuilder) {
                bb = new BlobBuilder();
                bb.append(response);
                blob = bb.getBlob("application/octet-stream");
              } else if (e.name === 'InvalidStateError') {
                blob = new Blob([response], {
                  type: 'application/octet-stream'
                });
              } else {
                alert('Downloading is not supported on your device.');
              }
            }
            filename = _this.get('mission.id') + '.duck';
            return _this.saveFileAs(filename, blob, 'application/octet-stream');
          };
        }(this));
      },
      doneUploading: function () {
        var _this;
        _this = this;
        this.set('startingUploading', true);
        return _jquery.default.ajax({
          url: _environment.default.api.host + "/v1/pilots/missions/" + this.get('mission.id') + "/cyberduck/complete",
          type: 'POST',
          headers: this.get('sessionAccount.headers')
        }).then(function (response) {
          _this.set('startingUploading', false);
          _this.get('mission').reload();
          return location.reload();
        });
      }
    }
  });
  var _default = UploadAssetsOtherAppComponent;
  _exports.default = _default;
});
define("pilots/helpers/temperature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TemperatureHelper;
  TemperatureHelper = Ember.Helper.helper(function (arg) {
    var number, type;
    number = arg[0], type = arg[1];
    return Math.round(number) + "°" + type;
  });
  var _default = TemperatureHelper;
  _exports.default = _default;
});
define("pilots/templates/components/scheduling-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kYqytcNX",
    "block": "[[[41,[33,1,[\"admin_scheduled\"]],[[[1,\"  This mission is prescheduled. The client requires this Mission be flown on \"],[1,[28,[35,2],[[33,1,[\"scheduled_at_start\"]],\"dddd M/D/YY\"],[[\"timeZone\"],[[33,1,[\"location\",\"timezone_id\"]]]]]],[1,\" at \"],[1,[28,[35,3],null,[[\"model\",\"timezone\"],[[33,1,[\"scheduled_at_start\"]],[33,1,[\"location\",\"timezone_id\"]]]]]],[1,\".\\n\"]],[]],[[[1,\"  The client requires this Mission be flown by \"],[1,[34,4]],[1,\". You must fly this Mission \"],[1,[34,5]],[1,\"between \"],[1,[28,[35,6],[[33,1,[\"timeframe_start\"]]],null]],[1,\" - \"],[1,[28,[35,6],[[33,1,[\"timeframe_end\"]]],null]],[1,\".\\n\"]],[]]]],[],false,[\"if\",\"mission\",\"moment-format\",\"moment-format-local-time\",\"lastDayOfSLA\",\"ifWeekendText\",\"format-hour\"]]",
    "moduleName": "pilots/templates/components/scheduling-copy.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/textarea-trigger-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TextareaTriggerSaveComponent;
  TextareaTriggerSaveComponent = Ember.TextArea.extend({
    classNames: ['form-control'],
    focusOut: function () {
      return this.get('parentView.controller').send('save');
    }
  });
  var _default = TextareaTriggerSaveComponent;
  _exports.default = _default;
});
define("pilots/components/work-order/upload-table-row", ["exports", "jquery", "pilots/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _jquery, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global BlobBuilder */
  var _default = Ember.Component.extend(_fileSaver.default, {
    sessionAccount: Ember.inject.service(),
    tagName: 'tr',
    startingUploading: false,
    complete: false,
    actions: {
      copyKey: function (id) {
        const _this = this;
        const copyText = document.getElementById(`copy-${id}`);
        copyText.select();
        document.execCommand("copy");
        this.set('textCopied', true);
        setTimeout(function () {
          _this.set('textCopied', false);
        }, 3000);
      },
      doneUploading: function (mission_id) {
        const _this = this;
        this.set('startingUploading', true);
        _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/pilots/missions/${mission_id}/cyberduck/complete`,
          type: 'POST',
          headers: this.get('sessionAccount.headers')
        }).then(function () {
          _this.set('startingUploading', false);
          _this.set('complete', true);
        });
      },
      getDuckFile: function (mission_id) {
        const _this = this;
        _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/pilots/missions/${mission_id}/cyberduck`,
          type: 'GET',
          headers: this.get('sessionAccount.headers')
        }).then(function (response) {
          let blob = null;
          try {
            blob = new Blob([response], {
              type: 'application/octet-stream'
            });
          } catch (error) {
            window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
            let blob;
            if (error.name === 'TypeError' && window.BlobBuilder) {
              let bb = new BlobBuilder();
              bb.append(response);
              blob = bb.getBlob("application/octet-stream");
            } else if (error.name === 'InvalidStateError') {
              blob = new Blob([response], {
                type: 'application/octet-stream'
              });
            } else {
              alert('Downloading is not supported on your device.');
            }
          }
          const filename = mission_id + '.duck';
          _this.saveFileAs(filename, blob, 'application/octet-stream');
        });
      }
    }
  });
  _exports.default = _default;
});
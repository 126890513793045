define("pilots/templates/availablemissionwebview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nYKuIIAY",
    "block": "[[[10,0],[14,0,\"available-mission available-mission-web-view\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"model\",\"acceptMission\",\"declineMission\",\"openMaps\"],[[33,1],[28,[37,2],[[30,0],[33,3],\"acceptMission\"],null],[28,[37,2],[[30,0],[33,3],\"declineMission\"],null],[28,[37,2],[[30,0],[33,3],\"openMaps\"],null]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"available-mission\",\"model\",\"action\",\"send\"]]",
    "moduleName": "pilots/templates/availablemissionwebview.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
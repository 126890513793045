define("pilots/helpers/multiplication", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MultiplicationHelper;
  MultiplicationHelper = Ember.Helper.helper(function (arg) {
    var value1, value2;
    value1 = arg[0], value2 = arg[1];
    if (!value1) {
      value1 = 0;
    }
    return value1 * value2;
  });
  var _default = MultiplicationHelper;
  _exports.default = _default;
});
define("pilots/components/moment-format-local-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MomentFormatLocalTimeComponent;
  MomentFormatLocalTimeComponent = Ember.Component.extend({
    tagName: '',
    format: 'hh:mmA'
  });
  var _default = MomentFormatLocalTimeComponent;
  _exports.default = _default;
});
define("pilots/components/dashboard-mission-map", ["exports", "jquery", "pilots/config/environment", "pilots/data/mission_type", "pilots/data/map_features", "pilots/mixins/google-init-mixin"], function (_exports, _jquery, _environment, _mission_type, _map_features, _googleInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  ;
  var DashboardMissionMapComponent;
  DashboardMissionMapComponent = Ember.Component.extend(_googleInitMixin.default, {
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    store: Ember.inject.service(),
    assetMap: Ember.inject.service(),
    classNameBindings: ['pilotlog:map-container:map-container-pilot-dashboard'],
    pilotlog: false,
    smallDevice: false,
    mapStyles: _map_features.default.mapStylesData,
    missionMarkers: Ember.A([]),
    missionFilters: Ember.A([]),
    missionTypeData: _mission_type.default,
    assetsDir: 'assets/images/v2/',
    actions: {
      declineMission: function (mission) {
        this.get('missions').removeObject(mission);
        this.loadMissions(true);
        return this.get('metrics').trackEvent({
          event: 'event',
          category: 'Active Missions',
          action: 'click',
          label: "Decline Available Mission"
        });
      },
      selectedMission: function (mission) {
        this.selectMission(mission, true);
        return this.get('metrics').trackEvent({
          event: 'event',
          category: 'Active Missions',
          action: 'click',
          label: "Select Mission From Panel"
        });
      },
      availableMission: function (mission) {
        return this.get('store').findRecord('mission', mission.id).then(function (_this) {
          return function (mission) {
            return _this.get('router').transitionTo('availablemission', mission.id);
          };
        }(this), function (_this) {
          return function (response) {
            alert('This mission is no longer available...');
            _this.get('missions').removeObject(mission);
            return _this.loadMissions(true);
          };
        }(this));
      }
    },
    init: function () {
      this._super();
      this.setupFilters();
      if (window.innerWidth <= 767) {
        return this.smallDevice = true;
      }
    },
    setupFilters: function () {
      var MissionType;
      MissionType = Em.Object.extend({
        type: null,
        color: null,
        fontIcon: null,
        png: null,
        name: null,
        selected: true,
        selected_png: null
      });
      return this.missionTypeData.forEach(function (_this) {
        return function (mission_type) {
          var icon_png, icon_png_array, missionType;
          missionType = MissionType.create(mission_type);
          icon_png_array = missionType.png.split('.');
          icon_png_array[0] = icon_png_array[0] + '_selected';
          icon_png = icon_png_array.join('.');
          missionType.selected_png = _this.get('assetMap').resolve(_this.assetsDir + icon_png);
          missionType.png = _this.get('assetMap').resolve(_this.assetsDir + missionType.png);
          return _this.get('missionFilters').pushObject(missionType);
        };
      }(this));
    },
    afterGoogleLoaded: function () {
      var mapOptions, zoom;
      zoom = this.smallDevice ? 13 : 15;
      mapOptions = {
        zoom: zoom,
        tilt: 0,
        streetViewControl: false,
        scaleControl: true,
        panControl: true,
        zoomControl: true,
        draggable: true,
        scrollwheel: !this.get('publicsearch'),
        styles: this.mapStyles,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM
        }
      };
      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
      }
      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('map-filter-container'));
      this.infoWindow = new google.maps.InfoWindow();
      return this.locateMe().then(function (_this) {
        return function () {
          _this.loadMissions(true);
          _this.setupInfoWindow();
          return google.maps.event.addListener(_this.map, 'click', function () {
            return _this.infoWindow.close();
          });
        };
      }(this));
    },
    setupInfoWindow: function () {
      var _this;
      _this = this;
      this.infoWindow = new google.maps.InfoWindow();
      this.infoWindowNode = (0, _jquery.default)('#info-window-node');
      return this.infoWindow.addListener('domready', function (event) {
        var iwBackground, iwCloseBtn, iwOuter;
        iwOuter = (0, _jquery.default)('.gm-style-iw');
        iwOuter.children().first().css({
          'display': 'block'
        });
        iwBackground = iwOuter.prev();
        iwBackground.children(':nth-child(2)').css({
          'display': 'none'
        });
        iwBackground.children(':nth-child(4)').css({
          'display': 'none'
        });
        iwBackground.children(':nth-child(3)').find('div').children().css({
          'z-index': 1,
          'box-shadow': '0 1px 6px rgba(178, 178, 178, 0.6)'
        });
        iwCloseBtn = iwOuter.next();
        return iwCloseBtn.css({
          'display': 'none'
        });
      });
    },
    defaultLocation: function () {
      return new google.maps.LatLng(34.019341, -118.493139);
    },
    locateMe: function () {
      return new Promise(function (_this) {
        return function (resolve, reject) {
          var lat, lat_lng, lon;
          try {
            lat = Number(_this.get('sessionAccount.account').get('latitude'));
            lon = Number(_this.get('sessionAccount.account').get('longitude'));
            if (isNaN(lat)) {
              lat_lng = _this.defaultLocation();
            } else {
              lat_lng = new google.maps.LatLng(lat, lon);
            }
          } catch (error) {
            lat_lng = _this.defaultLocation();
          }
          if (navigator.geolocation) {
            console.log('navigator.geolocation: true');
            navigator.geolocation.getCurrentPosition(function (response) {
              console.log('geo coded location');
              lat_lng = new google.maps.LatLng(response.coords.latitude, response.coords.longitude);
              _this.map.setCenter(lat_lng);
              _this.get('metrics').trackEvent({
                event: 'event',
                category: 'Active Missions',
                action: 'click',
                label: "Map Location: Geolocated"
              });
              return resolve(true);
            }, function (response) {
              console.log('pilot account/default location');
              _this.map.setCenter(lat_lng);
              _this.get('metrics').trackEvent({
                event: 'event',
                category: 'Active Missions',
                action: 'click',
                label: "Map Location: Pilot Account Location"
              });
              return resolve(true);
            });
          } else {
            console.log('navigator.geolocation: false');
            lat_lng = new google.maps.LatLng(lat, lon);
            _this.map.setCenter(lat_lng);
            _this.get('metrics').trackEvent({
              event: 'event',
              category: 'Active Missions',
              action: 'click',
              label: "Map Location: Default"
            });
            resolve(true);
          }
          return setTimeout(function () {
            if (_this.map.center == null) {
              console.log('firefox pilot account/default location after 5sec timeout');
              _this.map.setCenter(lat_lng);
              _this.get('metrics').trackEvent({
                event: 'event',
                category: 'Active Missions',
                action: 'click',
                label: "Map Location: Default (After FF Timeout)"
              });
              return resolve(true);
            }
          }, 5000);
        };
      }(this));
    },
    generateInfoNodeContent: function (mission) {
      var address, content, decline_button, header, icon, payoutCurrencyCode, payoutCurrencySymbol, payoutNumber, scheduled_date, scheduled_time, uniqueLocationName, viewDetails;
      if (mission.get('mission_type') === 'client') {
        if (mission.get('status') === 'pilots_notified') {
          header = "Available Client Mission";
          icon = "available-mission-pin";
          decline_button = "<div data-mission-id=" + mission.get('id') + " id='decline-mission-button' class='zv-button default text medium'>Decline</div>";
          viewDetails = "/availablemission/" + mission.id;
        } else {
          header = "Client Mission";
          icon = "client-mission-pin";
          decline_button = "";
          viewDetails = "/clientmission/" + mission.id;
        }
        if (mission.get('scheduled_at_start')) {
          scheduled_date = moment.tz(mission.get('scheduled_at_start'), mission.get('location.timezone_id')).format('MM/DD/YYYY');
          scheduled_time = moment.tz(mission.get('scheduled_at_start'), mission.get('location.timezone_id')).format('hh:mmA') + " - " + moment.tz(mission.get('scheduled_at_end'), mission.get('location.timezone_id')).format('hh:mmA');
        } else {
          scheduled_date = mission.get('flightDateWindow');
          scheduled_time = "";
        }
      }
      address = "Location Unknown";
      uniqueLocationName = "";
      payoutNumber = mission.get('pilotEstimatedPayoutWithCurrency.amount');
      payoutCurrencyCode = mission.get('pilotEstimatedPayoutWithCurrency.currency.code');
      payoutCurrencySymbol = mission.get('pilotEstimatedPayoutWithCurrency.currency.symbol');
      if (mission.get('location.formatted_address')) {
        address = mission.get('location.formatted_address');
      }
      if (mission.get('location.uniqueLocationName')) {
        address = mission.get('location.uniqueLocationName') + '<br />' + address;
      }
      content = "<div class='info-window-panel'> <div class='header'> <div class='icon " + icon + "'></div> <div class='head'> <div class='title'> <div>" + header + "</div> <div>" + payoutCurrencySymbol + (payoutNumber / 100 || 0) + " " + payoutCurrencyCode + "</div> </div> <div class='subtitle hidden-xs'>" + address + "</div> </div> </div> <div class='body'> <div class='subtitle visible-xs'>" + address + "</div> <div class='datetime'>" + (scheduled_time ? [scheduled_date, scheduled_time].join(', ') : scheduled_date) + "</div> </div> <div class='buttons'> <a href='" + viewDetails + "' class='zv-button default primary medium'> View details </a> " + decline_button + " </div> </div>";
      return content;
    },
    getIcon: function (mission, selected) {
      var anchor, icon, icon_png;
      if (selected == null) {
        selected = false;
      }
      icon_png = this.get('missionFilters').findBy('type', mission.get('missionStatusType')).get('png');
      anchor = new google.maps.Point(0, 0);
      if (selected) {
        icon_png = this.get('missionFilters').findBy('type', mission.get('missionStatusType')).get('selected_png');
        anchor = new google.maps.Point(17, 13);
      }
      icon = {
        url: icon_png,
        anchor: anchor,
        type: mission.get('missionStatusType')
      };
      return icon;
    },
    selectMission: function (mission, pan_to) {
      var selected_missions;
      if (pan_to == null) {
        pan_to = false;
      }
      selected_missions = this.get('missions').filterBy('selected_on_dashboard', true);
      if (selected_missions) {
        selected_missions.forEach(function (_this) {
          return function (selected_mission) {
            selected_mission.get('map_marker').setIcon(_this.getIcon(selected_mission));
            return selected_mission.set('selected_on_dashboard', false);
          };
        }(this));
      }
      mission.set('selected_on_dashboard', true);
      mission.get('map_marker').setIcon(this.getIcon(mission, true));
      if (pan_to) {
        return this.map.panTo(mission.get('map_marker').getPosition());
      }
    },
    createMarker: function (mission) {
      var _this, marker;
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(mission.get('location.latitude'), mission.get('location.longitude')),
        map: this.map,
        icon: this.getIcon(mission)
      });
      _this = this;
      mission.set('map_marker', marker);
      google.maps.event.addListener(marker, 'click', function (_this) {
        return function () {
          var content, declineButton;
          _this.selectMission(mission);
          _this.infoWindow.setPosition(marker.position);
          content = _this.generateInfoNodeContent(mission);
          _this.infoWindow.setOptions({
            content: content,
            pixelOffset: new google.maps.Size(10, -30),
            disableAutoPan: false
          });
          _this.infoWindowNode = (0, _jquery.default)('#info-window-node');
          _this.infoWindow.open(_this.map);
          declineButton = document.getElementById('decline-mission-button');
          if (declineButton) {
            return declineButton.addEventListener('click', function () {
              _this.infoWindow.close();
              marker.setMap(null);
              return _this.sendAction('deleteAction', mission);
            });
          }
        };
      }(this));
      return marker;
    },
    clearMissionMarkers: function () {
      this.missionMarkers.forEach(function (marker) {
        return marker.setMap(null);
      });
      return this.missionMarkers.clear();
    },
    loadMissions: function (set_bounds) {
      var bounds;
      if (set_bounds == null) {
        set_bounds = false;
      }
      bounds = new google.maps.LatLngBounds();
      this.clearMissionMarkers();
      this.get('missions').forEach(function (_this) {
        return function (mission) {
          var marker;
          marker = _this.createMarker(mission);
          if (!marker) {
            return;
          }
          bounds.extend(marker.getPosition());
          return _this.missionMarkers.pushObject(marker);
        };
      }(this));
      if (set_bounds) {
        return this.map.fitBounds(bounds);
      }
    }
  });
  var _default = DashboardMissionMapComponent;
  _exports.default = _default;
});
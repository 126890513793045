define("pilots/models/reschedule-reason", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    slug: (0, _model.attr)('string'),
    short: (0, _model.attr)('string'),
    is_pilot_selectable: (0, _model.attr)('boolean'),
    blurb: (0, _model.attr)('string')
  });
  _exports.default = _default;
});
define("pilots/components/validated-checkbox-image-auto-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ValidatedCheckboxImageAutoSaveComponent;
  ValidatedCheckboxImageAutoSaveComponent = Ember.Component.extend({
    classNames: ['roundCheckbox'],
    setImage: function () {
      if (this.get('changeset').get(this.get('propertyName'))) {
        return this.element.querySelector('img').src = '/assets/images/ui/checkmark_selected.svg';
      } else {
        return this.element.querySelector('img').src = '/assets/images/ui/checkmark_unselected.svg';
      }
    },
    didInsertElement: function () {
      return this.setImage();
    },
    actions: {
      validateProperty: function (changeset, property) {
        var currrentValue;
        currrentValue = this.get('changeset').get(this.get('propertyName'));
        this.get('changeset').set(this.get('propertyName'), !currrentValue);
        return changeset.validate(property).then(function (_this) {
          return function () {
            if (changeset.get('isValid')) {
              _this.setImage();
              return changeset.save();
            }
          };
        }(this));
      }
    }
  });
  var _default = ValidatedCheckboxImageAutoSaveComponent;
  _exports.default = _default;
});
define("pilots/templates/components/pilot-profile-drone-system-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/UeEiwU4",
    "block": "[[[10,0],[15,0,[29,[\"item-row drone-system \",[52,[33,1],\"missing-info\"]]]],[12],[1,\"\\n  \"],[10,1],[14,0,\"field-name new\"],[12],[1,\"DRONE \"],[1,[34,2]],[13],[10,1],[14,0,\"field-spacer\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"field-value select-drone\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"field-value-empty\"],[12],[1,[34,3]],[13],[10,1],[14,0,\"field-arrow icon-Arrow2_down\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"drone-dropdown\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[33,6,[\"sortedDrones\"]]],null]],null],null,[[[1,\"      \"],[11,\"li\"],[4,[38,7],[[30,0],\"create\",[30,1]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"item-creator\"],[12],[1,[30,1,[\"full_name\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"drone\"],false,[\"if\",\"missingInfo\",\"pilotDroneNumber\",\"cta\",\"each\",\"-track-array\",\"model\",\"action\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-drone-system-new.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
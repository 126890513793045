define("pilots/adapters/pilot-pilot-equipment", ["exports", "pilots/adapters/application", "pilots/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotPilotEquipmentAdapter;
  PilotPilotEquipmentAdapter = _application.default.extend({
    namespace: 'v1/pilots'
  });
  var _default = PilotPilotEquipmentAdapter;
  _exports.default = _default;
});
define("pilots/mirage/factories/mission", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jshint esversion: 6 */
  var _default = _emberCliMirage.Factory.extend({
    status: 'pilot_accepted',
    mission_type: 'client',
    rejection_reason: null,
    "instructions": null,
    "estimated_pilot_payout": "4500.0",
    "pilot_comment": null,
    "created_on": "2018-09-10T20:47:05Z",
    "admin_scheduled": false,
    // "fly_by_date":"2019-07-15",
    // "scheduled_at_start":"2018-09-19T18:00:00Z",
    // "scheduled_at_end":"2018-09-19T19:00:00Z",
    "duration_in_seconds": 3600,
    "is_on_hold": false,
    "cloud_reqs": "less_than_100",
    "reference_id": null,
    "sla_expired": false,
    "weekend_schedule_filter": "any_day",
    "timeframe_start": 660,
    "timeframe_end": 900,
    "pilot_script": null,
    "description": null,
    "pilot_arrived_at": null,
    "pilot_departed_at": null,
    "laanc_authorization_required": false,
    "laanc_authorization_granted": false,
    "passed_gps_check": false,
    location: (0, _emberCliMirage.association)(),
    afterCreate(mission, server) {
      mission.update({
        shots: server.createList('shot', 3)
      });
    }
  });
  _exports.default = _default;
});
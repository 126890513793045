define("pilots/models/shot", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    instructions: _emberData.default.attr('string'),
    pilot_comment: _emberData.default.attr('string'),
    origin_type: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    mission: _emberData.default.belongsTo('mission', {
      async: false
    }),
    shot_type: _emberData.default.belongsTo('shot_type', {
      async: false
    }),
    images: _emberData.default.hasMany('images', {
      async: false
    }),
    videos: _emberData.default.hasMany('videos', {
      async: false
    }),
    map_feature: _emberData.default.belongsTo('map_feature', {
      async: false
    }),
    post_processes: _emberData.default.hasMany('post-process'),
    assetsCount: Ember.computed('images.[]', 'videos.[]', function () {
      return this.get('images.length') + this.get('videos.length');
    }),
    readyToSubmit: Ember.computed('assetsCount', 'pilot_comment', function () {
      return !!this.get('assetsCount') || !!this.get('pilot_comment');
    }),
    isDeletable: false,
    hasMissingGpsInfo: Ember.computed('missingGpsCount', function () {
      return this.get('missingGpsCount') > 0;
    }),
    missingGpsCount: Ember.computed('imagesMissingGpsInfo', function () {
      return this.get('imagesMissingGpsInfo').length;
    }),
    imagesMissingGpsInfo: Ember.computed.filterBy('images', 'isMissingGpsInfo', true),
    hasOrthoPostProcess: Ember.computed('post_processes.[]', function () {
      return this.get('post_processes').filter(postProcess => ['thermal_ortho_stitching', 'rgb_ortho_stitching'].includes(postProcess.slug)).length > 0;
    })
  });
  _exports.default = _default;
});
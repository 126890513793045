define("pilots/components/coverage-check/flight-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: 'coverage-other-info',
    flightDuration: Ember.computed('images.[].taken_at', function () {
      const sortedImages = this.get('images').sort(function (a, b) {
        return moment(b.taken_at) - moment(a.taken_at);
      });
      if (sortedImages.length) {
        const duration = moment(sortedImages.firstObject.taken_at).diff(moment(sortedImages.lastObject.taken_at));
        return moment.utc(duration).format("mm:ss");
      }
    }),
    imagesCount: Ember.computed('images.length', function () {
      return this.get('images.length');
    }),
    actions: {
      startOver: function () {
        this.get('startCheckOver')();
      }
    }
  });
  _exports.default = _default;
});
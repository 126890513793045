define("pilots/routes/clientmissiongpscheck", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ClientMissionGPSCheckRoute = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function (params) {
      return Ember.RSVP.hash({
        pilot: this.store.findRecord('pilot', 'stubId'),
        mission: this.store.find('mission', params.mission_id).then(response => {
          if (response.get('status') === 'pilots_notified') {
            this.replaceWith('availablemission', response.id);
          }
          return response;
        }, error => {
          console.log("error loading ClientMissionRoute: " + error);
          return this.replaceWith('fourOhFour', {});
        })
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      return this.controllerFor('application').set('adminMode', !!this.get('session.data.authenticated.real_admin'));
    },
    actions: {
      refreshRoute: function () {
        this.refresh();
        return false;
      }
    }
  });
  var _default = ClientMissionGPSCheckRoute;
  _exports.default = _default;
});
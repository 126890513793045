define("pilots/adapters/mission", ["exports", "pilots/adapters/application", "pilots/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionAdapter;
  MissionAdapter = _application.default.extend({
    namespace: 'v2/pilots',
    deleteRecord: function (store, type, snapshot) {
      var id;
      id = snapshot.id;
      return this.ajax(_environment.default.api.host + "/v1/pilots/missions/" + id + "/invitation", "DELETE");
    },
    handleResponse: function (status, headers, payload, requestData) {
      if (requestData.method === 'DELETE') {
        status = 204;
        payload = {};
        payload.meta = {};
        payload.meta.success = true;
        headers = {};
      }
      return this._super(status, headers, payload, requestData);
    }
  });
  var _default = MissionAdapter;
  _exports.default = _default;
});
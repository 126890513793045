define("pilots/models/map-feature", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    geometry_type: _emberData.default.attr('string'),
    geometry: _emberData.default.attr(''),
    properties: _emberData.default.attr(''),
    featureSize: Ember.computed('properties', function () {
      const area = this.get('properties.rgeo_area') || this.get('properties.area');
      if (area) {
        return this.formatArea(area);
      } else {
        return this.formatLength(this.get('properties.rgeo_perimeter'));
      }
    }),
    formatLength(length) {
      if (!length) {
        return '';
      }
      let formatted = `${length.toLocaleString()} meter`;
      if (length !== 1.0) {
        formatted += 's';
      }
      return `${formatted} (${this.metersToFeet(length)})`;
    },
    formatArea(area) {
      if (!area) {
        return '';
      }
      return `${this.metersToAcres(area)} (${this.metersToFeetSqr(area)})`;
    },
    metersToAcres(area) {
      const val = (area * 0.0002471).toFixed(2);
      let acres = `${val} acre`;
      if (val !== 1) {
        acres += 's';
      }
      return acres;
    },
    metersToFeet(area) {
      const val = Math.round(area * 3.2808).toLocaleString();
      return `${val} feet`;
    },
    metersToFeetSqr(area) {
      const val = Math.round(area * 10.76391).toLocaleString();
      return `${val} sqr feet`;
    }
  });
  _exports.default = _default;
});
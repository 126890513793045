define("pilots/models/category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Category;
  Category = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    ordinality: _emberData.default.attr('number')
  });
  var _default = Category;
  _exports.default = _default;
});
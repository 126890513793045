define("pilots/templates/components/pilot-profile-licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VaKTEGfv",
    "block": "[[[10,0],[14,0,\"section-header\"],[12],[1,\"\\n  DOCUMENTATION\\n\"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2,[\"pilotLicenses\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"model\",\"pilotLicense\",\"index\",\"destroyLicense\"],[[33,2],[30,1],[30,2],[28,[37,4],[[30,0],\"destroyLicense\"],null]]]]],[1,\"\\n\"]],[1,2]],null],[41,[33,6],[[[1,\"  \"],[1,[28,[35,7],null,[[\"model\",\"createLicense\",\"missingInfo\",\"isModalOpen\",\"openModal\"],[[33,2],[28,[37,4],[[30,0],\"newLicense\"],null],[33,8],[33,9],[28,[37,4],[[30,0],\"newLicense\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,[28,[35,10],null,[[\"isModalOpen\",\"model\",\"pilot\",\"availableLicenses\",\"closeModal\",\"addLicense\"],[[33,9],[33,2],[33,2,[\"pilot\"]],[33,11],[28,[37,4],[[30,0],\"closeModal\"],null],[28,[37,4],[[30,0],\"createLicense\"],null]]]]],[1,\"\\n\"]],[\"pilotLicense\",\"index\"],false,[\"each\",\"-track-array\",\"model\",\"pilot-profile-license\",\"action\",\"if\",\"licensesAvailable\",\"pilot-profile-license-new\",\"missingInfo\",\"isModalOpen\",\"pilot-license-modal\",\"availableLicenses\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-licenses.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
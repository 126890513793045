define("pilots/templates/components/side-menu-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ShbdrbH0",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[14,0,\"toggle-bars\"],[12],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "pilots/templates/components/side-menu-toggle.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/templates/components/pilot-profile-devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4p5b44ns",
    "block": "[[[10,0],[14,0,\"section-header\"],[12],[1,\"\\n  DEVICE\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"secion-description\"],[12],[1,\"\\n  Enter the device(s) you use to fly your drone(s)\\n\"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2,[\"pilotDevices\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"model\",\"pilotDevice\",\"index\",\"destroyDevice\"],[[33,2],[30,1],[30,2],[28,[37,4],[[30,0],\"destroyDevice\"],null]]]]],[1,\"\\n\"]],[1,2]],null],[41,[33,6],[[[1,\"  \"],[1,[28,[35,7],null,[[\"model\",\"createDevice\",\"missingInfo\"],[[33,2],[28,[37,4],[[30,0],\"createDevice\"],null],[33,8]]]]],[1,\"\\n\"]],[]],null]],[\"device\",\"index\"],false,[\"each\",\"-track-array\",\"model\",\"pilot-profile-device\",\"action\",\"if\",\"devicesAvailable\",\"pilot-profile-device-new\",\"missingInfo\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-devices.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/templates/components/mission-unavailable-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "t1tueVQ+",
    "block": "[[[10,0],[14,0,\"ember-modal-inner-wrap mission-unavailable-modal\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-close-container\"],[12],[1,\"\\n    \"],[11,3],[4,[38,0],[[30,0],\"toggleModal\"],null],[12],[10,\"i\"],[14,0,\"icon-Xmark\"],[12],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/mission_unavailable.svg\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"header\"],[12],[1,\"Sorry Pilot!\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"body\"],[12],[1,\"\\n    This mission is no longer available. Stay tuned for your next opportunity to fly.\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[4,[38,0],[[30,0],\"toggleModal\"],null]],null,[[\"default\"],[[[[1,\"\\n    Return to Dashboard\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\",\"button\"]]",
    "moduleName": "pilots/templates/components/mission-unavailable-modal.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
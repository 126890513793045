define("pilots/routes/availablemissionwebview", ["exports", "pilots/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    tagName: '',
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    model(params) {
      const urlParams = new URL(window.location).searchParams;
      const source = urlParams.get('source');
      this.controllerFor('application').set('source', source);
      const tokens = this.sessionAccount.getAuthTokens(urlParams);
      if (Ember.isPresent(tokens.password)) {
        return this.get('session').authenticate('authenticator:devise', tokens.email, tokens.password).then(() => {
          this.sessionAccount.setAuth0Tokens(tokens);
          console.log('authed');
          return Ember.RSVP.hash({
            source,
            pilotDrones: this.store.findAll('pilot-drone'),
            pilotEquipment: this.store.findAll('pilot-pilot-equipment'),
            pilotDevices: this.store.findAll('pilot-device'),
            pilot: this.store.findRecord('pilot', 'stubId'),
            mission: this.store.find('mission', params.mission_id).then(response => response, error => {
              console.log('error loading AvailableMissionRoute: ', error);
              return this.replaceWith('fourOhFour', {});
            })
          });
        });
      }
      console.log('no auth tokens');
      return this.replaceWith('fourOhFour', {});
    },
    setupController(controller, model) {
      this._super(controller, model);
      return this.controllerFor('application').set('hideNav', true);
    },
    actions: {
      acceptMission(mission) {
        let data;
        if (!mission.get('admin_scheduled')) {
          data = {
            scheduled_at_start: mission.get('scheduled_at_start'),
            scheduled_at_end: mission.get('scheduled_at_end')
          };
        }
        return _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/pilots/missions/${mission.id}/invitation`,
          type: 'PATCH',
          dataType: 'json',
          headers: this.get('sessionAccount.headers'),
          data
        }).then(() => {
          if (this.controllerFor('application').get('source') === 'android') {
            return android.acceptMission(); // jshint ignore:line
          } else if (this.controllerFor('application').get('source') === 'ios') {
            return window.webkit.messageHandlers.navigation.postMessage("MissionAccepted");
          } else {
            return console.log('how the hell did you get here');
          }
        }, response => console.log('fail: ', response));
      },
      declineMission(mission) {
        // find the record (trigger adapter call if not yet loaded in store), but prevent a background reload
        // return if mission.get('stateManager.currentPath') == 'deleted.inFlight'
        mission.deleteRecord();
        return mission.save().then(() => {
          if (this.controllerFor('application').get('source') === 'android') {
            return android.declineMission(); // jshint ignore:line
          } else if (this.controllerFor('application').get('source') === 'ios') {
            return window.webkit.messageHandlers.navigation.postMessage("MissionDeclined");
          } else {
            return console.log('how the hell did you get here');
          }
        });
      },
      openMaps() {
        if (this.controllerFor('application').get('source') === 'android') {
          return android.openMaps(); // jshint ignore:line
        } else if (this.controllerFor('application').get('source') === 'ios') {
          return window.webkit.messageHandlers.navigation.postMessage("openMaps");
        }
      }
    }
  });
  _exports.default = _default;
});
define("pilots/components/expandable-unit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ExpandableUnitComponent;
  ExpandableUnitComponent = Ember.Component.extend({
    assetMap: Ember.inject.service(),
    onRender: Ember.on('didInsertElement', function () {
      return this.set('offsetTop', $("#" + this.get('id')).offset().top);
    }),
    justExpanded: Ember.observer('expanded', function () {
      if (this.get('expanded')) {
        $('html, body').animate({
          scrollTop: this.get('offsetTop')
        }, 500);
        return setTimeout(function () {
          var i, len, link, linkList, results;
          linkList = document.querySelectorAll('.expandable-unit a');
          results = [];
          for (i = 0, len = linkList.length; i < len; i++) {
            link = linkList[i];
            results.push(link.setAttribute('target', '_blank'));
          }
          return results;
        }, 500);
      }
    }),
    actions: {
      toggleExpanded: function () {
        this.set('expanded', !this.get('expanded'));
        if (this.get('expanded') && this.get('closeOtherSections')) {
          return this.closeOtherSections(this.get('id'));
        }
      }
    }
  });
  var _default = ExpandableUnitComponent;
  _exports.default = _default;
});
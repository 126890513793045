define("pilots/routes/index", ["exports", "pilots/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IndexRoute;
  IndexRoute = Ember.Route.extend({
    redirect: function () {
      return this.transitionTo('dashboard');
    }
  });
  var _default = IndexRoute;
  _exports.default = _default;
});
define("pilots/templates/components/dashboard-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "b56MXD8c",
    "block": "[[[10,0],[14,0,\"dashboard-header\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"header-item\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"header-icon icon-Flag\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"header-container\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"count\"],[12],[1,[33,0,[\"total_client_missions\"]]],[13],[1,\"\\n        \"],[10,1],[14,0,\"small-text\"],[12],[1,\"Client missions done\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"divider\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"header-item\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"header-icon-2 icon-Coins\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"header-container\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"count\"],[12],[1,[28,[35,1],[[33,0,[\"payout_total\"]]],null]],[13],[1,\"\\n        \"],[10,1],[14,0,\"small-text\"],[12],[1,\"Total earned\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"pilot\",\"number-in-dollars\"]]",
    "moduleName": "pilots/templates/components/dashboard-header.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/adapters/pilot-drone-camera", ["exports", "pilots/adapters/application", "pilots/config/environment", "ember-data-url-templates"], function (_exports, _application, _environment, _emberDataUrlTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotDroneCameraAdapter;
  PilotDroneCameraAdapter = _application.default.extend(_emberDataUrlTemplates.default, {
    namespace: _environment.default.api.host + "/" + _environment.default.api.namespace + "/pilots/pilot_drones",
    urlTemplate: '{+namespace}/{pilotDroneId}/cameras',
    urlSegments: {
      pilotDroneId: function (type, id, snapshot, query) {
        return snapshot.belongsTo('pilotDrone', {
          id: true
        });
      },
      droneCameraId: function (type, id, snapshot, query) {
        return snapshot.belongsTo('camera', {
          id: true
        });
      }
    }
  });
  var _default = PilotDroneCameraAdapter;
  _exports.default = _default;
});
define("pilots/components/pilot-profile-onboarding-badges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PilotProfileOnboardingBadges = Ember.Component.extend({
    classNames: ['profile-component'],
    store: Ember.inject.service(),
    collapsed: true,
    hasBadges: Ember.computed('model.pilot_badges.[]', function () {
      return this.get('model.pilot_badges.length');
    }),
    sortAlphabetical: function (a, b) {
      return a.get('name') < b.get('name');
    },
    completedBadges: Ember.computed('model.pilot_badges.[]', function () {
      if (this.get('model.pilot_badges')) {
        return this.get('model.pilot_badges').filter(function (badge) {
          return badge.get('status') === 'complete';
        });
      }
    }),
    pendingBadges: Ember.computed('model.pilot_badges.[]', function () {
      if (this.get('model.pilot_badges')) {
        return this.get('model.pilot_badges').filter(function (badge) {
          return badge.get('status') === 'pending';
        });
      }
    }),
    sortedBadges: Ember.computed('completedBadges.[]', 'pendingBadges.[]', function () {
      let sorted = [];
      if (this.completedBadges) {
        sorted = sorted.concat(this.completedBadges.sort(this.sortAlphabetical));
      }
      if (this.pendingBadges) {
        sorted = sorted.concat(this.pendingBadges).sort(this.sortAlphabetical);
      }
      return sorted;
    }),
    desktopMinimized: Ember.computed('sortedBadges.[]', function () {
      return this.sortedBadges.slice(0, 6);
    }),
    mobileMinimized: Ember.computed('sortedBadges.[]', function () {
      return this.sortedBadges.slice(0, 3);
    }),
    actions: {
      toggleCollapsedState: function () {
        this.set('collapsed', !this.collapsed);
      }
    }
  });
  var _default = PilotProfileOnboardingBadges;
  _exports.default = _default;
});
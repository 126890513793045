define("pilots/mirage/serializers/application", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jshint esversion: 6 */
  var _default = _emberCliMirage.JSONAPISerializer.extend({
    keyForAttribute(attribute) {
      return attribute.replace('-', '_');
    }
  });
  _exports.default = _default;
});
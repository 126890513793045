define("pilots/routes/airmapcallback", ["exports", "jquery", "pilots/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin", "pilots/mixins/s3-asset-uploads"], function (_exports, _jquery, _environment, _authenticatedRouteMixin, _s3AssetUploads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  ;
  ;
  var AirmapCallbackRoute;
  AirmapCallbackRoute = Ember.Route.extend(_authenticatedRouteMixin.default, _s3AssetUploads.default, {
    tagName: '',
    sessionAccount: Ember.inject.service(),
    model: function (params) {
      var auths, data, headers, missionId;
      missionId = params.mission_id;
      auths = JSON.parse(decodeURIComponent(params.authorizations))[0];
      data = {
        id: decodeURIComponent(params.flight_id),
        authorizations: [{
          message: auths.message,
          authority_name: auths.authority.name,
          authority_id: auths.authority.id,
          status: auths.status
        }]
      };
      headers = this.get('sessionAccount.headers');
      headers['Content-Type'] = 'application/json';
      return _jquery.default.ajax({
        url: _environment.default.api.host + "/v1/pilots/missions/" + params.mission_id + "/laanc_flights",
        type: 'POST',
        dataType: 'json',
        headers: headers,
        data: JSON.stringify(data)
      }).then(function (_this) {
        return function (response) {
          _this.controllerFor('application').set('isShowingLaancAuthAcquiredModal', true);
          return _this.replaceWith('clientmission', missionId);
        };
      }(this));
    }
  });
  var _default = AirmapCallbackRoute;
  _exports.default = _default;
});
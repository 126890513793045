define("pilots/components/client-mission-intro", ["exports", "pilots/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    appStoreAppLink: _environment.default.APP_STORE_APP_LINK,
    googlePlayAppLink: _environment.default.GOOGLE_PLAY_APP_LINK,
    blogUrl: _environment.default.BLOG_URL,
    faqUrl: _environment.default.PILOT_WEB_FAQ_URL
  });
  _exports.default = _default;
});
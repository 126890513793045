define("pilots/components/top-navbar", ["exports", "pilots/utils/init-qtip", "jquery", "pilots/config/environment"], function (_exports, _initQtip, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: '',
    auth0: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    notifications: Ember.inject.service(),
    sideMenu: Ember.inject.service(),
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    showPilotMenu: Ember.computed('pilotMenuVisible', function () {
      return this.get('pilotMenuVisible');
    }),
    didInsertElement() {
      return (0, _initQtip.default)('.nav-tooltip');
    },
    willDestroyElement() {
      return document.removeEventListener('click', this.clickDocument.bind(event));
    },
    useDarkHeader: Ember.computed('router.currentPath', function () {
      if (this.get('adminMode')) return true;
      const currentRoute = this.get('router.currentPath');
      const lightBackground = ['dashboard', 'pilotlog', 'profile', 'fourOhFour', 'availablemission', 'clientmission', 'clientmissionupload', 'clientmissiongpscheck', 'terms_and_conditions', 'unavailable-mission', 'work-orders.show'];
      if (currentRoute !== this.get('currentPath')) {
        if ((0, _jquery.default)('.top-navbar-desktop:visible').length !== 0) {
          this.set('notificationSidebar', false);
          this.set('pilotMenuVisible', false);
        }
        this.get('sideMenu').close();
        this.set('currentPath', currentRoute);
      }
      return Array.from(lightBackground).includes(currentRoute);
    }),
    initNavBar: Ember.on('init', function () {
      this.set('currentPath', this.get('router.currentPath'));
      return document.addEventListener('click', this.clickDocument.bind(event, null));
    }),
    sideMenuChanged: Ember.observer('sideMenu.isOpen', 'currentPath', function () {
      if (this.get('sideMenu.isOpen')) {
        let needle, needle1;
        this.set('pilotMenuVisible', (needle = this.get('currentPath'), ['profile', 'pilotlog'].includes(needle)));
        return this.set('notificationsActive', (needle1 = this.get('currentPath'), ['availablemission', 'clientmission'].includes(needle1)));
      }
    }),
    clickDocument: (_this, event) => {
      if (_this) {
        const targetClass = event.target.className.trim();
        const doNotClear = ['notifications-nav-button nav-tooltip notification-active', 'notifications-nav-container nav-tooltip', 'notifications-nav-button', 'username', 'profile-icon', 'profile-icon nav-tooltip profile-icon-active', 'notifications-nav-counter', 'pilot-information', 'username username-active', 'profile-icon profile-icon-active', 'sidebar-links', 'toggle', 'x-icon', 'nav-tooltip profile-header', 'notifications-nav-button notification-active', 'sidebar-link profile-header'];
        const useSlideOut = (0, _jquery.default)('.top-navbar-desktop:visible').length === 0;
        if (!Array.from(doNotClear).includes(targetClass) && !useSlideOut) {
          _this.set('notificationsActive', false);
          _this.set('pilotMenuVisible', false);
        }
        if (!Array.from(doNotClear).includes(targetClass) && useSlideOut) {
          return _this.get('sideMenu').close();
        }
      }
    },
    actions: {
      toggleNotificationsSidebar() {
        this.set('notificationsActive', !this.get('notificationsActive'));
        return this.set('pilotMenuVisible', false);
      },
      togglePilotMenu() {
        this.set('pilotMenuVisible', !this.get('pilotMenuVisible'));
        this.set('notificationSidebar', false);
        return this.set('notificationsActive', false);
      },
      toggleNotifications() {
        this.set('pilotMenuVisible', false);
        this.toggleProperty('notificationsActive');
        return this.get('notifications').findAllNotifications();
      },
      invalidateSession() {
        this.auth0.logout();
      },
      markAllAsRead() {
        return this.get('notifications').markAllAsRead();
      }
    }
  });
  _exports.default = _default;
});
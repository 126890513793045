define("pilots/templates/components/validated-checkbox-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aWyaUF+K",
    "block": "[[[10,0],[14,0,\"terms-checkbox-container\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[28,[37,1],[[33,2],[33,3]],null]],[[\"update\",\"onchange\",\"required\",\"data-validate\"],[[28,[37,4],[[30,0],[28,[37,5],[[28,[37,1],[[33,2],[33,3]],null]],null]],null],[28,[37,4],[[30,0],\"validateProperty\",[33,2],[33,3]],null],true,true]]]],[1,\"\\n\\n  \"],[10,0],[12],[1,\"\\n    I am 18 years of age or older and I agree to the Zeitview\\n    \"],[8,[39,6],[[24,6,\"/terms\"],[24,\"target\",\"_new\"]],[[\"@type\",\"@size\",\"@tagName\"],[\"link\",\"medium\",\"a\"]],[[\"default\"],[[[[1,\"\\n      Terms & Conditions\\n    \"]],[]]]]],[1,\"\\n    and\\n    \"],[8,[39,6],[[24,6,\"https://www.zeitview.com/privacy-policy\"],[24,\"target\",\"_blank\"]],[[\"@type\",\"@size\",\"@tagName\"],[\"text\",\"medium\",\"a\"]],[[\"default\"],[[[[1,\"\\n      Privacy Policy\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[28,[37,1],[[33,2,[\"error\"]],[33,3]],null],[[[42,[28,[37,9],[[28,[37,9],[[28,[37,1],[[28,[37,1],[[33,2,[\"error\"]],[33,3]],null],\"validation\"],null]],null]],null],null,[[[1,\"    \"],[10,1],[14,0,\"error term\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null]],[]],null]],[\"error\"],false,[\"one-way-checkbox\",\"get\",\"changeset\",\"propertyName\",\"action\",\"mut\",\"button\",\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "pilots/templates/components/validated-checkbox-terms.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
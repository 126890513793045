define("pilots/components/pilot-profile", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  var PilotProfileComponent;
  PilotProfileComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    returnedOptIns: false,
    onInit: Ember.on('init', function () {
      if (this.get('model.pilotLicenses.length') === 0 || this.get('model.pilotDrones.length') === 0 || !this.get('model.pilot.travel_distance') || this.get('model.pilotDevices.length') === 0) {
        this.set('showHeaderBadge', true);
      }
      if (this.legal_entity_slug) {
        return _jquery.default.ajax({
          url: _environment.default.api.host + "/v1/pilots/account/payment_accounts/" + this.stripe_account_code,
          type: 'PUT',
          dataType: 'json',
          headers: this.get('sessionAccount.headers'),
          data: {
            legal_entity_slug: this.legal_entity_slug
          }
        }).then(function (_this) {
          return function (response) {
            _this.legal_entity_slug = null;
            _this.stripe_account_code = null;
            return _this.get('model.pilot').reload();
          };
        }(this), function (_this) {
          return function (response) {
            _this.get('rollbar').error('Error after redirect from Stripe', response);
            _this.legal_entity_slug = null;
            return _this.stripe_account_code = null;
          };
        }(this));
      }
    }),
    didInsertElement: function () {
      var cookie;
      $('.overlay').height($(this.element).height() + 50);
      cookie = Cookies.get('viewedLightBoxes');
      if (!cookie && this.get('showHeaderBadge')) {
        $('.overlay').css('display', 'block');
        $($('.profile-tooltip')[0]).css('display', 'block');
      }
      if (window.innerWidth < 1000) {
        return this.initMobileLightBoxes();
      } else {
        return this.initDesktopLightBoxes();
      }
    },
    initDesktopLightBoxes: function () {
      var tooltip1, tooltip2, tooltip3, tooltip4, tooltip5, tooltip6, tooltip7, width;
      tooltip1 = $($('.profile-tooltip')[0]);
      tooltip2 = $($('.profile-tooltip')[1]);
      tooltip3 = $($('.profile-tooltip')[2]);
      tooltip4 = $($('.profile-tooltip')[3]);
      tooltip5 = $($('.profile-tooltip')[4]);
      tooltip6 = $($('.profile-tooltip')[5]);
      tooltip7 = $($('.profile-tooltip')[6]);
      width = tooltip1.innerWidth();
      tooltip1.css('top', $('.row').offset().top - 25);
      tooltip1.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip2.css('top', $('.licenses-row .section-header').offset().top - tooltip2.height() / 2.5);
      tooltip2.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip3.css('top', $('.drones-row .section-header').offset().top - tooltip3.height() / 2.5);
      tooltip3.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip4.css('top', $('.devices-row .section-header').offset().top - tooltip4.height() / 2.5);
      tooltip4.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip5.css('top', $('.equipment-row .section-header').offset().top - tooltip4.height() / 2.5);
      tooltip5.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip6.css('top', $('.availability').offset().top - tooltip4.height() / 2.5);
      tooltip6.css('left', $('.availability').children().last().offset().left + 150);
      tooltip7.css('top', $('.header-badge').offset().top - tooltip5.height() / 2);
      return tooltip7.css('left', $('.header-badge').offset().left + 30);
    },
    initMobileLightBoxes: function () {
      var tooltip1, tooltip2, tooltip3, tooltip4, tooltip5, tooltip6, tooltip7, width;
      tooltip1 = $($('.profile-tooltip')[0]);
      tooltip2 = $($('.profile-tooltip')[1]);
      tooltip3 = $($('.profile-tooltip')[2]);
      tooltip4 = $($('.profile-tooltip')[3]);
      tooltip5 = $($('.profile-tooltip')[4]);
      tooltip6 = $($('.profile-tooltip')[5]);
      tooltip7 = $($('.profile-tooltip')[6]);
      width = tooltip1.innerWidth();
      tooltip1.css('top', $('.row').offset().top - 25);
      tooltip1.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip2.css('top', $('.licenses-row .section-header').offset().top - tooltip2.height() * 1.5);
      tooltip2.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip3.css('top', $('.drones-row .section-header').offset().top - tooltip3.height() * 1.5);
      tooltip3.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip4.css('top', $('.devices-row .section-header').offset().top - tooltip3.height() * 1.5);
      tooltip4.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip5.css('top', $('.equipment-row .section-header').offset().top - tooltip3.height() * 1.5);
      tooltip5.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip6.css('top', $('.availability-row .section-header').offset().top - tooltip4.height() * 1.5);
      tooltip6.css('left', "calc(50% - " + width / 2 + "px)");
      tooltip7.css('top', $('.header-badge').offset().top - tooltip5.height() * 1.5);
      return tooltip7.css('left', "calc(50% - " + width / 2 + "px)");
    },
    missingInfoCount: Ember.computed('model', 'model.pilot', 'model.pilotLicenses.[]', 'model.pilotDrones.[]', 'model.pilot.travel_distance', function () {
      var count;
      count = 0;
      if (this.get('model.pilotLicenses.length') === 0) {
        count += 1;
      }
      if (this.get('model.pilotDrones.length') === 0) {
        count += 1;
      }
      if (!this.get('model.pilot.travel_distance')) {
        count += 1;
      }
      if (this.get('model.pilotDevices.length') === 0) {
        count += 1;
      }
      return count;
    }),
    hideToolTipsAndUnfocus: function () {
      $('.profile-tooltip').css('display', 'none');
      return $('.content').removeClass('focused');
    },
    finishToolTips: function () {
      $('.profile-tooltip').css('display', 'none');
      $('.content').removeClass('focused');
      $('.overlay').css('display', 'none');
      return Cookies.set('viewedLightBoxes', true);
    },
    goToLicenses: function () {
      $($('.profile-tooltip')[1]).css('display', 'block');
      $('.licenses-row .content').addClass('focused');
      return $('html,body').animate({
        scrollTop: $(".licenses-row").offset().top - $($('.profile-tooltip')[1]).innerHeight() - 50
      });
    },
    goToDrones: function () {
      $($('.profile-tooltip')[2]).css('display', 'block');
      $('.licenses-row .content').removeClass('focused');
      $('.drones-row .content').addClass('focused');
      return $('html,body').animate({
        scrollTop: $(".drones-row").offset().top - $($('.profile-tooltip')[2]).innerHeight() - 50
      });
    },
    goToDevices: function () {
      $($('.profile-tooltip')[3]).css('display', 'block');
      $('.devices-row .content').removeClass('focused');
      $('.devices-row .content').addClass('focused');
      return $('html,body').animate({
        scrollTop: $(".devices-row").offset().top - $($('.profile-tooltip')[3]).innerHeight() - 50
      });
    },
    goToEquipment: function () {
      $($('.profile-tooltip')[4]).css('display', 'block');
      $('.equipment-row .content').removeClass('focused');
      $('.equipment-row .content').addClass('focused');
      return $('html,body').animate({
        scrollTop: $(".equipment-row").offset().top - $($('.profile-tooltip')[4]).innerHeight() - 50
      });
    },
    goToAvailability: function () {
      $($('.profile-tooltip')[5]).css('display', 'block');
      $('.drones-row .content').removeClass('focused');
      $('.availability-row .content').addClass('focused');
      return $('html,body').animate({
        scrollTop: $(".availability-row").offset().top - $($('.profile-tooltip')[5]).innerHeight() - 50
      });
    },
    hasOptIns: Ember.computed('model.pilot.opt_ins', 'returnedOptIns', function () {
      return this.get('returnedOptIns') || this.get('model.pilot.opt_ins');
    }),
    actions: {
      optIn: function () {
        var _this, headers;
        headers = this.get('sessionAccount.headers');
        _this = this;
        return _jquery.default.ajax({
          url: _environment.default.api.host + "/v1/pilots/pilot_opt_in",
          type: 'POST',
          dataType: 'json',
          headers: headers,
          data: {
            id: 'generic_training'
          }
        }).then(function (response) {
          return _this.set('returnedOptIns', true);
        }, function (response) {});
      },
      completeProfilePrompt: function () {
        return $($('.profile-tooltip')[6]).css('display', 'block');
      },
      step1: function () {
        this.get('hideToolTipsAndUnfocus')();
        if (this.get('model.pilotLicenses.length') === 0) {
          return this.get('goToLicenses')();
        } else if (this.get('model.pilotDrones.length') === 0) {
          return this.get('goToDrones')();
        } else if (this.get('model.pilotDevices.length') === 0) {
          return this.get('goToDevices')();
        } else if (this.get('model.pilotEquipment.length') === 0) {
          return this.get('goToEquipment')();
        } else if (!this.get('model.pilot.travel_distance')) {
          return this.get('goToAvailability')();
        } else {
          return this.get('finishToolTips')();
        }
      },
      step2: function () {
        this.get('hideToolTipsAndUnfocus')();
        if (this.get('model.pilotDrones.length') === 0) {
          return this.get('goToDrones')();
        } else if (this.get('model.pilotDevices.length') === 0) {
          return this.get('goToDevices')();
        } else if (this.get('model.pilotEquipment.length') === 0) {
          return this.get('goToEquipment')();
        } else if (!this.get('model.pilot.travel_distance')) {
          return this.get('goToAvailability')();
        } else {
          return this.get('finishToolTips')();
        }
      },
      step3: function () {
        this.get('hideToolTipsAndUnfocus')();
        if (this.get('model.pilotDevices.length') === 0) {
          return this.get('goToDevices')();
        } else if (this.get('model.pilotEquipment.length') === 0) {
          return this.get('goToEquipment')();
        } else if (!this.get('model.pilot.travel_distance')) {
          return this.get('goToAvailability')();
        } else {
          return this.get('finishToolTips')();
        }
      },
      step4: function () {
        this.get('hideToolTipsAndUnfocus')();
        if (this.get('model.pilotEquipment.length') === 0) {
          return this.get('goToEquipment')();
        } else if (!this.get('model.pilot.travel_distance')) {
          return this.get('goToAvailability')();
        } else {
          return this.get('finishToolTips')();
        }
      },
      step5: function () {
        this.get('hideToolTipsAndUnfocus')();
        if (!this.get('model.pilot.travel_distance')) {
          return this.get('goToAvailability')();
        } else {
          return this.get('finishToolTips')();
        }
      },
      step6: function () {
        return this.get('finishToolTips')();
      },
      takeMeThere: function () {
        var pilot;
        $($('.profile-tooltip')[6]).css('display', 'none');
        pilot = this.get('model.pilot');
        if (pilot.get('pilot_licenses').length === 0) {
          return $('html,body').animate({
            scrollTop: $(".licenses-row").offset().top
          });
        } else if (pilot.get('drones').length === 0) {
          return $('html,body').animate({
            scrollTop: $(".drones-row").offset().top
          });
        } else if (this.get('model.pilotDevices.length') === 0) {
          return $('html,body').animate({
            scrollTop: $(".devices-row").offset().top
          });
        } else if (this.get('model.pilotEquipment.length') === 0) {
          return $('html,body').animate({
            scrollTop: $(".equipment-row").offset().top
          });
        } else {
          return $('html,body').animate({
            scrollTop: $(".availability-row").offset().top
          });
        }
      },
      done: function () {
        return this.get('finishToolTips')();
      }
    }
  });
  var _default = PilotProfileComponent;
  _exports.default = _default;
});
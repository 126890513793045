define("pilots/components/mission-scheduled-at-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionScheduledAtTimeComponent;
  MissionScheduledAtTimeComponent = Ember.Component.extend({
    sameStartEndTime: Ember.computed('model.scheduled_at_start', 'model.scheduled_at_end', function () {
      return this.get('model.scheduled_at_start') === this.get('model.scheduled_at_end');
    })
  });
  var _default = MissionScheduledAtTimeComponent;
  _exports.default = _default;
});
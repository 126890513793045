define("pilots/components/schedule-mission", ["exports", "pilots/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  var ScheduleMissionComponent;
  ScheduleMissionComponent = Ember.Component.extend({
    classNames: ['schedule-mission'],
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    hide: Ember.computed('hideScheduling', function () {
      if (this.get('hideScheduling')) {
        return 'hide';
      }
    }),
    schedulingRequired: Ember.computed('model.admin_scheduled', 'model.scheduled_at_start', function () {
      return !this.get('model.admin_scheduled') && !this.get('model.scheduled_at_start');
    }),
    updateScheduledAt: Ember.observer('selectedDate', 'selectedTimeSlot', function () {
      if (this.get('selectedTimeSlot')) {
        this.set('model.scheduled_at_start', this.get('selectedTimeSlot.scheduled_at_start'));
        return this.set('model.scheduled_at_end', this.get('selectedTimeSlot.scheduled_at_end'));
      }
    }),
    dayTimeslots: Ember.computed('timeslots', 'selectedDate', function () {
      var i, len, ref, timeslot;
      if (this.get('timeslots')) {
        ref = this.get('timeslots');
        for (i = 0, len = ref.length; i < len; i++) {
          timeslot = ref[i];
          if (timeslot.date === this.get('selectedDate')) {
            return timeslot;
          }
        }
      }
    }),
    actions: {
      accept: function (mission) {
        return this.sendAction('acceptAction', mission);
      },
      decline: function (mission) {
        return this.sendAction('declineAction', mission);
      },
      reSchedule: function (mission) {
        this.get('metrics').trackEvent({
          category: 'Accepted Mission',
          action: 'Reschedule',
          label: 'Reschedule Accepted Mission'
        });
        return this.sendAction('updateAction', mission);
      }
    },
    didInsertElement: function () {
      if (this.get('model.admin_scheduled')) {
        return;
      }
      return this.loadTimeslots();
    },
    loadTimeslots: function () {
      var headers;
      headers = this.get('sessionAccount.headers');
      this.set('timeslotsLoading', true);
      return jQuery.ajax({
        url: _environment.default.api.host + "/v1/pilots/missions/" + this.get('model.id') + "/timeslots",
        type: 'GET',
        dataType: 'json',
        headers: headers
      }).then(function (_this) {
        return function (response) {
          _this.set('timeslots', response);
          _this.set('timeslotsLoading', false);
          return _this.renderDatePicker(response);
        };
      }(this), function (response) {
        this.set('timeslotsLoading', false);
        return console.log('failed to retrieve timeslots');
      });
    },
    renderDatePicker: function (timeslots) {
      var datepicker, disabledDays, i, len, line, maxDate, minDate, missionScheduledAt, ref, selectedDate, timeslot, timeslotsDates;
      missionScheduledAt = moment(this.get('model.scheduled_at_start')).format('YYYY-MM-DD');
      timeslotsDates = timeslots.map(function (_this) {
        return function (timeslot) {
          return timeslot.date;
        };
      }(this));
      selectedDate = timeslotsDates.find(function (_this) {
        return function (date) {
          return moment(date).diff(missionScheduledAt, 'days') >= 0;
        };
      }(this));
      if (selectedDate) {
        this.set('selectedDate', selectedDate);
        ref = this.get('dayTimeslots').available_timeslots;
        for (i = 0, len = ref.length; i < len; i++) {
          timeslot = ref[i];
          if (timeslot.scheduled_at_start === this.get('model.scheduled_at_start')) {
            this.set('selectedTimeSlot', timeslot);
            break;
          }
        }
      } else {
        this.set('selectedDate', timeslotsDates[0]);
      }
      minDate = timeslotsDates[0];
      maxDate = timeslotsDates.at(-1);
      disabledDays = [];
      if (this.get('model.weekend_schedule_filter') === 'no_weekends') {
        disabledDays = [0, 6];
      } else if (this.get('model.weekend_schedule_filter') === 'weekends_only') {
        disabledDays = [1, 2, 3, 4, 5];
      }
      $('#scheduleTimeDatePicker').datetimepicker({
        collapse: false,
        viewMode: 'days',
        format: 'YYYY-MM-DD',
        inline: true,
        minDate: minDate,
        maxDate: maxDate,
        daysOfWeekDisabled: disabledDays,
        date: this.get('selectedDate'),
        icons: {
          date: "fa fa-calendar",
          previous: "icon-DB_icon_ArrowStroke_left",
          next: "icon-DB_icon_ArrowStroke_right",
          up: "icon-Arrow2_down",
          down: "icon-Arrow2_up",
          close: "icon-Xmark"
        }
      });
      datepicker = document.getElementsByClassName('datepicker')[0];
      line = document.createElement('div');
      line.className = "separator-line";
      datepicker.appendChild(line);
      return $('#scheduleTimeDatePicker').on('dp.change', function (_this) {
        return function (e) {
          return _this.set('selectedDate', e.date.format('YYYY-MM-DD'));
        };
      }(this));
    }
  });
  var _default = ScheduleMissionComponent;
  _exports.default = _default;
});
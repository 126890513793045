define("pilots/components/pilot-profile-devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileDevicesComponent;
  PilotProfileDevicesComponent = Ember.Component.extend({
    classNames: ['profile-component'],
    store: Ember.inject.service(),
    devicesAvailable: Ember.computed('model.pilotDevices.[]', function () {
      return this.get('model.pilotDevices.length') < this.get('model.devices.length');
    }),
    missingInfo: Ember.computed('model.pilotDevices.[]', function () {
      return this.get('model.pilotDevices.length') === 0;
    }),
    actions: {
      createDevice: function (device) {
        device = this.get('store').createRecord('pilot-device', {
          device: device,
          pilot: this.get('model.pilot')
        });
        return device.save();
      },
      destroyDevice: function (device) {
        return device.destroyRecord().then(function (_this) {
          return function () {
            return _this.get('model.pilot').reload();
          };
        }(this));
      }
    }
  });
  var _default = PilotProfileDevicesComponent;
  _exports.default = _default;
});
define("pilots/controllers/clientmissiongpscheck", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    images: Ember.A([]),
    processingCompleted: false,
    actions: {
      logCheckPassed: function () {
        const headers = this.get('sessionAccount.headers');
        _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/pilots/missions/${this.get('model.mission.id')}/activity_logs`,
          type: 'POST',
          dataType: 'json',
          headers: headers,
          data: {
            event_type: 'gps_check_passed',
            event_datetime: moment().format()
          }
        }).then(() => {
          this.set('model.mission.passed_gps_check', true);
        }).catch(function () {
          console.log('failed to create passed gps check event');
        });
      },
      startOver: function () {
        this.set('images', Ember.A([]));
        this.set('processingCompleted', false);
        this.send('refreshRoute');
      }
    }
  });
  _exports.default = _default;
});
define("pilots/models/mission-reschedule", ["exports", "ember-data", "pilots/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionReschedule;
  MissionReschedule = _emberData.default.Model.extend({
    mission: _emberData.default.belongsTo('mission'),
    reschedule_reason: _emberData.default.belongsTo('reschedule-reason'),
    notes: _emberData.default.attr('string'),
    scheduled_at_start: _emberData.default.attr('string'),
    scheduled_at_end: _emberData.default.attr('string'),
    rescheduler: _emberData.default.belongsTo('rescheduler', {
      polymorphic: true,
      inverse: 'mission_reschedules'
    })
  });
  var _default = MissionReschedule;
  _exports.default = _default;
});
define("pilots/models/mission", ["exports", "ember-data", "pilots/data/map_features", "pilots/models/payoutable"], function (_exports, _emberData, _map_features, _payoutable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global moment */
  var _default = _payoutable.default.extend({
    created_on: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    instructions: _emberData.default.attr('string'),
    scheduled_at_start: _emberData.default.attr('string'),
    scheduled_at_end: _emberData.default.attr('string'),
    duration_in_seconds: _emberData.default.attr('number'),
    admin_scheduled: _emberData.default.attr('boolean'),
    shot_list: _emberData.default.attr(''),
    pilot_comment: _emberData.default.attr('string'),
    estimated_pilot_payout: _emberData.default.attr('number'),
    assigned: _emberData.default.attr('boolean'),
    completed: _emberData.default.attr('boolean'),
    status_timestamps: _emberData.default.attr(''),
    location: _emberData.default.belongsTo('location', {
      async: false
    }),
    point_of_interest: _emberData.default.belongsTo('point_of_interest', {
      async: false
    }),
    images: _emberData.default.hasMany('image', {
      async: true
    }),
    videos: _emberData.default.hasMany('videos', {
      async: true
    }),
    panoramas: _emberData.default.hasMany('panoramas', {
      async: true
    }),
    shots: _emberData.default.hasMany('shot', {
      async: true
    }),
    mission_type: _emberData.default.attr('string'),
    rejectionReason: _emberData.default.attr('string'),
    category: _emberData.default.belongsTo('category', {
      async: false
    }),
    laanc_flights: _emberData.default.hasMany('laanc_flight', {
      async: false
    }),
    laanc_exemptions: _emberData.default.hasMany('laanc_exemption', {
      async: false
    }),
    onsite_contact: _emberData.default.belongsTo('onsite_contact', {
      async: false
    }),
    currency: _emberData.default.belongsTo('currency', {
      async: true
    }),
    mission_reschedules: _emberData.default.hasMany('mission-reschedule', {
      async: false
    }),
    require_contact_to_reschedule: _emberData.default.attr('boolean'),
    is_on_hold: _emberData.default.attr('boolean'),
    flight_app: _emberData.default.belongsTo('flight_app', {
      async: false
    }),
    pilot_script: _emberData.default.attr('string'),
    package: _emberData.default.belongsTo('mission_package', {
      async: false
    }),
    reference_id: _emberData.default.attr('string'),
    cloud_reqs: _emberData.default.attr('string'),
    require_raw_images: _emberData.default.attr('boolean'),
    partial_reshoots: _emberData.default.hasMany('partial_reshoots', {
      async: false
    }),
    work_order: _emberData.default.belongsTo('work_order', {
      async: false
    }),
    map_features: _emberData.default.hasMany('map_feature'),
    estimated_payouts: _emberData.default.hasMany('estimated-payout'),
    cloud_reqs_verbose: Ember.computed('cloud_reqs', function () {
      const valueWithDashes = this.get('cloud_reqs') || 'less_than_100';
      return valueWithDashes.replace(/_/g, ' ').replace('less than', 'Up to') + '% cloudy';
    }),
    duration: Ember.computed('duration_in_seconds', function () {
      return moment.duration(this.get('duration_in_seconds') * 1000).humanize();
    }),
    fly_by_date: _emberData.default.attr('string'),
    sla_expired: _emberData.default.attr('boolean'),
    weekend_schedule_filter: _emberData.default.attr('string'),
    timeframe_start: _emberData.default.attr('string'),
    timeframe_end: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    passed_gps_check: _emberData.default.attr('boolean'),
    airbus_briefing: _emberData.default.belongsTo('airbus-briefing', {
      async: false
    }),
    airbus_authorization: _emberData.default.attr('string'),
    pilotEstimatedPayoutWithCurrency: Ember.computed('estimated_payouts.[]', 'estimated_pilot_payout', function () {
      const newCurencyModelPilotPayout = this.get('estimated_payouts')?.findBy('payout_type', 'pilot');
      return newCurencyModelPilotPayout || Ember.Object.create({
        amount: this.get('estimated_pilot_payout'),
        currency: Ember.Object.create({
          code: 'USD',
          symbol: '$'
        })
      });
    }),
    flightDateWindow: Ember.computed('fly_by_date', function () {
      return `Fly by ${moment(this.get('fly_by_date')).format('MM/DD/YYYY')}`;
    }),
    // Shots which the pilot should assign assets to
    pilotShots: Ember.computed('shots', function () {
      if (this.get('hasPartialReshoots')) {
        return this.get('partial_reshoots').filterBy('resolved', false).toArray().flatMap(reshoot => {
          return reshoot.get('shots').toArray();
        });
      } else {
        return this.get('shots').filter(shot => shot.get('name') !== 'Final Assets');
      }
    }),
    selected_on_dashboard: _emberData.default.attr('boolean'),
    map_marker: _emberData.default.attr(''),
    unassignedVideos: Ember.computed('videos.[]', 'videos.@each.shot', function () {
      return this.get('videos').reduce(function (acc, video) {
        if (video.get('shot.id')) {
          return acc;
        } else {
          acc.push(video);
          return acc;
        }
      }, []);
    }),
    showOnHold: Ember.computed('is_on_hold', function () {
      const status = this.get('status');
      return this.get('is_on_hold') && (status === 'pilots_notified' || status === 'pilot_accepted' || status === 'assets_uploaded' || status === 'processing_shots' || status === 'assets_classified');
    }),
    mainImage: Ember.computed('images.[]', function () {
      if (this.get('images').get('length')) {
        return this.get('images').get('firstObject.url');
      }
    }),
    allAssets: Ember.computed.union('images', 'videos'),
    assetsCount: Ember.computed('allAssets.length', function () {
      return this.get('allAssets.length');
    }),
    pilotAssigned: Ember.computed('status', function () {
      return this.get('status') === 'pilot_assigned';
    }),
    missionComplete: Ember.computed('status', 'flight_app', function () {
      const status = this.get('status');
      if (this.get('flight_app') && this.get('flight_app.value') && this.get('flight_app.value.upload_started_at')) {
        return true;
      }
      return status !== 'pilot_assigned' && status !== 'flight_complete' && status !== 'assets_uploaded' && status !== 'pilot_accepted';
    }),
    hasAssets: Ember.computed('images', 'videos', function () {
      return this.get('images.length') > 0 || this.get('videos.length') > 0;
    }),
    pendingPayout: Ember.computed('payouts', function () {
      return this.get('payouts').filter(_ref => {
        let {
          payout_to
        } = _ref;
        return payout_to === 'pilot';
      }).find(function (payout) {
        return payout.status !== 'complete';
      });
    }),
    totalPayouts: Ember.computed('payouts', function () {
      const pilotPayouts = this.get('payouts').filter(_ref2 => {
        let {
          payout_to
        } = _ref2;
        return payout_to === 'pilot';
      });
      const currencies = [...new Map(pilotPayouts.map(_ref3 => {
        let {
          currency
        } = _ref3;
        return [currency.get('id'), currency];
      })).values()];
      const totalAmountByCurrencies = currencies.map(currency => {
        const totalAmountByCurrency = pilotPayouts.filter(payout => payout.get('currency.id') === currency.get('id')).mapBy('amount').reduce((sum, value) => sum + Number(value), 0);
        return Ember.Object.create({
          id: currency.get('id'),
          code: currency.get('code'),
          symbol: currency.get('symbol'),
          amount: totalAmountByCurrency
        });
      });
      return totalAmountByCurrencies;
    }),
    address: Ember.computed('location', function () {
      return `${this.get('location.address')}, ${this.get('location.city')}, ${this.get('location.state')}, ${this.get('location.country')}`;
    }),
    shortAddress: Ember.computed('location', function () {
      return `${this.get('location.city')}, ${this.get('location.state')}, ${this.get('location.country')}`;
    }),
    paymentDate: Ember.computed('payouts', function () {
      if (!this.get('payouts').get('length')) {
        return '';
      }
      return this.get('payouts')[0]['updated_at'];
    }),
    paymentProcessor: Ember.computed('payouts', function () {
      if (!this.get('payouts').get('length')) {
        return '';
      }
      return this.get('payouts').firstObject.get('payment_processor');
    }),
    formattedMissionType: Ember.computed('mission_type', function () {
      switch (this.get('mission_type')) {
        case 'creative':
          return 'Creative Mission';
        case 'panorama':
          return 'Panorama Mission';
        case 'client':
          return 'Client Mission';
        case 'training':
          return 'Training Mission';
        default:
          return 'Other';
      }
    }),
    uploadedDate: Ember.computed('status_timestamps', function () {
      let date = this.get('status_timestamps.assets_uploaded');
      if (!date) {
        return date = this.get('status_timestamps.processing_shots');
      }
    }),
    // Combines mission and status to determine which icon to display for this
    // mission, etc.
    missionStatusType: Ember.computed('status', 'completed', 'point_of_interest', function () {
      if (this.get('mission_type') === 'creative') {
        return 'creative_mission';
      }
      if (this.get('point_of_interest')) {
        if (this.get('completed')) {
          if (this.get('point_of_interest.property_type') === 'commercial') {
            return 'pano_commercial_completed';
          } else {
            return 'pano_residential_completed';
          }
        } else {
          if (this.get('point_of_interest.property_type') === 'commercial') {
            return 'pano_commercial_active';
          } else {
            return 'pano_residential_active';
          }
        }
      } else if (this.get('status') === 'pilots_notified') {
        return 'client_mission_available';
      } else {
        return 'client_mission';
      }
    }),
    icon: Ember.computed('missionStatusType', function () {
      switch (this.get('missionStatusType')) {
        case 'pano_commercial_completed':
          return 'icon-DroneBase_Icons_Commercial_complete_pin pano-icon-color';
        case 'pano_residential_completed':
          return 'icon-DroneBase_Icons_Residential_complete_pin pano-icon-color';
        case 'pano_commercial_active':
          return 'icon-DroneBase_Icons_Commercial_Star-Pin pano-icon-color';
        case 'pano_residential_active':
          return 'icon-DroneBase_Icons_Residential_Star_pin pano-icon-color';
        case 'client_mission_available':
          return 'icon-ClientPin client-mission-icon-color';
        case 'client_mission':
          return 'icon-ClientPin client-mission-icon-color';
        case 'creative_mission':
          return 'icon-Drone_b creative-mission-icon-color';
      }
    }),
    isCyberduck: Ember.computed('flight_app.name', function () {
      return this.get('flight_app') != null && this.get('flight_app.name') === 'Cyberduck';
    }),
    isAMS: Ember.computed('work_order', function () {
      return this.get('work_order') != null;
    }),
    amsInProgress: Ember.computed('status', function () {
      const status = this.get('status');
      return status === 'pilot_accepted' || status === 'flight_complete';
    }),
    featureShots: Ember.computed('shots', 'shots.[]', 'shots.@each.map_feature', function () {
      const mapFeatureGroupedItems = {};
      const noFeatureShots = [];
      const noFeatureMapFeature = {
        id: "NO_FEATURE",
        name: 'Additional Images'
      };
      this.get('shots').forEach(function (shot) {
        if (shot.origin_type === "catalog") {
          const map_feature = shot.get('map_feature');
          if (map_feature != null) {
            const key = map_feature.id;
            let groupedItems = mapFeatureGroupedItems[key];
            if (groupedItems) {
              return groupedItems.shots.pushObject(shot);
            } else {
              groupedItems = {
                map_feature,
                shots: [shot]
              };
              mapFeatureGroupedItems[key] = groupedItems;
            }
          } else {
            noFeatureShots.pushObject(shot);
          }
        } else {
          noFeatureShots.pushObject(shot);
        }
      });
      if (noFeatureShots.length) {
        mapFeatureGroupedItems["NO_FEATURE"] = {
          map_feature: noFeatureMapFeature,
          shots: noFeatureShots
        };
      }
      const mapFeatureValues = Object.values(mapFeatureGroupedItems);
      const featureColors = _map_features.default.featureColors;
      let colorIndex = 0;
      function getNextColor() {
        colorIndex = colorIndex + 1;
        return featureColors[colorIndex % 5];
      }

      // sort map features as on the map to get the right color in the shot list
      const mapFeatureIds = this.map_features.map(f => f.id);
      mapFeatureValues.sort((a, b) => {
        return mapFeatureIds.indexOf(a.map_feature.id) - mapFeatureIds.indexOf(b.map_feature.id);
      });
      mapFeatureValues.forEach(function (feature) {
        if (feature.map_feature.id === "NO_FEATURE") {
          feature.featureColor = "transparent";
        } else {
          feature.featureColor = getNextColor();
        }
      });
      return mapFeatureValues;
    }),
    hasFeatureShots: Ember.computed('shots', function () {
      return this.get('shots').filter(function (shot) {
        return shot.get('origin_type') === 'catalog';
      }).length;
    }),
    hasPartialReshoots: Ember.computed('partial_reshoots.[]', function () {
      return this.get('partial_reshoots').length > 0;
    }),
    hasOrthoShot: Ember.computed('shots.[].hasOrthoPostProcess', function () {
      return this.get('shots').getEach('hasOrthoPostProcess').indexOf(true) >= 0;
    }),
    isScheduled: Ember.computed('scheduled_at_start', 'scheduled_at_end', function () {
      return !!this.get('scheduled_at_start') && !!this.get('scheduled_at_end');
    }),
    acreage: Ember.computed('map_features.[]', function () {
      let area = 0;
      if (this.get('map_features.length') > 0) {
        this.get('map_features').forEach(function (feature) {
          if (feature.properties.type !== 'No Fly Zone' && !feature.no_fly_zone) {
            const feature_area = feature.properties.rgeo_area || feature.properties.area;
            if (feature_area) {
              return area += Number(feature_area);
            }
          }
        });
        const val = (area * 0.0002471).toFixed(2);
        let acres = `${val} Acre`;
        if (val !== 1) {
          acres += 's';
        }
        return acres;
      }
    })
  });
  _exports.default = _default;
});
define("pilots/components/mission-address", ["exports", "pilots/mixins/google-init-mixin"], function (_exports, _googleInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_googleInitMixin.default, {
    classNames: 'mission-address-container',
    milesCountries: ['United States', 'United States of America', 'US', 'USA', 'GB'],
    missionDistance: null,
    classNameBindings: ['isArgsEmpty:hide'],
    isArgsEmpty: Ember.computed('mission', 'pilot', function () {
      return !this.get('mission') || !this.get('pilot');
    }),
    afterGoogleLoaded: function () {
      if (this.isArgsEmpty) {
        return;
      }
      if (this.get('mission').get('laanc_flights') && this.get('mission').get('laanc_flights').get('firstObject')) {
        this.set('LAANCAuth', this.get('mission').get('laanc_flights').get('firstObject').get('authorizations')[0]);
      }
      if (this.get('mission').get('laanc_exemptions') && this.get('mission').get('laanc_exemptions').get('firstObject')) {
        this.set('LAANCExemption', this.get('mission').get('laanc_exemptions').get('firstObject'));
      }
      const latLngA = new google.maps.LatLng(this.get('pilot.latitude'), this.get('pilot.longitude'));
      const latLngB = new google.maps.LatLng(Number(this.get('mission.location.latitude')), Number(this.get('mission.location.longitude')));
      const distance = google.maps.geometry.spherical.computeDistanceBetween(latLngA, latLngB);
      const distanceMiles = (distance / 1609).toString();
      if (this.get('milesCountries').indexOf(this.get('pilot.country')) >= 0) {
        if (distanceMiles.indexOf('.') && distanceMiles.indexOf('.') + 1 < distanceMiles.length) {
          return this.set('missionDistance', distanceMiles.substring(0, distanceMiles.indexOf('.') + 2) + ' mi away');
        } else {
          return this.set('missionDistance', distanceMiles + ' mi away');
        }
      } else {
        const distanceKM = (distance / 1000).toString();
        if (distanceKM.indexOf('.') && distanceKM.indexOf('.') + 1 < distanceKM.length) {
          return this.set('missionDistance', distanceKM.substring(0, distanceKM.indexOf('.') + 2) + ' km away');
        } else {
          return this.set('missionDistance', distanceKM + ' km away');
        }
      }
    }
  });
  _exports.default = _default;
});
define("pilots/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "pilots/config/environment"], function (_exports, _unauthenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    model() {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('pilot_email')) {
        return Ember.RSVP.hash({
          adminMode: true,
          adminToken: urlParams.get('admin_token'),
          adminEmail: urlParams.get('admin_email'),
          pilotEmail: urlParams.get('pilot_email'),
          account: this.sessionAccount.account
        });
      } else {
        return {
          identification: null,
          password: null,
          adminMode: false
        };
      }
    },
    setupController(controller, model) {
      this._super(controller, model);
      if (model.adminMode) {
        this.session.authenticate('authenticator:adminPretender', model.adminEmail, model.adminToken, model.pilotEmail).then(() => {
          window.location = _environment.default['ember-simple-auth'].auth0.redirectUri;
        });
      } else {
        this.controllerFor('application').set('hideNav', true);
        this.logout();
        this.login();
      }
    },
    login() {
      // Check out the docs for all the options:
      // https://auth0.com/docs/libraries/auth0js/v9#webauth-authorize-
      const authOptions = {
        audience: _environment.default['ember-simple-auth'].auth0.audience,
        redirectUri: _environment.default['ember-simple-auth'].auth0.redirectUri,
        responseType: 'token id_token',
        scope: 'openid email',
        state: this._router.url
      };
      this.session.authenticate('authenticator:auth0-universal', authOptions, err => {
        if (err) {
          console.log("Auth0 err", err);
        }
      });
    },
    logout() {
      localStorage.clear();
      this.session.invalidate();
    },
    didInsertElement() {
      Ember.run.schedule('afterRender', this, function () {
        this.element.attr('novalidate', true);
      });
    },
    actions: {
      willTransition: function () {
        this.controllerFor('application').set('hideNav', false);
      }
    }
  });
  _exports.default = _default;
});
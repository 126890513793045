define("pilots/models/panorama", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Panorama;
  Panorama = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    processing: _emberData.default.attr('boolean'),
    processing_status: _emberData.default.attr('string'),
    source_type: _emberData.default.attr('string'),
    thumbnail_url: _emberData.default.attr('string'),
    viewer_url: _emberData.default.attr('string'),
    mission: _emberData.default.belongsTo('mission'),
    shot: _emberData.default.belongsTo('shot')
  });
  var _default = Panorama;
  _exports.default = _default;
});
define("pilots/templates/components/client-mission/wrong-files-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "297YfNtp",
    "block": "[[[10,0],[14,0,\"ember-modal-inner-wrap unclassified-modal\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-close-container\"],[12],[1,\"\\n    \"],[11,3],[4,[38,0],[[30,0],\"toggleModal\"],null],[12],[10,\"i\"],[14,0,\"icon-Xmark\"],[12],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"header\"],[12],[1,\"The following file(s) will be removed from your upload.  Only video/image files are accepted\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"body\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"      \"],[10,0],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,4],[[4,[38,0],[[30,0],\"toggleModal\"],null]],null,[[\"default\"],[[[[1,\"\\n    Proceed\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"file\"],false,[\"action\",\"each\",\"-track-array\",\"files\",\"button\"]]",
    "moduleName": "pilots/templates/components/client-mission/wrong-files-modal.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/templates/components/reschedule-success-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "94RZbO0y",
    "block": "[[[10,0],[14,0,\"ember-modal-inner-wrap reschedule-success-modal\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"modal-close-container\"],[12],[1,\"\\n    \"],[11,3],[4,[38,0],[[30,0],\"toggleModal\"],null],[12],[10,\"i\"],[14,0,\"icon-Xmark\"],[12],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"header\"],[12],[1,\"Flight Rescheduled!\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"reschedule-icon\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"body\"],[12],[1,\"\\n    Thanks for working with us! Your mission is scheduled for:\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[10,1],[14,0,\"date\"],[12],[1,[28,[35,1],null,[[\"model\"],[[33,2]]]]],[13],[1,\"\\n    \"],[10,1],[14,0,\"time\"],[12],[1,[28,[35,3],null,[[\"model\"],[[33,2]]]]],[13],[1,\"\\n    \"],[10,\"br\"],[12],[13],[1,\"\\n    Make sure you've studied the mission details before flying.\\n  \"],[13],[1,\"\\n  \"],[8,[39,4],[[4,[38,0],[[30,0],\"toggleModal\"],null]],null,[[\"default\"],[[[[1,\"\\n    Got it\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\",\"moment-format-local-date\",\"mission\",\"mission-scheduled-at-time\",\"button\"]]",
    "moduleName": "pilots/templates/components/reschedule-success-modal.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/routes/application", ["exports", "pilots/config/environment", "ember-simple-auth/mixins/application-route-mixin", "ember-simple-auth-auth0/mixins/application-route-mixin"], function (_exports, _environment, _applicationRouteMixin, _applicationRouteMixin2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_environment.default.environment === 'test' ? _applicationRouteMixin.default : _applicationRouteMixin2.default, {
    sessionAccount: Ember.inject.service(),
    auth0: Ember.inject.service(),
    model: function () {
      return Ember.RSVP.hash({
        account: this.sessionAccount.account
      });
    },
    _invalidateIfAuthenticated: function () {
      if (this.session.data.authenticated.authenticator !== "authenticator:adminPretender" && this.session.data.authenticated.authenticator !== "authenticator:devise") {
        // We use devise for webview
        this._super();
      }
    },
    sessionAuthenticated: function () {},
    actions: {
      loginSuccess: function () {
        return this.transitionTo('dashboard');
      },
      transitionFromQuickOrder: function (targetRoute, missionId) {
        return this.transitionTo(targetRoute, missionId);
      },
      error: function (error) {
        let _this = this;
        if (error.status === 401) {
          this.auth0.logout();
        }
        if (_environment.default.environment !== 'production') {
          console.error('redirecting', error);
        }
        Ember.run.later(function () {
          _this.transitionTo('fourOhFour', {});
        });
      }
    }
  });
  _exports.default = _default;
});
define("pilots/routes/unavailable-mission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    actions: {
      returnToDashboard() {
        return this.transitionTo('dashboard');
      }
    }
  });
  _exports.default = _default;
});
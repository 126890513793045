define("pilots/components/select-inplace-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SelectInplaceEditComponent;
  SelectInplaceEditComponent = Ember.Component.extend({
    content: null,
    prompt: null,
    optionValuePath: 'value',
    optionLabelPath: 'label',
    action: Ember.K,
    classNames: ['form-group'],
    selectClass: 'form-control input-lg',
    value: null,
    label: null,
    didInitAttrs: function (attrs) {
      this._super(attrs);
      if (!this.get('content')) {
        return this.set('content', []);
      }
    },
    click: function () {
      if (!this.get('isEditing')) {
        this.set('isEditing', true);
        return Ember.run.scheduleOnce('afterRender', this, this.focusSelect);
      }
    },
    focusSelect: function () {
      this.element.querySelectorAll('select').val(this.get('value'));
      return this.element.querySelectorAll('select').focus();
    },
    focusOut: function () {
      return this.set('isEditing', false);
    },
    actions: {
      save: function () {
        var _this;
        _this = this;
        this.set('value', this.element.querySelectorAll('select').val());
        return this.get('model').save().then(function () {
          return _this.set('isEditing', false);
        }, function (response) {
          return _this.element.querySelectorAll('select').addClass('error');
        });
      }
    }
  });
  var _default = SelectInplaceEditComponent;
  _exports.default = _default;
});
define("pilots/models/license", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var License;
  License = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    is_certificate_number_required: _emberData.default.attr('boolean'),
    country_code: _emberData.default.attr('string'),
    issuer_type: _emberData.default.attr('string'),
    issuing_body: _emberData.default.attr('string'),
    reference_date_mode: _emberData.default.attr('string')
  });
  var _default = License;
  _exports.default = _default;
});
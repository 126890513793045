define("pilots/templates/clientmissionintro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8578fHku",
    "block": "[[[1,[28,[35,0],null,[[\"model\"],[[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[],false,[\"client-mission-intro\"]]",
    "moduleName": "pilots/templates/clientmissionintro.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/pilot-profile-equipment-existing", ["exports", "pilots/config/environment", "ember-changeset"], function (_exports, _environment, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileEquipmentExistingComponent;
  PilotProfileEquipmentExistingComponent = Ember.Component.extend({
    pilotEquipmentNumber: Ember.computed('index', {
      get: function () {
        if (this.get('index') >= 0) {
          return this.get('index') + 1;
        }
      },
      set: function (key, value) {
        return this._pilotEquipmentNumber = value;
      }
    }),
    actions: {
      destroy: function (equipment) {
        this.set('pilotEquipmentNumber', this.get('pilotEquipmentNumber') - 1);
        return this.destroyEquipment(equipment);
      }
    }
  });
  var _default = PilotProfileEquipmentExistingComponent;
  _exports.default = _default;
});
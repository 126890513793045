define("pilots/components/pilot-work-location-existing", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    workLocationNumber: Ember.computed('index', function () {
      if (this.get('index') >= 0) {
        return this.get('index') + 1;
      }
    }),
    actions: {
      setUpStripe: function () {
        return _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/pilots/account/payment_accounts`,
          type: 'POST',
          dataType: 'json',
          data: {
            legal_entity_slug: this.stripeAccount.get('legal_entity.slug')
          },
          headers: this.get('sessionAccount.headers')
        }).then(response => {
          window.open(response.stripe_link, "_self");
        }, response => {
          this.get('rollbar').error('Failed to get Stripe account link', response);
        });
      }
    }
  });
  _exports.default = _default;
});
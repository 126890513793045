define("pilots/validations/custom_validators/boolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = booleanValidator;
  function booleanValidator(message) {
    return (key, newValue) => {
      if (newValue === true) {
        return true;
      }
      return message;
    };
  }
});
define("pilots/helpers/pluralize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.pluralize = pluralize;
  function pluralize(params, options) {
    let [count, word] = params;
    const {
      omitCount
    } = options;
    if (count !== 1) {
      count = count || 0;
      word = Ember.String.pluralize(word);
    }
    return (omitCount ? '' : count + ' ') + word;
  }
  var _default = Ember.Helper.helper(pluralize);
  _exports.default = _default;
});
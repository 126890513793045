define("pilots/models/pilot-badge", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    badge: _emberData.default.belongsTo('badge', {
      async: false
    }),
    status: _emberData.default.attr('string'),
    abbreviation: Ember.computed('badge', function () {
      if (this.badge && this.get('badge.name')) {
        var badgeAbbreviation = this.get('badge.name').replace(/[^A-Z]/g, '');
        if (badgeAbbreviation.length > 3) {
          badgeAbbreviation = badgeAbbreviation.substring(0, 3);
        }
        return badgeAbbreviation;
      }
    })
  });
  _exports.default = _default;
});
define("pilots/models/payout", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    pilot_id: _emberData.default.attr('string'),
    payoutable_id: _emberData.default.attr('string'),
    amount: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    payment_processor: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    payout_to: _emberData.default.attr('string'),
    payoutable_type: _emberData.default.attr('string'),
    payoutable: _emberData.default.belongsTo('payoutable', {
      async: false,
      polymorphic: true,
      inverse: 'payouts'
    }),
    currency: _emberData.default.belongsTo('currency', {
      async: true
    })
  });
  _exports.default = _default;
});
define("pilots/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "f3olrfqx",
    "block": "[[[41,[30,0,[\"hideNav\"]],[[[1,\"  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,3],null,[[\"adminMode\"],[[30,0,[\"adminMode\"]]]]]],[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"component\",\"-outlet\",\"top-navbar\"]]",
    "moduleName": "pilots/templates/application.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
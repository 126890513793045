define("pilots/components/notification-dropdown", ["exports", "pilots/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NotificationComponent;
  NotificationComponent = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['notification-list'],
    assetsDir: 'assets/images/notifications/',
    assetMap: Ember.inject.service(),
    read: Ember.computed('model.read_status', function () {
      if (this.get('model.read_status') === 'read') {
        return 'notification-list-read';
      }
    }),
    icon: Ember.computed('model.fontIcon', function () {
      var iconArray, newAssetUrl;
      if (!this.get('read')) {
        return this.get('assetMap').resolve(this.assetsDir + this.get('model.fontIcon'));
      }
      iconArray = this.get('model.fontIcon').split('.');
      iconArray[0] = iconArray[0] + '_read';
      newAssetUrl = iconArray.join('.');
      return this.get('assetMap').resolve(this.assetsDir + newAssetUrl);
    }),
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    store: Ember.inject.service(),
    metrics: Ember.inject.service(),
    click: function (event) {
      event.preventDefault();
      this.markAsRead();
      switch (this.get('model.notification_type')) {
        case 'blog/new':
          this.get('metrics').trackEvent({
            event: 'event',
            category: 'Notifications',
            action: 'click',
            label: "New Blog"
          });
          return window.open(_environment.default.BLOG_URL);
        case 'pilot/mission/new':
          this.get('metrics').trackEvent({
            event: 'event',
            category: 'Notifications',
            action: 'click',
            label: "Available Mission"
          });
          return this.routeToMission('availablemission');
        case 'app/updated':
          if (!confirm('In order to update our app we need to reload ' + 'your page. Click OK to reload.')) {
            return;
          }
          this.get('metrics').trackEvent({
            event: 'event',
            category: 'Notifications',
            action: 'click',
            label: "Reload App"
          });
          return location.reload();
        case 'pilot/mission/updated':
          this.get('metrics').trackEvent({
            event: 'event',
            category: 'Notifications',
            action: 'click',
            label: "Mission Update"
          });
          return this.routeToMission();
        case 'pilot/payout/new':
          this.get('metrics').trackEvent({
            event: 'event',
            category: 'Notifications',
            action: 'click',
            label: "New Payout"
          });
          return this.get('router').transitionTo('pilotlog');
        case 'pilot/mission/pano_accept':
          this.get('metrics').trackEvent({
            event: 'event',
            category: 'Notifications',
            action: 'click',
            label: "Accept Pano"
          });
          return this.routeToMission('panomission');
        case 'pilot/mission/pano_reject':
          this.get('metrics').trackEvent({
            event: 'event',
            category: 'Notifications',
            action: 'click',
            label: "Reject Pano"
          });
          return this.routeToMission('panomission');
        case "pilot/profile/stripe_onboarding":
          this.get('metrics').trackEvent({
            event: 'event',
            category: 'Notifications',
            action: 'click',
            label: "Stripe Onboarding"
          });
          return this.get('router').transitionTo('profile');
      }
    },
    markAsRead: function () {
      if (this.get('model.read_status') !== 'unread') {
        return;
      }
      this.get('model').set('read_status', 'read');
      return this.get('model').save().then(function (response) {
        return console.log('notifcation read');
      }, function (response) {
        return console.log('could not mark notification as read');
      });
    },
    routeToMission: function (route) {
      var mission_id;
      mission_id = this.get('model.web_route_url').split('/')[1];
      return this.get('store').findRecord('mission', mission_id).then(function (_this) {
        return function (mission) {
          return _this.get('router').transitionTo(route, mission.id);
        };
      }(this), function (_this) {
        return function (response) {
          alert('This mission is no longer available...');
          return _this.get('metrics').trackEvent({
            event: 'event',
            category: 'Notifications',
            action: 'click',
            label: "Mission No Longer Available"
          });
        };
      }(this));
    }
  });
  var _default = NotificationComponent;
  _exports.default = _default;
});
define("pilots/templates/components/select-inplace-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MmwLdg+e",
    "block": "[[[41,[33,1],[[[11,\"select\"],[16,0,[36,2]],[4,[38,3],[[30,0],\"save\"],[[\"on\"],[\"change\"]]],[12],[1,\"\\n  \"],[10,\"option\"],[14,2,\"\"],[12],[1,\"\\n    \"],[1,[34,4]],[1,\"\\n  \"],[13],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],\"@identity\",[[[1,\"    \"],[10,\"option\"],[15,2,[29,[[28,[37,8],[[30,1],[33,9]],null]]]],[12],[1,\"\\n      \"],[1,[28,[35,8],[[30,1],[33,10]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"inline-input\"],[12],[1,\"\\n\"],[41,[33,11],[[[41,[33,12],[[[1,\"        \"],[10,1],[14,0,\"field-label\"],[12],[1,[34,12]],[1,\":\"],[13],[1,\" \"],[1,[34,11]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[34,11]],[1,\"\\n\"]],[]]]],[]],[[[1,\"      \"],[10,1],[14,0,\"placeholder\"],[12],[1,\"\\n        \"],[1,[34,4]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"item\"],false,[\"if\",\"isEditing\",\"selectClass\",\"action\",\"prompt\",\"each\",\"-track-array\",\"content\",\"read-path\",\"optionValuePath\",\"optionLabelPath\",\"value\",\"label\"]]",
    "moduleName": "pilots/templates/components/select-inplace-edit.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/pilot-profile-drone-camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileDroneCameraComponent;
  PilotProfileDroneCameraComponent = Ember.Component.extend({
    actions: {
      create: function (camera) {
        return this.createCamera(camera);
      }
    }
  });
  var _default = PilotProfileDroneCameraComponent;
  _exports.default = _default;
});
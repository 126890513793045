define("pilots/templates/components/dropdown-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VGywCaIW",
    "block": "[[[10,0],[15,0,[29,[[36,0],\" dropdown-selection\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"field-value select-list\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"field-value-empty \",[52,[33,2],\"has-value\"]]]],[12],[1,[34,3]],[13],[10,0],[14,0,\"field-arrow icon-Arrow2_down\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[33,4],[[[1,\"  \"],[10,\"ul\"],[14,0,\"select-list-dropdown\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[1,\"      \"],[11,\"li\"],[4,[38,8],[[30,0],\"selectItem\",[30,1]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"item-creator\"],[12],[1,[52,[33,9],[28,[37,10],[[30,1],[33,9]],null],[30,1]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"item\"],false,[\"dropdownClasses\",\"if\",\"selectedItem\",\"display\",\"show\",\"each\",\"-track-array\",\"items\",\"action\",\"propertyName\",\"get\"]]",
    "moduleName": "pilots/templates/components/dropdown-menu.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/helpers/disable-bubbling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.disableBubbling = disableBubbling;
  function disableBubbling(_ref) {
    let [action] = _ref;
    return function (event) {
      event.stopPropagation();
      return action(event);
    };
  }
  var _default = Ember.Helper.helper(disableBubbling);
  _exports.default = _default;
});
define("pilots/mirage/factories/drone", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jshint esversion: 6 */
  var _default = _emberCliMirage.Factory.extend({
    full_name: "A long name"
  });
  _exports.default = _default;
});
define("pilots/components/scheduling-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global moment */

  const SchedulingCopyComponent = Ember.Component.extend({
    lastDayOfSLA: Ember.computed('mission.fly_by_date', function () {
      return moment(this.get('mission.fly_by_date')).format('MM/DD/YYYY');
    }),
    ifWeekendText: Ember.computed('mission.weekend_schedule_filter', function () {
      if (this.get('mission.weekend_schedule_filter') === 'any_day') {
        return '';
      } else if (this.get('mission.weekend_schedule_filter') === 'no_weekends') {
        return 'Monday - Friday ';
      } else {
        return 'Saturday or Sunday ';
      }
    })
  });
  var _default = SchedulingCopyComponent;
  _exports.default = _default;
});
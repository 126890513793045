define("pilots/helpers/mission-next-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MissionNextStepHelper;
  MissionNextStepHelper = Ember.Helper.helper(function (arg) {
    var status;
    status = arg[0];
    if (status === 'pilot_assigned') {
      return 'Complete Flight';
    } else if (status === 'flight_complete') {
      return 'Upload Assets';
    } else {
      return '';
    }
  });
  var _default = MissionNextStepHelper;
  _exports.default = _default;
});
define("pilots/helpers/file-extension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let fileExtensionHelper = Ember.Helper.helper(function (_ref) {
    let [url] = _ref;
    if (typeof url === 'string') {
      const match = url.match(/\.([0-9a-z]+)([\?#]|$)/i);
      if (match && match.length > 1) {
        return match[1].toLowerCase();
      }
    }
    return null;
  });
  var _default = fileExtensionHelper;
  _exports.default = _default;
});
define("pilots/templates/pilot/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0jAbkiXa",
    "block": "[[[1,[28,[35,0],null,[[\"pilot\",\"countries\",\"travelDistance\",\"class\",\"action\"],[[33,1,[\"pilot\"]],[33,1,[\"countries\"]],[33,1,[\"travelDistance\"]],\"form\",\"save\"]]]],[1,\"\\n\"]],[],false,[\"pilot-form\",\"model\"]]",
    "moduleName": "pilots/templates/pilot/new.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/shot-list-descriptions", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    shotImagesExpanded: false,
    lightboxSetup: Ember.on('didRender', function () {
      if ((0, _jquery.default)('#sample-images-desktop-' + this.get('shotNameSpacesRemoved')).length) {
        (0, _jquery.default)('#sample-images-desktop-' + this.get('shotNameSpacesRemoved')).lightGallery({
          mode: 'lg-slide',
          selector: '.desktop-sample-image'
        });
      }
      if ((0, _jquery.default)('#sample-images-mobile-' + this.get('shotNameSpacesRemoved')).length) {
        (0, _jquery.default)('#sample-images-mobile-' + this.get('shotNameSpacesRemoved')).lightGallery({
          mode: 'lg-slide',
          selector: '.mobile-sample-image'
        });
      }
    }),
    shotNameSpacesRemoved: Ember.computed('shot.name', function () {
      return this.get('shot.name').replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    }),
    showSampleImages: Ember.computed('shotImagesExpanded', 'shot.shot_type.sample_images', function () {
      if (this.get('shotImagesExpanded')) {
        return this.get('shot.shot_type.sample_images');
      }
      return this.get('shot.shot_type.sample_images').slice(0, 2);
    }),
    actions: {
      toggleExpandShotImages() {
        this.set('shotImagesExpanded', !this.get('shotImagesExpanded'));
      }
    }
  });
  _exports.default = _default;
});
define("pilots/data/travel_distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    "label": "10 Miles",
    "value": 10
  }, {
    "label": "25 Miles",
    "value": 25
  }, {
    "label": "50 Miles",
    "value": 50
  }, {
    "label": "100 Miles",
    "value": 100
  }, {
    "label": "200 Miles",
    "value": 200
  }];
  _exports.default = _default;
});
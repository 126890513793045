define("pilots/components/pilot-profile-license-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileLicenseNewComponent;
  PilotProfileLicenseNewComponent = Ember.Component.extend({
    pilotLicenseNumber: Ember.computed('model.pilotLicenses.length', {
      get: function () {
        return this.get('model.pilotLicenses.length') + 1;
      },
      set: function (key, value) {
        return this._pilotLicenseNumber = value;
      }
    }),
    observePilotLicenseNumber: Ember.observer('model.pilotLicenses.length', function () {
      return this.set('pilotLicenseNumber', this.get('model.pilotLicenses.length') + 1);
    }),
    availableLicenses: Ember.computed('model.pilotLicenses.[]', function () {
      var licenses;
      licenses = [];
      this.get('model.licenses').forEach(function (_this) {
        return function (license) {
          if (!_this.get('model.pilotLicenses').mapBy('license').mapBy('content').includes(license)) {
            return licenses.push(license);
          }
        };
      }(this));
      return licenses;
    }),
    cta: Ember.computed('model.pilotLicenses.length', function () {
      if (this.get('model.pilotLicenses.length') > 0) {
        return 'Add Another Document';
      } else {
        return 'Add a Document';
      }
    }),
    didInsertElement: function () {
      return $('.select-license').on('click', function () {
        event.stopPropagation();
        $('.new-license-dropdown').toggle();
        return $('html').one('click', function () {
          return $('.new-license-dropdown').hide();
        });
      });
    },
    actions: {
      openModal: function () {
        return this.set('isModalOpen', true);
      },
      create: function (license) {
        $('.new-license-dropdown').toggle();
        return this.createLicense(license);
      }
    }
  });
  var _default = PilotProfileLicenseNewComponent;
  _exports.default = _default;
});
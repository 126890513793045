define("pilots/components/dashboard-map", ["exports", "jquery", "pilots/config/environment", "pilots/data/mission_type", "pilots/data/map_features", "pilots/mixins/google-init-mixin"], function (_exports, _jquery, _environment, _mission_type, _map_features, _googleInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  var DashboardMapComponent;
  DashboardMapComponent = Ember.Component.extend(_googleInitMixin.default, {
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    store: Ember.inject.service(),
    assetMap: Ember.inject.service(),
    classNameBindings: ['pilotlog:map-container:map-container-dashboard'],
    pilotlog: false,
    smallDevice: false,
    mapStyles: _map_features.default.mapStylesData,
    pointOfInterestMarkers: Ember.A([]),
    missionMarkers: Ember.A([]),
    missionTypeData: _mission_type.default,
    missionFilters: Ember.A([]),
    assetsDir: 'assets/images/v2/',
    actions: {
      declineMission: function (mission) {
        this.get('model.missions').removeObject(mission);
        this.loadMissions();
        return this.get('metrics').trackEvent({
          event: 'event',
          category: 'Active Missions',
          action: 'click',
          label: "Decline Available Mission"
        });
      },
      selectedMission: function (mission) {
        this.selectMission(mission, true);
        return this.get('metrics').trackEvent({
          event: 'event',
          category: 'Active Missions',
          action: 'click',
          label: "Select Mission From Panel"
        });
      },
      availableMission: function (mission) {
        return this.get('store').findRecord('mission', mission.id).then(function (_this) {
          return function (mission) {
            return _this.get('router').transitionTo('availablemission', mission.id);
          };
        }(this), function (_this) {
          return function (response) {
            alert('This mission is no longer available...');
            _this.get('model.missions').removeObject(mission);
            return _this.loadMissions();
          };
        }(this));
      }
    },
    init: function () {
      this._super();
      if (window.innerWidth <= 767) {
        this.smallDevice = true;
      }
      return this.setupFilters();
    },
    setupFilters: function () {
      var MissionType;
      MissionType = Em.Object.extend({
        type: null,
        color: null,
        fontIcon: null,
        png: null,
        name: null,
        selected: true,
        selected_png: null
      });
      return this.missionTypeData.forEach(function (_this) {
        return function (mission_type) {
          var icon_png, icon_png_array, missionType;
          missionType = MissionType.create(mission_type);
          icon_png_array = missionType.png.split('.');
          icon_png_array[0] = icon_png_array[0] + '_selected';
          icon_png = icon_png_array.join('.');
          missionType.selected_png = _this.get('assetMap').resolve(_this.assetsDir + icon_png);
          missionType.png = _this.get('assetMap').resolve(_this.assetsDir + missionType.png);
          return _this.get('missionFilters').pushObject(missionType);
        };
      }(this));
    },
    filterMap: function () {
      return this.missionMarkers.forEach(function (_this) {
        return function (marker) {
          return marker.setVisible(_this.get('missionFilters').findBy('type', marker.getIcon().type).get('selected'));
        };
      }(this));
    },
    afterGoogleLoaded: function () {
      var mapOptions, zoom;
      zoom = this.smallDevice ? 13 : 15;
      mapOptions = {
        zoom: zoom,
        tilt: 0,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT
        },
        streetViewControl: false,
        scaleControl: true,
        panControl: true,
        zoomControl: true,
        draggable: true,
        scrollwheel: !this.get('publicsearch'),
        styles: this.mapStyles,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM
        }
      };
      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
      }
      if (this.get('pilotlog')) {
        if (!this.get('model.missions').get('length')) {
          this.locateMe();
        }
        return this.loadMissions(true);
      } else {
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('map-filter-container'));
        this.infoWindow = new google.maps.InfoWindow();
        return this.locateMe().then(function (_this) {
          return function () {
            _this.loadMissions();
            _this.setupInfoWindow();
            _this.setupAutocomplete();
            return _this.map.addListener('idle', function () {
              _this.infoWindow.close();
              return _this.filterMap();
            });
          };
        }(this));
      }
    },
    defaultLocation: function () {
      return new google.maps.LatLng(34.019341, -118.493139);
    },
    locateMe: function () {
      return new Promise(function (_this) {
        return function (resolve, reject) {
          var lat, lat_lng, lon;
          try {
            lat = Number(_this.get('sessionAccount.account').get('latitude'));
            lon = Number(_this.get('sessionAccount.account').get('longitude'));
            if (isNaN(lat)) {
              lat_lng = _this.defaultLocation();
            } else {
              lat_lng = new google.maps.LatLng(lat, lon);
            }
          } catch (error) {
            lat_lng = _this.defaultLocation();
          }
          if (navigator.geolocation) {
            console.log('navigator.geolocation: true');
            navigator.geolocation.getCurrentPosition(function (response) {
              console.log('geo coded location');
              lat_lng = new google.maps.LatLng(response.coords.latitude, response.coords.longitude);
              _this.map.setCenter(lat_lng);
              _this.get('metrics').trackEvent({
                event: 'event',
                category: 'Active Missions',
                action: 'click',
                label: "Map Location: Geolocated"
              });
              return resolve(true);
            }, function (response) {
              console.log('pilot account/default location');
              _this.map.setCenter(lat_lng);
              _this.get('metrics').trackEvent({
                event: 'event',
                category: 'Active Missions',
                action: 'click',
                label: "Map Location: Pilot Account Location"
              });
              return resolve(true);
            });
          } else {
            console.log('navigator.geolocation: false');
            lat_lng = new google.maps.LatLng(lat, lon);
            _this.map.setCenter(lat_lng);
            _this.get('metrics').trackEvent({
              event: 'event',
              category: 'Active Missions',
              action: 'click',
              label: "Map Location: Default"
            });
            resolve(true);
          }
          return setTimeout(function () {
            if (_this.map.center == null) {
              console.log('firefox pilot account/default location after 5sec timeout');
              _this.map.setCenter(lat_lng);
              _this.get('metrics').trackEvent({
                event: 'event',
                category: 'Active Missions',
                action: 'click',
                label: "Map Location: Default (After FF Timeout)"
              });
              return resolve(true);
            }
          }, 5000);
        };
      }(this));
    },
    setupInfoWindow: function () {
      var _this;
      _this = this;
      this.infoWindow = new google.maps.InfoWindow();
      this.infoWindowNode = (0, _jquery.default)('#info-window-node');
      return this.infoWindow.addListener('domready', function (event) {
        var iwBackground, iwCloseBtn, iwOuter;
        iwOuter = (0, _jquery.default)('.gm-style-iw');
        iwOuter.children().first().css({
          'display': 'block'
        });
        iwBackground = iwOuter.prev();
        iwBackground.children(':nth-child(2)').css({
          'display': 'none'
        });
        iwBackground.children(':nth-child(4)').css({
          'display': 'none'
        });
        iwBackground.children(':nth-child(3)').find('div').children().css({
          'z-index': 1,
          'box-shadow': '0 1px 6px rgba(178, 178, 178, 0.6)'
        });
        iwCloseBtn = iwOuter.next();
        iwCloseBtn.css({
          'display': 'none'
        });
        return _this.populateInfoWindow(_this.infoWindow);
      });
    },
    populateInfoWindow: function (infoWindow) {
      var address, contentArr, image_url, share_url;
      contentArr = infoWindow.getContent().split('||');
      if (this.get('publicsearch')) {
        image_url = contentArr[0];
        share_url = contentArr[1];
        address = contentArr[2];
        this.infoWindowNode.html("<div id='publicsearch-pano-infowindow'> <img src='" + image_url + "'/> <div class='content'> <div class='icon icon-Pin'></div> <div class='address'>" + address + "</div> <a href=" + share_url + " class='link' target='_blank'>View Pano ></a> </div> </div>");
      } else {
        this.infoWindowNode.html("<div id='pano-infowindow'> <div class='pano-infowindow-content'> <div class='pano-infowindow-title'>Pano Mission - " + contentArr[1] + "</div> <div class='pano-infowindow-icon icon-Pin'></div> <div class='pano-infowindow-address'>" + contentArr[2] + "</div> <div class='pano-infowindow-likelihood' title='Likelihood of Payout'> <div class='likelihood-container'> <div class='likelihood-bg'> <div class='likelihood-bg-bar' style='width: " + contentArr[3] + "%'></div> <span class='likelihood-space'></span><span class='likelihood-space'></span><span class='likelihood-space'></span><span class='likelihood-space'></span><span class='likelihood-space'></span> <a href='" + _environment.default.PILOT_WEB_FAQ_URL + "' title='Likelihood of Payout' target='_blank' class='likelihood-info-icon icon-info'></a> </div> </div> </div> <div data-pano-id=" + contentArr[0] + " class='pano-infowindow-action'> <div class='icon-Star_a pano-infowindow-action-icon'></div> Add Mission </div>");
      }
      this.infoWindowNode.find('.pano-infowindow-action').click(function (_this) {
        return function (event) {
          var pano_id;
          if (!(pano_id = Number(event.target.dataset.panoId))) {
            pano_id = Number(event.target.parentElement.dataset.panoId);
          }
          return _this.createPointOfInteresMission(pano_id);
        };
      }(this));
      return infoWindow.setContent(this.infoWindowNode[0]);
    },
    createPointOfInteresMission: function (id) {
      var _mission, _poi, _this;
      _this = this;
      _poi = this.get('model.missions').store.createRecord('point_of_interest', {
        id: id
      });
      _mission = this.get('model.missions').store.createRecord('mission', {
        point_of_interest: _poi
      });
      return _mission.save().then(function (response) {
        var _marker;
        _this.infoWindow.close();
        if (_marker = _this.pointOfInterestMarkers.findBy('position', _this.infoWindow.position)) {
          _marker.setVisible(false);
          _this.pointOfInterestMarkers.removeObject(_marker);
        }
        _this.get('model.missions').pushObject(response._internalModel);
        _mission = _this.get('model.missions').findBy('id', response.id);
        _this.missionMarkers.pushObject(_this.createMarker(_mission));
        _this.selectMission(_mission);
        return _this.get('metrics').trackEvent({
          event: 'event',
          category: 'Active Missions',
          action: 'click',
          label: "Map: Starred Pano"
        });
      }, function (response) {
        return alert('No longer available');
      });
    },
    getIcon: function (mission, selected) {
      var anchor, icon, icon_png;
      if (selected == null) {
        selected = false;
      }
      icon_png = this.get('missionFilters').findBy('type', mission.get('missionStatusType')).get('png');
      anchor = new google.maps.Point(0, 0);
      if (selected) {
        icon_png = this.get('missionFilters').findBy('type', mission.get('missionStatusType')).get('selected_png');
        anchor = new google.maps.Point(17, 13);
      }
      icon = {
        url: icon_png,
        anchor: anchor,
        type: mission.get('missionStatusType')
      };
      return icon;
    },
    selectMission: function (mission, pan_to) {
      var selected_missions;
      if (pan_to == null) {
        pan_to = false;
      }
      selected_missions = this.get('model.missions').filterBy('selected_on_dashboard', true);
      if (selected_missions) {
        selected_missions.forEach(function (_this) {
          return function (selected_mission) {
            selected_mission.get('map_marker').setIcon(_this.getIcon(selected_mission));
            return selected_mission.set('selected_on_dashboard', false);
          };
        }(this));
      }
      mission.set('selected_on_dashboard', true);
      mission.get('map_marker').setIcon(this.getIcon(mission, true));
      if (pan_to) {
        return this.map.panTo(mission.get('map_marker').getPosition());
      }
    },
    createMarker: function (mission) {
      var marker;
      if (mission.get('missionStatusType') === 'creative_mission') {
        return;
      }
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(mission.get('location.latitude'), mission.get('location.longitude')),
        map: this.map,
        icon: this.getIcon(mission)
      });
      mission.set('map_marker', marker);
      google.maps.event.addListener(marker, 'click', function (_this) {
        return function () {
          return _this.selectMission(mission);
        };
      }(this));
      return marker;
    },
    clearMissionMarkers: function () {
      this.missionMarkers.forEach(function (marker) {
        return marker.setMap(null);
      });
      return this.missionMarkers.clear();
    },
    loadMissions: function (set_bounds) {
      var bounds;
      if (set_bounds == null) {
        set_bounds = false;
      }
      bounds = new google.maps.LatLngBounds();
      this.clearMissionMarkers();
      this.get('model.missions').forEach(function (_this) {
        return function (mission) {
          var marker;
          marker = _this.createMarker(mission);
          if (!marker) {
            return;
          }
          bounds.extend(marker.getPosition());
          return _this.missionMarkers.pushObject(marker);
        };
      }(this));
      if (set_bounds) {
        return this.map.fitBounds(bounds);
      }
    },
    setupAutocomplete: function () {
      var input, searchBox;
      input = $('#filter-search-field')[0];
      searchBox = new google.maps.places.SearchBox(input);
      this.map.addListener('bounds_changed', function () {
        return searchBox.setBounds(_this.map.getBounds());
      });
      return searchBox.addListener('places_changed', function (_this) {
        return function () {
          var place;
          if (searchBox.getPlaces().length === 0) {
            alert('Address search returned no results.');
            _this.get('metrics').trackEvent({
              event: 'event',
              category: 'Active Missions',
              action: 'click',
              label: "Failed Search"
            });
            return;
          }
          place = searchBox.getPlaces()[0];
          _this.map.setCenter(place.geometry.location);
          return _this.get('metrics').trackEvent({
            event: 'event',
            category: 'Active Missions',
            action: 'click',
            label: "Successful Search"
          });
        };
      }(this));
    }
  });
  var _default = DashboardMapComponent;
  _exports.default = _default;
});
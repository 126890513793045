define("pilots/models/point-of-interest", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PointOfInterest;
  PointOfInterest = _emberData.default.Model.extend({
    likelihood_of_payout: _emberData.default.attr('number'),
    property_type: _emberData.default.attr('string'),
    location: _emberData.default.belongsTo('location', {
      async: false
    })
  });
  var _default = PointOfInterest;
  _exports.default = _default;
});
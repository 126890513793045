define("pilots/routes/dashboard", ["exports", "pilots/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _environment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardRoute;
  DashboardRoute = Ember.Route.extend(_authenticatedRouteMixin.default, {
    store: Ember.inject.service(),
    totalPages: 1,
    perPage: 50,
    model: function (params) {
      return Ember.RSVP.hash({
        pilot: this.get('store').findRecord('pilot', 'stubId'),
        workOrders: this.get('store').findAll('work-order'),
        missions: this.get('store').query('mission', {
          status: 'available,pilot_accepted,flight_complete,assets_uploaded',
          per_page: this.get('perPage'),
          page: params.pageNumber
        })
      });
    },
    setupController: function (controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('adminMode', !!this.get('session.data.authenticated.real_admin'));
      return this.set('totalPages', model.missions.meta.total_pages);
    },
    actions: {
      addedPanoMission: function () {
        return this.refresh();
      }
    }
  });
  var _default = DashboardRoute;
  _exports.default = _default;
});
define("pilots/templates/components/select-validated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VcHtIRnc",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"error\"],[12],[1,[30,1,[\"message\"]]],[13],[1,\"\\n\"]],[1]],null],[41,[33,4],[[[1,\"  \"],[10,0],[14,0,\"error\"],[12],[1,[34,5]],[13],[1,\"\\n\"]],[]],null],[11,\"select\"],[16,0,[36,6]],[4,[38,7],[[30,0],\"updateValue\"],[[\"on\"],[\"change\"]]],[12],[1,\"\\n  \"],[10,\"option\"],[14,2,\"\"],[12],[1,\"\\n    \"],[1,[34,8]],[1,\"\\n  \"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,9]],null]],null],\"@identity\",[[[1,\"    \"],[10,\"option\"],[15,2,[29,[[28,[37,10],[[30,2],[33,11]],null]]]],[12],[1,\"\\n      \"],[1,[28,[35,10],[[30,2],[33,12]],null]],[1,\"\\n     \"],[13],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"error\",\"item\"],false,[\"each\",\"-track-array\",\"modelErrors\",\"if\",\"showError\",\"errors\",\"selectClass\",\"action\",\"prompt\",\"content\",\"read-path\",\"optionValuePath\",\"optionLabelPath\"]]",
    "moduleName": "pilots/templates/components/select-validated.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/templates/components/profile-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KCvNf68A",
    "block": "[[[10,0],[15,0,[29,[\"left-arrow \",[52,[51,[33,1]],\"hidden\"]]]],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"content\"],[12],[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[11,3],[24,0,\"cross\"],[4,[38,4],[[30,0],\"doneAction\"],null],[12],[1,\"\\n      \"],[10,1],[14,0,\"icon-Xmark\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"head\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"title\"],[12],[1,\"\\n      \"],[1,[34,5]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"text\"],[12],[1,\"\\n      \"],[1,[34,6]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"footer\"],[12],[1,\"\\n    \"],[8,[39,7],[[4,[38,4],[[30,0],\"nextAction\"],null]],[[\"@size\"],[\"medium\"]],[[\"default\"],[[[[1,[34,8]],[1,\" \"]],[]]]]],[1,\"\\n\\n\"],[41,[33,9],[[[1,\"      \"],[10,0],[14,0,\"pills\"],[12],[1,\"\\n        \"],[1,[28,[35,10],null,[[\"pillCount\",\"selectedIndex\"],[[33,11],[33,12]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[13],[1,\"\\n\"]],[],false,[\"unless\",\"showLeftArrow\",\"if\",\"showCross\",\"action\",\"title\",\"text\",\"button\",\"nextText\",\"showPills\",\"pill-list\",\"pillCount\",\"selectedPillIndex\"]]",
    "moduleName": "pilots/templates/components/profile-tooltip.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
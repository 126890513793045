define("pilots/components/mission-map", ["exports", "jquery", "pilots/data/map_features", "ember-cli-file-saver/mixins/file-saver", "pilots/mixins/google-init-mixin"], function (_exports, _jquery, _map_features, _fileSaver, _googleInitMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_fileSaver.default, _googleInitMixin.default, {
    geoJsonConverter: Ember.inject.service(),
    colorIndex: 0,
    mapStyles: _map_features.default.mapStylesData,
    featureColors: _map_features.default.featureColors,
    featureOptions: _map_features.default.featureOptions,
    selectedFeatureOptions: _map_features.default.selectedFeatureOptions,
    disabledFeatureOptions: _map_features.default.disabledFeatureOptions,
    features: null,
    afterGoogleLoaded() {
      const _this = this;
      this.set('features', Em.A([]));
      const mapOptions = {
        tilt: 0,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_LEFT
        },
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        panControl: true,
        mapTypeId: google.maps.MapTypeId.HYBRID,
        zoomControl: true,
        draggable: true,
        scrollwheel: false,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.LEFT_BOTTOM
        },
        panControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },
        // map style: https://snazzymaps.com/style/27/shift-worker
        styles: this.mapStyles
      };
      if (!this.map) {
        this.map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);
      }
      this.map.setZoom(13);
      this.map.data.addListener('addfeature', event => {
        let bounds, classes, id, latlng;
        const options = this.lookUpMapOptions(event.feature);
        this.map.data.overrideStyle(event.feature, options.defaultOptions);
        this.map.data.overrideStyle(event.feature, {
          fillColor: options.mapColor,
          strokeColor: options.strokeColor
        });
        this.get('features').pushObject(this.Feature.create({
          id: event.feature.getId(),
          object: event.feature,
          feature_type: event.feature.getProperty('feature_type'),
          no_fly_zone: event.feature.getProperty('no_fly_zone'),
          color: options.keyColor,
          iconName: options.iconName
        }));
        if (event.feature.getGeometry().getType() === 'Point') {
          latlng = event.feature.getGeometry().get();
        } else if (event.feature.getGeometry().getType() === 'LineString') {
          bounds = new google.maps.LatLngBounds();
          event.feature.getGeometry().getArray().forEach(latLng => bounds.extend(latLng));
          latlng = new google.maps.LatLng(bounds.getCenter().lat(), bounds.getCenter().lng());
        } else {
          bounds = new google.maps.LatLngBounds();
          event.feature.getGeometry().getArray().forEach(path => path.getArray().forEach(latLng => bounds.extend(latLng)));
          latlng = new google.maps.LatLng(bounds.getCenter().lat(), bounds.getCenter().lng());
        }
        if (!(id = event.feature.getId())) {
          id = 1;
        }
        if (event.feature.getGeometry().getType() === 'Point') {
          return classes = 'client-note-marker point';
        } else {
          return classes = 'client-note-marker polygon';
        }
      });
      this.map.data.addListener('setproperty', function (event) {
        const polygon = _this.get('features').findBy('object', event.feature);
        if (polygon) {
          return polygon.set(event.name, event.newValue);
        }
      });
      this.map.data.addListener('setgeometry', function (event) {
        if (event.feature.getGeometry().getType() === 'Polygon') {
          return _this.setGeometryData(event.feature);
        }
      });
      this.map.data.addListener('click', event => _this.enableFeature(event.feature));
      this.map.data.addListener('mousedown', event => {
        this.infoWindow.close();
        return this.infoAddressWindow.close();
      });
      this.map.addListener('mousedown', event => {
        this.infoAddressWindow.close();
        return this.set('clientNotesVisible', false);
      });
      this.map.addListener('zoom_changed', event => {
        return $('.client-note-marker').css('font-size', this.map.getZoom() + 'px');
      });
      this.map.data.addListener('mousedown', event => {
        const id = event.feature.getId();
        this.set('clientNotesVisible', true);
        $('.client-note').removeClass('highlight');
        $(`.id-${id}`).addClass('highlight');
        return this.infoWindow.close();
      });
      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        if (!this.get('mission.map_features.length')) {
          const addressLocation = {
            lat: Number(this.get('mission.location.latitude')),
            lng: Number(this.get('mission.location.longitude'))
          };
          this.addMarker(addressLocation, false);
          return this.map.setCenter(addressLocation);
        }
      });
      this.infowindow = new google.maps.InfoWindow();
      this.setupInfoWindow();
      this.setupAddressInfoWindow();
      this.Feature = Ember.Object.extend({
        id: null,
        object: null,
        name: '',
        notes: '',
        area: null,
        center: null
      });
      google.maps.event.addListener(this.map, 'click', () => _this.disableFeatures());

      // SET LOCATION + DATA (if avail)
      let latLng = null;
      if (this.get('mission.map_features.length') > 0) {
        this.addGeoJson(this.geoJsonConverter.toGeoJson(this.get('mission.map_features')));
      } else if (this.get('mission.location.latitude') && this.get('mission.location.longitude')) {
        latLng = new google.maps.LatLng({
          lat: Number(this.get('mission.location.latitude')),
          lng: Number(this.get('mission.location.longitude'))
        });
        this.map.setCenter(latLng);
      } else {
        this.defaultLocation();
      }
      this.loadImagePins();
      this.map.setZoom(12);
      return this.fitAllFeatures();
    },
    getNextColor(feature) {
      this.set('colorIndex', this.get('colorIndex') + 1);
      return this.get('featureColors')[this.get('colorIndex') % 5];
    },
    lookUpMapOptions(feature) {
      let keyColor, mapColor, strokeColor;
      const geometryType = feature.getGeometry().getType();
      const isNoFlyZone = feature.getProperty('type') === 'No Fly Zone' || feature.getProperty('no_fly_zone');
      const featureType = feature.getProperty('feature_type');
      const options = (() => {
        switch (geometryType) {
          case 'Polygon':
            if (isNoFlyZone) {
              return this.get('featureOptions').NOFLYZONE;
            } else {
              return this.get('featureOptions').PROPERTYAREA;
            }
          case 'LineString':
            return this.get('featureOptions').LINE;
          case 'Point':
            if (featureType === 'panorama') {
              return this.get('featureOptions').PANORAMA;
            } else {
              return this.get('featureOptions').POINTOFINTEREST;
            }
        }
      })();
      if (isNoFlyZone) {
        mapColor = '#F26649';
        keyColor = 'transparent';
      } else if (this.get('gpsCheck')) {
        strokeColor = '#5B48FB';
        mapColor = '#FFFFFF';
      } else if (['LineString', 'Polygon'].includes(geometryType)) {
        mapColor = this.getNextColor();
      } else {
        // Point
        mapColor = 'transparent';
      }
      options.mapColor = mapColor;
      options.keyColor = keyColor || mapColor;
      options.strokeColor = strokeColor || mapColor;
      if (this.get('gpsCheck')) {
        options.defaultOptions.strokeWeight = 2;
      }
      return options;
    },
    defaultLocation() {
      const lat_lng = new google.maps.LatLng(37.2350, -115.8111);
      return this.map.setCenter(lat_lng);
    },
    setupInfoWindow() {
      this.infoWindow = new google.maps.InfoWindow();
      this.infoWindowNode = (0, _jquery.default)('#info-window-node');
      return this.infoWindow.addListener('domready', () => {
        return this.populateInfoWindow(this.infoWindow);
      });
    },
    populateInfoWindow(target) {
      const contentArr = target.content.split('||');
      const feature = this.features.findBy('id', contentArr[0]);
      let offset = null;
      let content = "<div class='info-window-container'>";
      content += `<span class='name'>${contentArr[1]}</span>`;
      if (contentArr[3]) {
        content += `<span class='geometric-info'>${contentArr[3]}</span>`;
      }
      if (contentArr[4]) {
        content += `<span class='geometric-info'>${contentArr[4]}</span>`;
      }
      if (contentArr[5]) {
        content += `<span class='geometric-info'>Altitude: ${contentArr[5]}</span>`;
      }
      if (feature.object.getGeometry().getType() === 'Point') {
        this.infoWindowNode.addClass('point-info');
        offset = new google.maps.Size(0, -65);
      }
      if (feature.object.getGeometry().getType() === 'Polygon') {
        this.infoWindowNode.removeClass('point-info');
        offset = new google.maps.Size(0, -10);
      } else {
        this.infoWindowNode.css({
          'border-style': 'none'
        });
      }
      content += "</div>";
      this.infoWindowNode.html(content);
      target.set('content', this.infoWindowNode[0]);
      if (offset) {
        target.set('pixelOffset', offset);
      }
    },
    enableInfoWindow(feature) {
      const map_feature = this.get('mission.map_features').findBy('id', feature.getId());
      let position;
      const shotTypeAttributes = feature.getProperty('shot_type_attributes');
      const panoAttributes = shotTypeAttributes && shotTypeAttributes[this.mission.package.get('panoramaShotType.id')];
      const altitude = panoAttributes && panoAttributes['altitude'];
      let info = this.concatInfo(`${feature.getId()}||${map_feature.name}`, this.formatLength(feature.getProperty('lineLength')));
      const area = map_feature.properties.rgeo_area || map_feature.properties.area;
      info = this.concatInfo(info, this.formatArea(area));
      const content = `${this.concatInfo(info, this.formatAltitude(altitude))}`;
      if (feature.getGeometry().getType() === 'Polygon') {
        position = feature.getGeometry().getAt(0).getAt(0);
        this.infoWindow.setOptions({
          content,
          position,
          pixelOffset: new google.maps.Size(0, 0)
        });
      } else if (feature.getGeometry().getType() === 'LineString') {
        position = feature.getGeometry().getAt(0);
        this.infoWindow.setOptions({
          content,
          position,
          pixelOffset: new google.maps.Size(0, 0)
        });
      } else if (feature.getGeometry().getType() === 'Point') {
        position = feature.getGeometry().get();
        this.infoWindow.setOptions({
          content,
          position,
          pixelOffset: new google.maps.Size(0, -30)
        });
      }
      return this.infoWindow.open(this.map);
    },
    concatInfo(original, suffix) {
      if (suffix != undefined && suffix.length) {
        return `${original}||${suffix}`;
      } else {
        return `${original}||`;
      }
    },
    formatLength(length) {
      if (!length) {
        return '';
      }
      let formatted = `${length} meter`;
      if (length !== 1.0) {
        formatted += 's';
      }
      return `${formatted} (${this.metersToFeet(length)})`;
    },
    formatArea(area) {
      if (!area) {
        return '';
      }
      return `${this.metersToAcres(area)} (${this.metersToFeetSqr(area)})`;
    },
    formatAltitude(altitude) {
      if (!altitude) {
        return '';
      }
      return `${altitude} ft`;
    },
    metersToAcres(area) {
      const val = (area * 0.0002471).toFixed(2);
      let acres = `${val} acre`;
      if (val !== 1) {
        acres += 's';
      }
      return acres;
    },
    metersToFeet(area) {
      const val = Math.round(area * 3.2808).toLocaleString();
      return `${val} feet`;
    },
    metersToFeetSqr(area) {
      const val = Math.round(area * 10.76391).toLocaleString();
      return `${val} sqr feet`;
    },
    setupAddressInfoWindow() {
      this.infoAddressWindow = new google.maps.InfoWindow();
      this.infoAddressWindowNode = (0, _jquery.default)('#info-window-node');
      return this.infoAddressWindow.addListener('domready', function (event) {
        const iwOuter = (0, _jquery.default)('.gm-style-iw');
        iwOuter.children().first().css({
          'display': 'block'
        });
        const iwBackground = iwOuter.prev();
        iwBackground.children(':nth-child(2)').css({
          'display': 'none'
        });
        iwBackground.children(':nth-child(4)').css({
          'display': 'none'
        });
        iwBackground.children(':nth-child(3)').find('div').children().css({
          'z-index': 1,
          'box-shadow': '0 1px 6px rgba(178, 178, 178, 0.6)'
        });
        const iwCloseBtn = iwOuter.next();
        return iwCloseBtn.css({
          'display': 'none'
        });
      });
    },
    addGeoJson(json) {
      const features = this.map.data.addGeoJson(json);
      this.set('geoFeatures', features);
      const bounds = new google.maps.LatLngBounds();
      if (features) {
        features.forEach(function (feature) {
          if (feature.getGeometry().getType() === 'Polygon') {
            return feature.getGeometry().getArray().forEach(path => path.getArray().forEach(latLng => bounds.extend(latLng)));
          } else if (feature.getGeometry().getType() === 'Point') {
            return bounds.extend(feature.getGeometry().get());
          }
        });
      }
      bounds.extend({
        lat: Number(this.get('mission.location.latitude')),
        lng: Number(this.get('mission.location.longitude'))
      });
      this.disableFeatures();
      return this.map.fitBounds(bounds);
    },
    addMarker(position, draggable) {
      if (draggable == null) {
        draggable = true;
      }
      const animation = google.maps.Animation.DROP;
      const marker = new google.maps.Marker({
        map: this.map,
        position,
        draggable,
        animation,
        icon: {
          url: '/assets/images/v2/client_mission_assigned.svg'
        }
      });
      const _this = this;
      return marker.addListener('click', function () {
        _this.enableAddressInfoWindow();
        _this.infoAddressWindow.setPosition(this.getPosition());
        return _this.infoAddressWindow.open(_this.map);
      });
    },
    setGeometryData(polygon) {
      const bounds = new google.maps.LatLngBounds();
      polygon.getGeometry().getArray().forEach(path => path.getArray().forEach(latLng => bounds.extend(latLng)));
      polygon.setProperty('bounds', bounds);
      polygon.setProperty('area', Math.round(google.maps.geometry.spherical.computeArea(polygon.getGeometry().getArray()[0].getArray())));
      return polygon.setProperty('center', polygon.getProperty('bounds').getCenter());
    },
    enableAddressInfoWindow() {
      const addressLine1 = `${this.get('mission.location.address')}, ${this.get('mission.location.city')},`;
      const addressLine2 = `${this.get('mission.location.state')}, ${this.get('mission.location.country')}`;
      const feature_description = `\
<div class='client-infowindow'> \
<span class='address'>${this.get('mission.id')}</span><br> \
${addressLine1}<br>${addressLine2} \
</div>`;
      const offsetHeight = new google.maps.Size(-2, -30);
      this.infoAddressWindow.setOptions({
        pixelOffset: offsetHeight
      });
      return this.infoAddressWindow.setContent(feature_description);
    },
    enableFeature(feature) {
      if (this.get('gpsCheck')) {
        return;
      }
      this.infoWindow.close();
      let options = this.get('selectedFeatureOptions');
      if (feature.getProperty('feature_type') === 'panorama') {
        options.icon = '/assets/images/map/pano_pin.png';
      } else {
        options.icon = '/assets/images/pilot/point_of_interest_selected_pin.svg';
      }
      this.map.data.overrideStyle(feature, options);
      this.get('features').forEach(f => {
        if (feature.getId() !== f.id) {
          options = this.get('disabledFeatureOptions');
          if (f.feature_type === 'orbit') {
            options.icon = '/assets/images/map/orbit_pin.png';
          } else if (f.feature_type === 'panorama') {
            options.icon = '/assets/images/map/pano_pin.png';
          } else {
            options.icon = '/assets/images/pilot/point_of_interest_pin.svg';
          }
          return this.map.data.overrideStyle(f.object, options);
        }
      });
      return this.enableInfoWindow(feature);
      const map_feature = this.get('mission.map_features').findBy('id', event.feature.getId());
    },
    disableFeatures() {
      const _this = this;
      if (this.get('gpsCheck')) {
        return;
      }
      this.infoWindow.close();
      return this.get('features').forEach(function (f) {
        const options = _this.get('disabledFeatureOptions');
        if (f.feature_type === 'orbit') {
          options.icon = '/assets/images/map/orbit_pin.png';
        } else if (f.feature_type === 'panorama') {
          options.icon = '/assets/images/map/pano_pin.png';
        } else {
          options.icon = '/assets/images/pilot/point_of_interest_pin.svg';
        }
        return _this.map.data.overrideStyle(f.object, options);
      });
    },
    loadImagePins() {
      const markers = [];
      const total = this.get('images.length');
      if (total && total > 0) {
        this.get('images').forEach(image => {
          if (image.gps_latitude && image.gps_longitude && image.gps_latitude !== '0' && image.gps_longitude !== '0') {
            const infowindow = new google.maps.InfoWindow({
              content: `<div class='image-tooltip'>${image.showName}</div>`,
              maxWidth: 250,
              pixelOffset: new google.maps.Size(0, -15)
            });
            const marker = new google.maps.Marker({
              icon: this.markerIcon(total),
              map: this.map,
              position: this.buildLatLng(image.gps_latitude, image.gps_longitude)
            });
            marker.addListener('mouseover', () => {
              infowindow.open(this.map, marker);
              return setTimeout(() => document.getElementsByClassName('gm-ui-hover-effect')[0].remove(), 0);
            });
            marker.addListener('mouseout', () => {
              return setTimeout(() => {
                return infowindow.close(this.map, marker);
              }, 300);
            });
            return markers.push(marker);
          }
        });
      }
      this.set('markers', markers);
      return this.fitAllFeatures();
    },
    markerIcon(total) {
      if (total < 250) {
        return {
          url: '/assets/images/coverage-check/gps-dot.svg',
          scaledSize: new google.maps.Size(12, 12),
          origin: new google.maps.Point(0, 0)
        };
      } else {
        return {
          url: '/assets/images/coverage-check/gps-dot.svg',
          scaledSize: new google.maps.Size(12, 12)
        };
      }
    },
    fitAllFeatures() {
      const bounds = new google.maps.LatLngBounds();
      if (this.get('mission.map_features.length')) {
        this.get('mission.map_features').forEach((_this => function (feature) {
          if (feature.geometry.type === 'Polygon') {
            return feature.geometry.coordinates.forEach(path => path.forEach(latLng => bounds.extend(_this.buildLatLng(latLng[1], latLng[0]))));
          } else if (feature.geometry.type === 'Point') {
            const latLng = feature.geometry.coordinates;
            return bounds.extend(_this.buildLatLng(latLng[1], latLng[0]));
          } else if (feature.geometry.type === 'LineString') {
            return feature.geometry.coordinates.forEach(latLng => bounds.extend(_this.buildLatLng(latLng[1], latLng[0])));
          }
        })(this));
      }
      this.fitAllMarkers(bounds);
      if (!bounds.isEmpty()) {
        return this.map.fitBounds(bounds);
      }
    },
    fitAllMarkers(bounds) {
      if (this.get('markers')) {
        return this.get('markers').forEach(marker => bounds.extend(marker.position));
      }
    },
    buildLatLng(lat, lng) {
      lat = Number(lat);
      lng = Number(lng);
      return new google.maps.LatLng({
        lat,
        lng
      });
    },
    processingObserver: Ember.observer('processed', function () {
      if (this.get('processed')) {
        return this.loadImagePins();
      }
    }),
    actions: {
      enable_feature(feature) {
        return this.enableFeature(feature);
      },
      toggleClientNotes(event) {
        this.set('clientNotesVisible', !this.get('clientNotesVisible'));
        return $('.client-note').removeClass('highlight');
      },
      downloadKML() {
        let blob;
        let kml = tokml(this.geoJsonConverter.toGeoJson(this.get('mission.map_features')));
        kml = kml.substring(kml.indexOf(">") + 1);
        try {
          blob = new Blob([kml], {
            type: 'application/vnd.google-earth.kml+xml'
          });
        } catch (error) {
          window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder || window.MSBlobBuilder;
          if (error.name === 'TypeError' && window.BlobBuilder) {
            const bb = new BlobBuilder();
            bb.append(kml);
            blob = bb.getBlob("application/vnd.google-earth.kml+xml");
          } else if (e.name === 'InvalidStateError') {
            blob = new Blob([kml], {
              type: 'application/vnd.google-earth.kml+xml'
            });
          } else {
            alert('Downloading is not supported on your device.');
          }
        }
        let filename = this.get('mission.id') + '_' + this.get('mission.location.address') + '_' + this.get('mission.location.city') + '_' + this.get('mission.location.state') + '_' + '.kml';
        filename = filename.split(' ').join('_');
        return this.saveFileAs(filename, blob, 'application/vnd.google-earth.kml+xml');
      }
    }
  });
  _exports.default = _default;
});
define("pilots/components/client-mission-uploader", ["exports", "jquery", "pilots/config/environment", "pilots/components/asset-uploader"], function (_exports, _jquery, _environment, _assetUploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _assetUploader.default.extend({
    sessionAccount: Ember.inject.service(),
    store: Ember.inject.service(),
    lastShotListWidth: 0,
    lastAssetLeft: 0,
    showUnclassifiedAssetsModal: false,
    currentImages: Ember.computed('currentShot', 'mission.images', 'unassignedImages', function () {
      let currentImages;
      if (this.get('currentShot') === 'allAssets') {
        currentImages = this.mission.images;
      } else if (this.get('currentShot') === 'unassignedAssets') {
        currentImages = this.unassignedImages;
      } else {
        currentImages = this.get('currentShot').images;
      }
      return (currentImages || []).sortBy('uploadNumber');
    }),
    currentVideos: Ember.computed('currentShot', 'mission.videos', 'mission.unassignedVideos', function () {
      let currentVideos;
      if (this.get('currentShot') === 'allAssets') {
        currentVideos = this.get('mission').videos;
      } else if (this.get('currentShot') === 'unassignedAssets') {
        currentVideos = this.get('mission').unassignedVideos;
      } else {
        currentVideos = this.get('currentShot').videos;
      }
      return (currentVideos || []).sortBy('uploadNumber');
    }),
    currentAssets: Ember.computed.union('currentImages', 'currentVideos'),
    showInstructions: Ember.computed('currentAssets.length', function () {
      return this.get('currentAssets.length') === 0;
    }),
    readyToSubmit: Ember.computed('mission.pilotShots.@each.readyToSubmit', 'unassignedAssetsCount', function () {
      let ready = true;
      this.get('mission.pilotShots').forEach(shot => ready = ready && shot.get('readyToSubmit'));
      return ready && this.get('unassignedAssetsCount') === 0;
    }),
    unassignedImages: Ember.computed('mission.images.[]', 'mission.images.@each.shot', function () {
      return this.get('mission.images').reduce(function (acc, image) {
        if (image.get('shot.id')) {
          return acc;
        } else {
          acc.push(image);
          return acc;
        }
      }, []);
    }),
    hasUnassignedMissingGpsInfo: Ember.computed('unassignedMissingGpsCount', function () {
      return this.get('unassignedMissingGpsCount') > 0;
    }),
    unassignedMissingGpsCount: Ember.computed('unassignedImagesMissingGpsInfo', function () {
      return this.get('unassignedImagesMissingGpsInfo').length;
    }),
    unassignedImagesMissingGpsInfo: Ember.computed.filterBy('unassignedImages', 'isMissingGpsInfo', true),
    unassignedAssetsCount: Ember.computed('mission.unassignedVideos.[]', 'unassignedImages.[]', function () {
      return this.get('mission.unassignedVideos.length') + this.get('unassignedImages.length');
    }),
    failedUploads: Ember.computed('queue.failedUploads', function () {
      return this.get('queue.failedUploads');
    }),
    failedUploadsCount: Ember.computed('failedUploads', function () {
      return (this.get('failedUploads') || []).length;
    }),
    hasFailedUploads: Ember.computed('failedUploadsCount', function () {
      return this.get('failedUploadsCount') > 0;
    }),
    hasMissingGpsInfo: Ember.computed('imagesMissingGpsInfoCount', function () {
      return this.get('imagesMissingGpsInfoCount') > 0;
    }),
    imagesMissingGpsInfoCount: Ember.computed('imagesMissingGpsInfo', function () {
      return this.get('imagesMissingGpsInfo').length;
    }),
    imagesMissingGpsInfo: Ember.computed.filterBy('mission.images', 'isMissingGpsInfo', true),
    didInsertElement() {
      this._super();
      return this.initShotListScroll();
    },
    cleanup: Ember.on('willDestroyElement', function () {
      (0, _jquery.default)(window).off('scroll');
      Ember.run.cancel(this._addShot);
      return Ember.run.cancel(this._deselectAll);
    }),
    initShotListScroll() {
      const win = (0, _jquery.default)(window);
      const shotList = (0, _jquery.default)('.shot-list');
      const assets = (0, _jquery.default)('.assets');
      return win.scroll(() => {
        if (win.scrollTop() > assets.offset().top - 20) {
          if (this.get('lastShotListWidth') === 0) {
            this.set('lastShotListWidth', shotList.width());
            this.set('lastAssetLeft', assets.offset().left);
          }
          shotList.css({
            position: 'fixed',
            top: '20px',
            width: this.get('lastShotListWidth')
          });
          return assets.css({
            marginLeft: this.get('lastShotListWidth') + 4
          });
        } else {
          this.set('lastShotListWidth', 0);
          this.set('lastAssetLeft', 0);
          shotList.css({
            position: 'relative',
            top: ''
          });
          return assets.css({
            marginLeft: ''
          });
        }
      });
    },
    selectedAssets() {
      const selected = [];
      for (let asset of Array.from(this.get('currentAssets'))) {
        if (asset.get('isSelected')) {
          selected.push(asset);
        }
      }
      this.set('selectedCount', selected.length);
      return selected;
    },
    mouseDown(e) {
      const assetWrapperArr = (0, _jquery.default)(e.target).closest('.asset-wrapper');
      if (assetWrapperArr.length === 0) {
        return;
      }
      const assetId = (0, _jquery.default)(assetWrapperArr[0]).data('asset-id');
      if (!assetId || assetId === '') {
        return;
      }
      const asset = this.get('mission.allAssets').filterBy('id', assetId);
      const previouslySelectedAssetId = this.get('previouslySelectedAssetId');
      if (e.shiftKey && previouslySelectedAssetId && assetId !== previouslySelectedAssetId) {
        this.toggleRange(previouslySelectedAssetId, assetId);
      } else {
        this.toggleElementSelection(asset[0]);
      }
      return this.set('previouslySelectedAssetId', assetId);
    },
    // Iterate through all of the currently displayed assets and select the ones
    // in between assetId1 and assetId2
    toggleRange(assetId1, assetId2) {
      let inSelectedRange = false;
      for (let asset of Array.from(this.get('currentAssets'))) {
        if (asset.id === assetId1 || asset.id === assetId2) {
          inSelectedRange = !inSelectedRange;
          asset.set('isSelected', true);
        }
        if (inSelectedRange) {
          asset.set('isSelected', true);
        }
      }
      return this.selectedAssets();
    },
    toggleElementSelection(asset) {
      if (!asset) {
        return;
      }
      if (asset.get('isSelected')) {
        asset.set('isSelected', false);
      } else {
        asset.set('isSelected', true);
      }
      return this.selectedAssets();
    },
    deselectAll() {
      for (let asset of Array.from(this.get('mission.allAssets'))) {
        asset.set('isSelected', false);
      }
      return this.selectedAssets();
    },
    selectShot(shot) {
      let name;
      this.set('currentShot', shot);
      if (this.get('currentAssets').length > 100) {
        this.set('listView', true);
      }
      if (shot === 'unassignedAssets') {
        this.set('unassignedAssets', true);
      } else {
        this.set('unassignedAssets', false);
      }
      (0, _jquery.default)('.shot').removeClass('selected');
      if (shot.id) {
        name = 'shot-' + shot.id;
        this.set('shot_id', shot.id);
        (0, _jquery.default)('#shot-comment').css('display', 'block');
      } else {
        name = shot;
        (0, _jquery.default)('#shot-comment').css('display', 'none');
        this.set('shot_id', null);
      }
      (0, _jquery.default)('#' + name).addClass('selected');
      return this.selectedAssets();
    },
    addAsset(file) {
      // Move to assets_uploaded when the first asset is uploaded
      if (this.get('mission.status') !== 'assets_uploaded') {
        this.updateStatus('assets_uploaded');
      }
      const fileType = file.get('type').match(/video|image/)[0];
      const asset = this.get('store').createRecord(fileType, {
        id: file.get('id'),
        // set temp id so we can lookup in call back later.
        name: file.get('sanitizedName'),
        processing: true,
        generatingNativeThumbnail: this.get('showNativeThumbnail'),
        file,
        uploadNumber: file.uploadNumber
      });
      const mission = this.get('mission');
      if (this.get('shot_id')) {
        asset.set('shot', mission.get('shots').findBy('id', this.get('shot_id')));
        file.set("shot_id", this.get('shot_id'));
      }
      mission.get(fileType + 's').unshiftObject(asset);
      file.set("mission_number", mission.id);
      this.set('file_type', fileType);
      file.set("authHeaders", this.get('sessionAccount.headers'));
      return this._super(file, null, mission);
    },
    init() {
      this._super();
      this.set('currentShot', 'unassignedAssets');
      this.set('unassignedAssets', true);
      this.set('commentButtonName', 'Save comment');
      if (this.get('currentAssets').length > 100) {
        return this.set('listView', true);
      }
    },
    actions: {
      addAssetToShot(file, shot_id) {
        this.set('shot_id', shot_id);
        return this.addAsset(file);
      },
      hideUnclassifiedAssetsModal() {
        return this.set('showUnclassifiedAssetsModal', false);
      },
      toggleModal() {
        return this.sendAction('toggleModal');
      },
      deselectAll() {
        return this.deselectAll();
      },
      assignAsset(shot, _asset) {
        const assets = this.selectedAssets();
        // shot = options.target.shot
        if (!assets.includes(_asset)) {
          assets.push(_asset);
        }
        for (let asset of Array.from(assets)) {
          asset.set('shot', shot);
          if (asset.get('isNew')) {
            if (asset.get('hasProperId') && asset.get('mission.id')) {
              asset.saveAsUpdate();
            } else {}
            // will save this record once we have id in xhrAPISuccessResponse
          } else {
            asset.save();
          }
        }
        this.deselectAll();
        return this.selectShot(shot);
      },
      clickShot(shot) {
        return this.selectShot(shot);
      },
      clickDraggable(asset) {
        return this.toggleElementSelection(asset);
      },
      saveComment() {
        const _this = this;
        this.set('commentButtonName', 'Saving...');
        return this.get('currentShot').save().then(function () {
          _this.set('commentButtonName', 'Saved');
          return setTimeout(function () {
            _this.set('commentButtonName', 'Save comment');
          }, 4000);
        }, () => alert('There was an issue saving this comment'));
      },
      doneClassifying() {
        if (this.get('queue.uploader.total.queued') !== 0) {
          alert('Please wait for uploads to finish.');
          return;
        }
        if (!this.get('readyToSubmit')) {
          this.set('showUnclassifiedAssetsModal', true);
          (0, _jquery.default)('.shot-list').removeClass('hide-notready');
          return;
        }
        return this.doneClassifyingAction();
      },
      deleteShot(shot) {
        if (shot.get('images.length') || shot.get('videos.length')) {
          alert('Please remove assets from shot first.');
          return;
        }
        if (!confirm('Do you want to delete this shot?')) {
          return;
        }
        return shot.destroyRecord().then(() => {
          this.get('mission.pilotShots').removeObject(shot);
          this.set('currentShot', 'unassignedAssets');
          return this.set('unassignedAssets', true);
        });
      },
      deleteAsset(asset) {
        if (window.confirm('Are you sure? This will permanently remove this asset.')) {
          this.deselectAll();
          this.get("queue").notifyPropertyChange('failedUploads');
          return asset.destroyRecord().then(() => window.location.reload(true), () => alert('There was an issue deleting this asset'));
        }
      },
      deleteAssets() {
        if (!confirm('Do you want to delete the selected assets?')) {
          return;
        }
        const targets = [];
        this.selectedAssets().forEach(function (asset) {
          let type;
          if (asset._internalModel.modelName.includes('video')) {
            type = 'video';
          } else if (asset._internalModel.modelName.includes('image')) {
            type = 'image';
          } else {
            return;
          }
          const item = {
            type,
            id: asset.get('id')
          };
          targets.push(item);
          asset.deleteRecord();
        });
        return _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/pilots/missions/${this.get('mission.id')}/assets/delete_all`,
          type: 'POST',
          dataType: 'json',
          headers: this.get('sessionAccount.headers'),
          data: {
            targets
          }
        }).then(() => window.location.reload(true), () => alert('Sorry, there was an error while deleting these assets.'));
      },
      toggleListView() {
        this.set('listView', !this.get('listView'));
        return this.toggleProperty('showNativeThumbnail');
      },
      toggleBulkUploaderModal() {
        if (this.get('unassignedImages').length > 0) {
          this.set('showUnclassifiedAssetsModal', true);
          (0, _jquery.default)('.shot-list').removeClass('hide-notready');
          return;
        }
        this.set('showNativeThumbnail', false);
        this.set('listView', true);
        this.get("queue").notifyPropertyChange('failedUploads');
        if (this.get('bulkUploadModal')) {
          return this.get('mission').reload().then(() => {
            return this.set('bulkUploadModal', false);
          });
        } else {
          return this.set('bulkUploadModal', true);
        }
      }
    }
  });
  _exports.default = _default;
});
define("pilots/components/simple-weather", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  ;
  ;
  ;
  var SimpleWeatherComponent;
  SimpleWeatherComponent = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    classNames: ['weather'],
    initWeather: Ember.on('init', function () {
      var _this, latitude, longitude;
      _this = this;
      longitude = this.get('pilot').get('longitude') ? this.get('pilot').get('longitude') : 118.496475;
      latitude = this.get('pilot').get('latitude') ? this.get('pilot').get('latitude') : 34.0195;
      return _jquery.default.ajax({
        url: _environment.default.api.host + "/v1/pilots/weather?latitude=" + this.get('pilot').get('latitude') + "&longitude=" + this.get('pilot').get('longitude'),
        type: 'GET',
        dataType: 'json',
        headers: _this.get('sessionAccount.headers')
      }).then(function (response) {
        if (response.error) {
          return;
        }
        _this.set('degree', Math.round(response.data.temperature));
        return _this.set('icon', response.data.icon);
      });
    })
  });
  var _default = SimpleWeatherComponent;
  _exports.default = _default;
});
define("pilots/components/reschedule-success-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RescheduleSuccessModalComponent;
  RescheduleSuccessModalComponent = Ember.Component.extend({
    actions: {
      toggleModal: function () {
        return this.close();
      }
    }
  });
  var _default = RescheduleSuccessModalComponent;
  _exports.default = _default;
});
define("pilots/routes/clientmissionwebview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    tagName: '',
    sessionAccount: Ember.inject.service(),
    session: Ember.inject.service(),
    model(params) {
      const urlParams = new URL(window.location).searchParams;
      const source = urlParams.get('source');
      this.controllerFor('application').set('source', source);
      const tokens = this.sessionAccount.getAuthTokens(urlParams);
      if (Ember.isPresent(tokens.password)) {
        return this.get('session').authenticate('authenticator:devise', tokens.email, tokens.password).then(() => {
          this.sessionAccount.setAuth0Tokens(tokens);
          console.log('authed');
          return Ember.RSVP.hash({
            source,
            pilotDrones: this.store.findAll('pilot-drone'),
            pilotEquipment: this.store.findAll('pilot-pilot-equipment'),
            pilotDevices: this.store.findAll('pilot-device'),
            pilot: this.store.findRecord('pilot', 'stubId'),
            missionRescheduleReasons: this.store.query('reschedule-reason', {}),
            mission: this.store.find('mission', params.mission_id).then(response => response, error => {
              console.log('error loading ClientMissionRoute: ', error);
              return this.replaceWith('fourOhFour', {});
            })
          });
        });
      }
      console.log('no auth tokens');
      return this.replaceWith('fourOhFour', {});
    },
    setupController(controller, model) {
      this._super(controller, model);
      return this.controllerFor('application').set('hideNav', true);
    },
    actions: {
      update(mission) {
        return mission.save();
      },
      goToUpload() {
        if (this.controllerFor('application').get('source') === 'android') {
          return android.goToUpload(); // jshint ignore:line
        } else if (this.controllerFor('application').get('source') === 'ios') {
          return window.webkit.messageHandlers.navigation.postMessage("upload");
        }
      },
      startLAANCAuth() {
        if (this.controllerFor('application').get('source') === 'android') {
          return android.startLAANCAuth(); // jshint ignore:line
        } else if (this.controllerFor('application').get('source') === 'ios') {
          return window.webkit.messageHandlers.auth.postMessage("laanc");
        }
      },
      openMaps() {
        if (this.controllerFor('application').get('source') === 'android') {
          return android.openMaps(); // jshint ignore:line
        } else if (this.controllerFor('application').get('source') === 'ios') {
          return window.webkit.messageHandlers.navigation.postMessage("openMaps");
        }
      }
    }
  });
  _exports.default = _default;
});
define("pilots/templates/components/pilot-profile-mission-preferences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cHdHRvUj",
    "block": "[[[10,0],[14,0,\"section-header\"],[12],[1,\"\\n  MISSION PREFERENCES\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"item-row\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"field-name wide\"],[12],[1,\"MILES WILLING TO TRAVEL\"],[13],[10,1],[14,0,\"field-spacer\"],[12],[13],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"propertyName\",\"changeset\",\"placeholder\",\"type\"],[\"travel_distance\",[33,1],\"Travel Distance\",\"text\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"item-row\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"field-name\"],[12],[1,\"AVAILABILITY\"],[13],[10,1],[14,0,\"field-spacer\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"availability\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"propertyName\",\"changeset\"],[\"is_available_weekdays\",[33,1]]]]],[1,\" Weekdays\\n    \"],[1,[28,[35,2],null,[[\"propertyName\",\"changeset\"],[\"is_available_weekends\",[33,1]]]]],[1,\" Weekends\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"validated-input-auto-save\",\"changeset\",\"validated-checkbox-image-auto-save\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-mission-preferences.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/mirage/factories/drone-camera", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*jshint esversion: 6 */
  var _default = _emberCliMirage.Factory.extend({
    name: 'Flash Camera',
    stock: true
  });
  _exports.default = _default;
});
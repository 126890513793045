define("pilots/models/drone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Drone;
  Drone = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    optional_cameras: _emberData.default.hasMany('stock-drone-camera', {
      async: false
    }),
    stock_cameras: _emberData.default.hasMany('optional-drone-camera', {
      async: false
    }),
    drone_manufacturer: _emberData.default.belongsTo('drone-manufacturer'),
    full_name: Ember.computed('name', 'drone_manufacturer', function () {
      return this.get('drone_manufacturer.name') + ' ' + this.get('name');
    })
  });
  var _default = Drone;
  _exports.default = _default;
});
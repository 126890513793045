define("pilots/helpers/is-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IsInHelper,
    indexOf = [].indexOf || function (item) {
      for (var i = 0, l = this.length; i < l; i++) {
        if (i in this && this[i] === item) return i;
      }
      return -1;
    };
  IsInHelper = Ember.Helper.helper(function (arg) {
    var list, value;
    value = arg[0], list = arg[1];
    return indexOf.call(list.split(','), value) >= 0;
  });
  var _default = IsInHelper;
  _exports.default = _default;
});
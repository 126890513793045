define("pilots/components/pilot-profile-equipment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileEquipmentComponent;
  PilotProfileEquipmentComponent = Ember.Component.extend({
    classNames: ['profile-component'],
    store: Ember.inject.service(),
    equipmentAvailable: Ember.computed('model.pilotEquipment.[]', function () {
      return this.get('model.pilotEquipment.length') < this.get('model.equipment.length');
    }),
    missingInfo: Ember.computed('model.pilotEquipment.[]', function () {
      return this.get('model.pilotEquipment.length') === 0;
    }),
    actions: {
      createEquipment: function (pilot_equipment) {
        var pilotEquipment;
        pilotEquipment = this.get('store').createRecord('pilot-pilot-equipment', {
          pilot_equipment: pilot_equipment,
          pilot: this.get('model.pilot')
        });
        return pilotEquipment.save();
      },
      destroyEquipment: function (pilot_equipment) {
        return pilot_equipment.destroyRecord().then(function (_this) {
          return function () {
            return _this.get('model.pilot').reload();
          };
        }(this));
      }
    }
  });
  var _default = PilotProfileEquipmentComponent;
  _exports.default = _default;
});
define("pilots/templates/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/Af8hsyu",
    "block": "[[[10,0],[14,0,\"four-oh-four-container text-center\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"Not Found\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Sorry, the page you are looking for does not exist. Please check\\n  the address and try again.\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "pilots/templates/four-oh-four.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/utils/trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var rtrim, trim;
  trim = String.prototype.trim ? function (string) {
    return (string || '').trim();
  } : (rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, function (string) {
    return (string || '').replace(rtrim, '');
  });
  var _default = trim;
  _exports.default = _default;
});
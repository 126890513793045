define("pilots/helpers/not-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function () {
    const [leftSide, rightSide] = Array.from(arguments.length <= 0 ? undefined : arguments[0]);
    return leftSide !== rightSide;
  });
  _exports.default = _default;
});
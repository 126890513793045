define("pilots/templates/components/pill-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gW92MBqu",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[41,[28,[37,4],[[30,1],true],null],[[[1,\"    \"],[10,\"i\"],[14,0,\"fa fa-circle selected\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"i\"],[14,0,\"fa fa-circle\"],[12],[13],[1,\"\\n\"]],[]]]],[1]],null]],[\"a\"],false,[\"each\",\"-track-array\",\"pillsHash\",\"if\",\"is-equal\"]]",
    "moduleName": "pilots/templates/components/pill-list.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
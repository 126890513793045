define("pilots/components/pilot-form", ["exports", "pilots/config/environment", "pilots/validations/pilot", "ember-changeset-validations", "ember-changeset"], function (_exports, _environment, _pilot, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_pilot.default, {
    tagName: 'form',
    role: 'form',
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    buttonName: 'Register',
    registrationCompleted: false,
    initChangeSet: Ember.on('init', function () {
      return this.changeset = new _emberChangeset.default(this.get('pilot'), (0, _emberChangesetValidations.default)(_pilot.default), _pilot.default);
    }),
    actions: {
      save: function (event) {
        event.preventDefault();
        this.set('buttonName', 'Registering...');
        this.changeset.validate().then(() => {
          if (this.changeset.get('isInvalid')) {
            this.set('buttonName', 'Register');
          } else {
            this.changeset.save().then(() => {
              this.get('metrics').trackEvent({
                event: 'event',
                category: 'Users',
                action: 'submit',
                label: 'Registration Completed'
              });
              if (_environment.default.environment === 'production') {
                Cookies.set('registrationCompleted', 'true');
              }
              this.set('buttonName', 'Registered');
              this.set('registrationCompleted', true);
            }, () => {
              this.get('pilot.errors').forEach(arg => {
                const attribute = arg.attribute;
                const message = arg.message;
                this.changeset.pushErrors(attribute, message);
                this.set('buttonName', 'Register');
              });
            });
          }
        });
      }
    }
  });
  _exports.default = _default;
});
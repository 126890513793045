define("pilots/templates/pilotlog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "M4NhHGk0",
    "block": "[[[10,0],[14,0,\"pilotlog\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"pilot\"],[[33,1,[\"pilot\"]]]]]],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"hidden-xs\"],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"model\",\"class\",\"pilotlog\"],[[33,1],\"col-sm-9\",true]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"pilot\",\"licenses\"],[[33,1,[\"pilot\"]],[33,1,[\"pilotLicenses\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"list-container\"],[12],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"content\"],[[33,1,[\"missions\"]]]]]],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"model\"],[[33,1]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"dashboard-header\",\"model\",\"dashboard-map\",\"pilot-details\",\"page-numbers\",\"pilot-log\"]]",
    "moduleName": "pilots/templates/pilotlog.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
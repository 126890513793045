define("pilots/components/pilot-profile-licenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    licensesAvailable: Ember.computed(function () {
      return true;
    }),
    availableLicenses: Ember.computed('model.licenses', function () {
      let licenses = this.get('model.licenses');
      if (!licenses) {
        return [];
      }
      let available = licenses.filter(license => license.issuer_type); // hacky way to ensure we only get licenses shown in options

      return available.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }),
    actions: {
      newLicense() {
        this.set('isModalOpen', true);
      },
      closeModal() {
        this.set('isModalOpen', false);
      },
      createLicense(license) {
        let newLicense = this.get('store').createRecord('pilot-license', {
          license: license,
          pilot: this.get('model.pilot')
        });
        newLicense.save().then(() => {
          this.get('model.pilotLicenses').pushObject(newLicense);
          this.send('closeModal');
        });
      },
      destroyLicense(license) {
        license.destroyRecord().then(() => {
          this.get('model.pilot').reload();
        });
      }
    }
  });
  _exports.default = _default;
});
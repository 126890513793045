define("pilots/components/pilot-profile-drone-system-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileDroneSystemNewComponent;
  PilotProfileDroneSystemNewComponent = Ember.Component.extend({
    pilotDroneNumber: Ember.computed('model.pilotDrones.length', {
      get: function () {
        return this.get('model.pilotDrones.length') + 1;
      },
      set: function (key, value) {
        return this._pilotDroneNumber = value;
      }
    }),
    observePilotDroneLength: Ember.observer('model.pilotDrones.length', function () {
      return this.set('pilotDroneNumber', this.get('model.pilotDrones.length') + 1);
    }),
    cta: Ember.computed('model.pilotDrones.length', function () {
      if (this.get('model.pilotDrones.length') > 0) {
        return 'Add Another Drone';
      } else {
        return 'Add a Drone';
      }
    }),
    didInsertElement: function () {
      return $('.select-drone').on('click', function () {
        event.stopPropagation();
        $('.drone-dropdown').toggle();
        return $('html').one('click', function () {
          return $('.drone-dropdown').hide();
        });
      });
    },
    actions: {
      create: function (drone) {
        $('.drone-dropdown').toggle();
        return this.create(drone);
      }
    }
  });
  var _default = PilotProfileDroneSystemNewComponent;
  _exports.default = _default;
});
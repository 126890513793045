define("pilots/components/pilot-mission-information", ["exports", "pilots/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotMissionInformation;
  PilotMissionInformation = Ember.Component.extend({
    classNames: ['mission-list-data'],
    actions: {
      confirmDeclined: function (mission) {
        this.set('declineModal', false);
        return this.deleteAction(mission);
      },
      decline: function () {
        return this.set('declineModal', true);
      },
      closeDeclinedModal: function () {
        return this.set('declineModal', false);
      }
    }
  });
  var _default = PilotMissionInformation;
  _exports.default = _default;
});
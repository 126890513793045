define("pilots/validations/getty", ["exports", "ember-changeset-validations/validators", "pilots/validations/custom_validators/validateNumCommaSeparated"], function (_exports, _validators, _validateNumCommaSeparated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'fileInput': (0, _validators.validatePresence)({
      presence: true,
      message: 'Please select a file'
    }),
    'category': (0, _validators.validateNumber)({
      gte: 0,
      message: 'Please select country'
    }),
    'description': [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 20,
      message: 'Please enter at least 20 characters'
    })],
    'city': (0, _validators.validatePresence)({
      presence: true,
      message: 'Please enter city'
    }),
    'state': (0, _validators.validatePresence)({
      presence: true,
      message: 'Please enter state'
    }),
    'country': (0, _validators.validatePresence)({
      presence: true,
      message: 'Please select country'
    }),
    'timecode': (0, _validators.validateFormat)({
      allowBlank: true,
      regex: /^(([0-9]){2}:([0-9]){2})$|^([0-9]){2}$|^(([0-9]){1}:([0-9]){2})$/,
      message: 'Timecode format: hh:mm'
    }),
    'tags': (0, _validateNumCommaSeparated.default)(5, 45, 'Please enter between 5 and 45 comma-separated tags')
  };
  _exports.default = _default;
});
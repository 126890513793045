define("pilots/templates/components/pilot-profile-equipment-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "r6YN3tTk",
    "block": "[[[10,0],[14,0,\"item-row equipment\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"field-name new\"],[12],[1,\"EQUIPMENT \"],[1,[34,0]],[13],[1,\"\\n  \"],[10,1],[14,0,\"field-spacer\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"field-value select-equipment\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"field-value-empty\"],[12],[1,\"Choose Equipment Type\"],[13],[1,\"\\n    \"],[10,1],[14,0,\"field-arrow icon-Arrow2_down\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"equipment-dropdown\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"      \"],[11,\"li\"],[4,[38,4],[[30,0],\"create\",[30,1]],null],[12],[1,\"\\n        \"],[10,1],[14,0,\"item-creator\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"equipment\"],false,[\"pilotEquipmentNumber\",\"each\",\"-track-array\",\"availableEquipment\",\"action\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-equipment-new.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
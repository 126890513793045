define("pilots/templates/components/uploading-asset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yhqRUiXi",
    "block": "[[[10,0],[14,0,\"upload-file shot-asset\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"processing-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"asset\"],[15,5,[29,[\"background-image:url(\",[33,0,[\"thumbnail\"]],\")\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"filename\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"name\"],[12],[1,\"\\n\"],[41,[33,0,[\"error\"]],[[[1,\"        \"],[10,\"i\"],[14,0,\"failed-upload fa fa-exclamation-triangle\"],[12],[13],[1,\" \"],[1,[33,0,[\"sanitizedName\"]]],[1,\" \"],[10,\"b\"],[12],[1,\"There was an error uploading this file. Please remove and try again.\"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[33,0,[\"sanitizedName\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[11,3],[24,0,\"delete-asset\"],[4,[38,2],[[30,0],\"removeAsset\",[33,3,[\"uploader\"]],[33,0]],null],[12],[10,\"i\"],[14,0,\"icon-Xmark\"],[12],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"file\",\"if\",\"action\",\"queue\"]]",
    "moduleName": "pilots/templates/components/uploading-asset.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
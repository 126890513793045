define("pilots/adapters/application", ["exports", "ember-inflector", "ember-data", "pilots/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberInflector, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ApplicationAdapter = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    headers: Ember.computed('session.data.authenticated.{accessToken,idToken,token,email}', 'session.isAuthenticated', function () {
      let headers = {};
      let {
        accessToken,
        idToken,
        token,
        email
      } = this.get('session.data.authenticated');
      if (this.session.isAuthenticated) {
        if (accessToken) {
          headers['Authorization'] = `Bearer ${accessToken}`;
          headers['X-OAuth-User-ID-Token'] = idToken;
        } else {
          headers['Authorization'] = `Token token=\"${token}\", email=\"${email}\"`;
        }
      } else {
        this.session.invalidate();
      }
      return headers;
    }),
    authorize() {},
    shouldReloadRecord() {
      return true;
    },
    shouldReloadAll() {
      return true;
    },
    namespace: _environment.default.api.namespace,
    host: _environment.default.api.host,
    pathForType(type) {
      const underscored = Ember.String.underscore(type);
      return (0, _emberInflector.pluralize)(underscored);
    }
  });
  var _default = ApplicationAdapter;
  _exports.default = _default;
});
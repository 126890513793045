define("pilots/routes/work-orders/show", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    store: Ember.inject.service(),
    model(params) {
      return this.get('store').findRecord('work-order', params.id);
    }
  });
  _exports.default = _default;
});
define("pilots/helpers/starts-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var StartsWithHelper;
  StartsWithHelper = Ember.Helper.helper(function (arg) {
    var prefix, string;
    string = arg[0], prefix = arg[1];
    if (typeof string !== 'string' || typeof prefix !== 'string') {
      return false;
    }
    return string.startsWith(prefix);
  });
  var _default = StartsWithHelper;
  _exports.default = _default;
});
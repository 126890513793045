define("pilots/validations/custom_validators/validateNumCommaSeparated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateNumCommaSeparated;
  function validateNumCommaSeparated(min, max, message) {
    return (key, tags) => {
      let trimmed = tags.trim();
      let numItems = 0;
      let items = trimmed.split(',');
      for (let i = 0; i < items.length; i++) {
        if (items[i].trim().length > 0) {
          numItems++;
        }
      }
      if (numItems <= max && numItems >= min) {
        return true;
      }
      return message;
    };
  }
});
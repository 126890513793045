define("pilots/models/drone-camera", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    stock: _emberData.default.attr('boolean'),
    drone_manufacturer: _emberData.default.belongsTo('drone-manufacturer')
  });
  _exports.default = _default;
});
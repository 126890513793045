define("pilots/templates/signup-flow-certification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yFElVIQx",
    "block": "[[[10,0],[14,0,\"signup-page-2\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/logo_nav_white.svg\"],[14,0,\"logo hidden-xs\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"sign-up-flow-module\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[33,2],\"MAIN\"],null],[[[1,\"      \"],[1,[28,[35,3],null,[[\"step\"],[2]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"hidden-xs\"],[12],[1,\"\\n        \"],[1,[28,[35,3],null,[[\"step\"],[2]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,0],[14,0,\"main-body\"],[12],[1,\"\\n      \"],[1,[28,[35,4],null,[[\"model\",\"setCurrentPage\",\"toggleShowSkipModal\"],[[33,5],[28,[37,6],[[30,0],\"setCurrentPage\"],null],[28,[37,6],[[30,0],\"toggleShowSkipModal\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[33,7],[[[1,\"  \"],[1,[28,[35,8],null,[[\"model\",\"toggleShowSkipModal\",\"skip\"],[[33,5],[28,[37,6],[[30,0],\"toggleShowSkipModal\"],null],[28,[37,6],[[30,0],\"skipStep\"],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"is-equal\",\"currentPage\",\"signup-flow-steps\",\"signup-select-certification\",\"model\",\"action\",\"showSkipModal\",\"signup-flow-skip-modal\"]]",
    "moduleName": "pilots/templates/signup-flow-certification.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/helpers/is-ios", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IsiOS;
  IsiOS = Ember.Helper.helper(function () {
    var userAgent;
    userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  });
  var _default = IsiOS;
  _exports.default = _default;
});
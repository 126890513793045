define("pilots/templates/components/video-player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BvhkX4sk",
    "block": "[[[10,0],[14,0,\"video-player-container\"],[14,1,\"video-player-container\"],[15,\"onMouseEnter\",[28,[37,0],[[30,0],\"showVideoControls\"],null]],[15,\"onMouseLeave\",[28,[37,0],[[30,0],\"hideVideoControls\"],null]],[12],[1,\"\\n  \"],[11,\"video\"],[24,0,\"video-player\"],[16,\"muted\",[36,1]],[24,\"controlsList\",\"nodownload\"],[16,\"loop\",[36,2]],[16,\"controls\",[36,3]],[4,[38,0],[[30,0],\"togglePlayPause\"],null],[12],[1,\"\\n    \"],[10,\"source\"],[15,\"src\",[36,4]],[14,4,\"video/mp4\"],[12],[13],[1,\"\\n    Your browser does not support HTML5 video.\\n  \"],[13],[1,\"\\n\"],[41,[51,[33,3]],[[[1,\"  \"],[10,0],[14,0,\"play-hide\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/videoPlayer/play_button.svg\"],[14,0,\"play-pause-button play\"],[12],[13],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/videoPlayer/pause_button.svg\"],[14,0,\"play-pause-button pause\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"video-controls \",[52,[33,7],\"no-audio\"]]]],[12],[1,\"\\n\"],[41,[51,[33,7]],[[[1,\"      \"],[11,\"img\"],[24,\"src\",\"/assets/images/videoPlayer/no_audio_icon.svg\"],[24,0,\"video-control-button\"],[24,1,\"volume-on\"],[4,[38,0],[[30,0],\"toggleVolume\"],null],[12],[13],[1,\"\\n      \"],[11,\"img\"],[24,\"src\",\"/assets/images/videoPlayer/audio_icon.svg\"],[24,0,\"video-control-button\"],[24,1,\"muted\"],[4,[38,0],[[30,0],\"toggleVolume\"],null],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[11,\"img\"],[24,\"src\",\"/assets/images/videoPlayer/fullscreen_icon.svg\"],[24,0,\"video-control-button\"],[24,1,\"full-screen\"],[4,[38,0],[[30,0],\"fullScreen\"],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"action\",\"muted\",\"loop\",\"showDefaultControls\",\"videoSrc\",\"unless\",\"if\",\"hideAudio\"]]",
    "moduleName": "pilots/templates/components/video-player.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
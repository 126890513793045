define("pilots/controllers/work-orders/show", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service(),
    selectedMissions: [],
    missionsFilter: 'all',
    cyberduckCredentials: null,
    currentTab: 'instructions',
    displayMissions: Ember.computed('model.missions.[]', 'missionsFilter', function () {
      const filter = this.get('missionsFilter');
      const missions = this.get('model.missions');
      if (filter === 'all') {
        return missions;
      } else if (filter === 'in_progress') {
        return this.get('missionsInProgress');
      } else if (filter === 'complete') {
        return missions.filter(mission => mission.get('amsInProgress') === false);
      }
    }),
    missionsInProgress: Ember.computed('model.missions.[]', function () {
      return this.get('model.missions').filter(mission => mission.get('amsInProgress'));
    }),
    completeCount: Ember.computed('model.missions.[]', function () {
      return this.get('model.missions').filter(mission => mission.get('amsInProgress') === false).length;
    }),
    resetCheckboxes: function () {
      this.set('selectedMissions', []);
      (0, _jquery.default)('.work-order-missions input[type=checkbox]').prop('checked', false);
    },
    actions: {
      setTab: function (tab) {
        this.set('currentTab', tab);
      },
      setMissionsFilter: function (value) {
        this.set('missionsFilter', value);
        this.resetCheckboxes();
      },
      toggleAllMissions: function () {
        if (this.get('missionsFilter') === 'complete') {
          return;
        }
        const checked = (0, _jquery.default)('.master-checkbox').prop('checked');
        if (checked && this.get('selectedMissions.length') !== this.get('missionsInProgress.length')) {
          this.set('selectedMissions', this.get('missionsInProgress').map(mission => mission.id));
        } else {
          this.set('selectedMissions', []);
        }
        (0, _jquery.default)('.work-order-missions .active-checkbox').prop('checked', checked);
      },
      toggleSelectedMission: function (missionID) {
        if (this.get('selectedMissions').includes(missionID)) {
          this.get('selectedMissions').removeObject(missionID);
        } else {
          this.get('selectedMissions').pushObject(missionID);
        }
      },
      startUploading: function () {
        if (this.get('selectedMissions.length') === 0) {
          return;
        }
        const _this = this;
        _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/pilots/work_orders/${this.get('model.id')}/cyberduck`,
          method: 'POST',
          data: {
            ids: this.get('selectedMissions')
          },
          headers: this.get('sessionAccount.headers')
        }).then(function (result) {
          _this.set('cyberduckCredentials', result);
        }, function () {});
      }
    }
  });
  _exports.default = _default;
});
define("pilots/templates/components/signup-flow-skip-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ISoFH50z",
    "block": "[[[6,[39,0],null,[[\"translucentOverlay\",\"onClickOverlay\",\"containerClassNames\"],[true,[28,[37,1],[[30,0],\"toggleShowSkipModal\"],null],\"signup-flow-modal skip-modal\"]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"ember-modal-inner-wrap\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"modal-header\"],[12],[1,\"\\n      \"],[1,[33,2,[\"pilot\",\"first_name\"]]],[1,\", you won’t receive any missions until you complete your profile. Continue?\\n      \"],[11,0],[24,0,\"close-modal\"],[4,[38,1],[[30,0],\"toggleShowSkipModal\"],null],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/assets/images/x_mark.svg\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"modal-body\"],[12],[1,\"\\n      \"],[8,[39,3],[[4,[38,1],[[30,0],\"skip\"],null]],null,[[\"default\"],[[[[1,\"Skip For now\"]],[]]]]],[1,\"\\n      \"],[8,[39,3],[[4,[38,1],[[30,0],\"toggleShowSkipModal\"],null]],[[\"@type\"],[\"secondary\"]],[[\"default\"],[[[[1,\"Complete profile\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"modal-dialog\",\"action\",\"model\",\"button\"]]",
    "moduleName": "pilots/templates/components/signup-flow-skip-modal.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/controllers/application", ["exports", "pilots/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ApplicationController;
  ApplicationController = Ember.Controller.extend();
  var _default = ApplicationController;
  _exports.default = _default;
});
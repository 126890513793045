define("pilots/helpers/map-value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let mapValueHelper = Ember.Helper.helper(function (_ref) {
    let [itemMap, key] = _ref;
    return itemMap.get(key);
  });
  var _default = mapValueHelper;
  _exports.default = _default;
});
define("pilots/models/shot-type-sample-image", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    url: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    processing: _emberData.default.attr('boolean'),
    version_urls: _emberData.default.attr(),
    gps_latitude: _emberData.default.attr('number'),
    gps_longitude: _emberData.default.attr('number'),
    gps_altitude: _emberData.default.attr('number'),
    mission: _emberData.default.belongsTo('mission'),
    shot: _emberData.default.belongsTo('shot'),
    file: null,
    uploadNumber: null,
    isSelected: false,
    generatingNativeThumbnail: false,
    nativeThumbnail: null,
    uploaded: false,
    hasProperId: Ember.computed('id', function () {
      return this.get('id').indexOf('DB') > -1;
    })
  });
  _exports.default = _default;
});
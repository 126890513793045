define("pilots/templates/components/page-numbers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NBJSV3mg",
    "block": "[[[1,\"  \"],[10,0],[14,0,\"page-buttons\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"      \"],[11,3],[24,6,\"#\"],[24,0,\"arrow-enabled fa fa-angle-left\"],[4,[38,2],[[30,0],\"incrementPage\",-1],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,3],[24,6,\"#\"],[24,0,\"arrow-disabled fa fa-angle-left\"],[4,[38,2],[[30,0],\"incrementPage\",-1],null],[12],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[10,0],[12],[1,[34,3]],[13],[1,\" / \\n    \"],[10,0],[12],[1,[34,4]],[13],[1,\"\\n\"],[41,[33,5],[[[1,\"      \"],[11,3],[24,6,\"#\"],[24,0,\"arrow-enabled fa fa-angle-right\"],[4,[38,2],[[30,0],\"incrementPage\",1],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,3],[24,6,\"#\"],[24,0,\"arrow-disabled fa fa-angle-right\"],[4,[38,2],[[30,0],\"incrementPage\",1],null],[12],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[],false,[\"if\",\"canStepBackward\",\"action\",\"currentPage\",\"totalPages\",\"canStepForward\"]]",
    "moduleName": "pilots/templates/components/page-numbers.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
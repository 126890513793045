define("pilots/models/stock-drone-camera", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var StockDroneCamera;
  StockDroneCamera = _emberData.default.Model.extend({
    camera: _emberData.default.belongsTo('drone-camera')
  });
  var _default = StockDroneCamera;
  _exports.default = _default;
});
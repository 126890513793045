define("pilots/components/dashboard-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DashboardHeaderComponent;
  DashboardHeaderComponent = Ember.Component.extend({
    classNames: ['container-top dashboard-header-wrap']
  });
  var _default = DashboardHeaderComponent;
  _exports.default = _default;
});
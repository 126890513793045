define("pilots/templates/components/admin-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Lhderd3l",
    "block": "[[[10,0],[14,0,\"admin-status-line\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"admin-text-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"admin-text\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"text\"],[12],[1,\"\\n      You are logged in as\\n      \"],[10,1],[14,0,\"username\"],[12],[1,[34,0]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,3],[4,[38,1],[[30,0],\"adminLogout\"],null],[12],[1,\"Log Out\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"userName\",\"action\"]]",
    "moduleName": "pilots/templates/components/admin-notification.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/services/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NotificationsService;
  NotificationsService = Ember.Service.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service(),
    metrics: Ember.inject.service(),
    model: null,
    notificationCount: Ember.computed('model.@each.read_status', function () {
      if (this.get('model')) {
        return this.get('model').filterBy('read_status', 'unread').get('length');
      }
    }),
    init: function () {
      this._super();
      this.findAllNotifications();
      return this.checkForNewNotifications(300000);
    },
    findAllNotifications: function () {
      if (!this.get('sessionAccount.account')) {
        return;
      }
      return this.get('store').findAll('notification').then(function (_this) {
        return function (response) {
          var _reponse;
          _reponse = response.sortBy('created_at').reverseObjects();
          _this.set('model', _reponse);
          return _this.set('lastFetch', Math.floor(Date.now() / 1000));
        };
      }(this), function (response) {
        return console.log('error loading notifications');
      });
    },
    checkForNewNotifications: function (interval) {
      return setInterval(function (_this) {
        return function () {
          _this.findAllNotifications();
        };
      }(this), interval);
    },
    markAllAsRead: function () {
      if (!this.get('model')) {
        return;
      }
      this.get('model').forEach(function (notification) {
        notification.set('read_status', 'read');
        return notification.save();
      });
      return this.get('metrics').trackEvent({
        category: 'Notifications',
        action: 'click',
        label: "Mark All As Read"
      });
    }
  });
  var _default = NotificationsService;
  _exports.default = _default;
});
define("pilots/controllers/airmapcallback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AirmapcallbackController;
  AirmapcallbackController = Ember.Controller.extend({
    queryParams: ['flight_id', 'authorizations']
  });
  var _default = AirmapcallbackController;
  _exports.default = _default;
});
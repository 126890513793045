define("pilots/components/pilot-work-location-new", ["exports", "jquery", "pilots/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    pilotWorkLocationNumber: Ember.computed('pilot.stripe_accounts.length', function () {
      return this.get('pilot.stripe_accounts.length') + 1;
    }),
    addButtonDisabled: Ember.computed('selectedLegalEntityID', function () {
      return !this.get('selectedLegalEntityID');
    }),
    actions: {
      onSelect: function (event) {
        this.set('selectedLegalEntityID', event.target.value);
      },
      createPilotStripeAccount: function () {
        return _jquery.default.ajax({
          url: `${_environment.default.api.host}/v1/pilots/stripe_accounts`,
          type: 'POST',
          dataType: 'json',
          data: {
            legal_entity_id: this.get('selectedLegalEntityID')
          },
          headers: this.get('sessionAccount.headers')
        }).then(response => {
          window.open(response.stripe_link, "_self");
        }, response => {
          this.get('rollbar').error('Failed to create StripeAccount', response);
        });
      }
    }
  });
  _exports.default = _default;
});
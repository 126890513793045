define("pilots/components/shot-drop-target", ["exports", "pilots/components/draggable-object-target"], function (_exports, _draggableObjectTarget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ShotDropTarget;
  ShotDropTarget = _draggableObjectTarget.default.extend({
    validateDragEvent: function (event) {
      var shotid;
      shotid = '#shot-' + this.get('shot.id');
      if ($(shotid).hasClass('selected')) {
        return false;
      }
      if (event.dataTransfer.types.length === 3) {
        return true;
      }
      if (event.dataTransfer.effectAllowed === 'move') {
        return true;
      }
      return false;
    }
  });
  var _default = ShotDropTarget;
  _exports.default = _default;
});
define("pilots/components/validated-input-auto-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ValidatedInputAutoSaveComponent;
  ValidatedInputAutoSaveComponent = Ember.Component.extend({
    classNames: ['form-group'],
    saved: false,
    cleanup: Ember.on('willDestroyElement', function () {
      Ember.run.cancel(this._saveLater);
      return Ember.run.cancel(this._saved);
    }),
    actions: {
      validateProperty: function (changeset, property) {
        if (!changeset.get('isDirty')) {
          return;
        }
        if (this._saveLater) {
          Ember.run.cancel(this._saveLater);
        }
        return this._saveLater = Ember.run.later(this, function (_this) {
          return function () {
            return changeset.validate(property).then(function () {
              if (changeset.get('isValid')) {
                return changeset.save().then(function () {
                  _this.set('saved', true);
                  return _this._saved = Ember.run.later(_this, function () {
                    return _this.set('saved', false);
                  }, 1000);
                })["catch"](function (error) {
                  return console.log('failed to save ', property);
                });
              }
            });
          };
        }(this), 1000);
      }
    }
  });
  var _default = ValidatedInputAutoSaveComponent;
  _exports.default = _default;
});
define("pilots/helpers/find-pilot-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let findPilotSelectedHelper = Ember.Helper.helper(function (_ref) {
    let [item, pilotSelectedList, itemName] = _ref;
    let i_name = item.get('name');
    let selectedItem = pilotSelectedList.find(function (pilotItem) {
      return i_name === pilotItem.get(itemName).get('name');
    });
    return selectedItem;
  });
  var _default = findPilotSelectedHelper;
  _exports.default = _default;
});
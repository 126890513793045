define("pilots/components/signup-flow-skip-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      skip() {
        this.skip();
      },
      toggleShowSkipModal() {
        this.toggleShowSkipModal();
      }
    }
  });
  _exports.default = _default;
});
define("pilots/components/pill-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PillListComponent;
  PillListComponent = Ember.Component.extend({
    pillCount: 0,
    selectedIndex: null,
    pillsHash: Ember.computed('count', 'selectedIndex', function () {
      var counter, max, selectedIndex, tempHash;
      counter = 0;
      max = this.get('pillCount');
      selectedIndex = this.get('selectedIndex');
      tempHash = [];
      while (counter < max) {
        tempHash.push(selectedIndex === counter);
        counter += 1;
      }
      return tempHash;
    })
  });
  var _default = PillListComponent;
  _exports.default = _default;
});
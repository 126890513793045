define("pilots/routes/pilot/new", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "pilots/data/iso_country", "pilots/data/travel_distance"], function (_exports, _unauthenticatedRouteMixin, _iso_country, _travel_distance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotRoute;
  PilotRoute = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    setupController: function (controller, model) {
      this._super(controller, model);
      return this.controllerFor('application').set('hideNav', true);
    },
    model: function (params) {
      return Ember.RSVP.hash({
        pilot: this.store.createRecord('pilot'),
        countries: _iso_country.default,
        travelDistance: _travel_distance.default
      });
    }
  });
  var _default = PilotRoute;
  _exports.default = _default;
});
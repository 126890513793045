define("pilots/components/pilot-profile-mission-preferences", ["exports", "pilots/config/environment", "pilots/validations/pilot", "ember-changeset-validations", "ember-changeset"], function (_exports, _environment, _pilot, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PilotProfileMissionPreferencesComponent;
  PilotProfileMissionPreferencesComponent = Ember.Component.extend(_pilot.default, {
    classNames: ['profile-component'],
    metrics: Ember.inject.service(),
    initChangeSet: Ember.on('init', function () {
      return this.changeset = new _emberChangeset.default(this.get('model.pilot'), (0, _emberChangesetValidations.default)(_pilot.default), _pilot.default);
    })
  });
  var _default = PilotProfileMissionPreferencesComponent;
  _exports.default = _default;
});
define("pilots/templates/components/pilot-work-location-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tuQi4+9o",
    "block": "[[[10,0],[14,0,\"item-container work-location\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"title new\"],[12],[1,\"WORK LOCATION \"],[1,[34,0]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"vertical-divider\"],[12],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"work-location-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"work-location-radio-container\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"work-location-radio\"],[12],[1,\"\\n          \"],[1,[28,[35,4],null,[[\"name\",\"id\",\"value\",\"change\"],[\"legal-entity\",[30,1,[\"slug\"]],[30,1,[\"id\"]],[28,[37,5],[[30,0],\"onSelect\"],null]]]]],[1,\"\\n          \"],[10,\"label\"],[14,0,\"work-location-name\"],[15,\"for\",[30,1,[\"slug\"]]],[12],[1,\"\\n            \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"],[41,[30,1,[\"displayCountries\"]],[[[1,\"              \"],[10,1],[14,0,\"countries-list\"],[12],[1,\"(\"],[1,[30,1,[\"displayCountries\"]]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"work-location-radio-actions\"],[12],[1,\"\\n      \"],[8,[39,7],[[16,\"disabled\",[36,8]],[4,[38,5],[[30,0],\"createPilotStripeAccount\"],null]],[[\"@size\"],[\"medium\"]],[[\"default\"],[[[[1,\"\\n        Create Stripe account\\n      \"]],[]]]]],[1,\"\\n\\n      \"],[8,[39,7],[[4,[38,5],[[30,0],[33,9]],null]],[[\"@size\",\"@type\"],[\"medium\",\"text\"]],[[\"default\"],[[[[1,\"\\n        Cancel\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"legalEntity\"],false,[\"pilotWorkLocationNumber\",\"each\",\"-track-array\",\"legalEntitiesOptions\",\"radio-button\",\"action\",\"if\",\"button\",\"addButtonDisabled\",\"hideForm\"]]",
    "moduleName": "pilots/templates/components/pilot-work-location-new.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/profile-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProfileTooltipComponent;
  ProfileTooltipComponent = Ember.Component.extend({
    classNames: 'profile-tooltip',
    showLeftArrow: true,
    showPills: true,
    showCross: true,
    actions: {
      nextAction: function () {
        return this.get('nextAction')();
      },
      doneAction: function () {
        return this.get('doneAction')();
      }
    }
  });
  var _default = ProfileTooltipComponent;
  _exports.default = _default;
});
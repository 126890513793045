define("pilots/templates/components/pilot-profile-drone-system", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IVsMQqEY",
    "block": "[[[10,0],[15,0,[29,[\"drone-system-item item-row \",[52,[51,[33,1,[\"drone\",\"optional_cameras\",\"length\"]]],\"btm-border-line\"]]]],[12],[1,\"\\n  \"],[10,1],[14,0,\"field-name\"],[12],[1,\"DRONE \"],[1,[34,2]],[13],[10,1],[14,0,\"field-spacer\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"drone-system-name\"],[12],[1,\"\\n    \"],[1,[33,1,[\"drone\",\"full_name\"]]],[1,\" \"],[11,1],[24,0,\"delete-me icon-Xmark\"],[4,[38,3],[[30,0],\"destroy\",[33,1]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[33,1,[\"drone\",\"optional_cameras\",\"length\"]],[[[1,\"  \"],[10,0],[14,0,\"drone-system-item item-row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"cameras-container\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"top-arrow\"],[12],[13],[1,\"\\n        \"],[1,[28,[35,5],null,[[\"pilotDrone\"],[[33,1]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"pilotDrone\",\"pilotDroneNumber\",\"action\",\"if\",\"pilot-profile-drone-cameras\"]]",
    "moduleName": "pilots/templates/components/pilot-profile-drone-system.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/templates/components/mission-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8nM0RAqu",
    "block": "[[[41,[28,[37,1],[[33,2,[\"mission_type\"]],\"client\"],null],[[[1,\"  \"],[10,\"img\"],[14,0,\"pin\"],[14,\"src\",\"/assets/images/pilot/client_mission_pin.svg\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"img\"],[14,0,\"pin\"],[14,\"src\",\"/assets/images/training_mission_icon.svg\"],[12],[13],[1,\"\\n\"]],[]]],[10,0],[14,0,\"address\"],[12],[1,\"\\n  \"],[10,0],[12],[1,[33,2,[\"address\"]]],[13],[1,\"\\n  \"],[10,0],[14,0,\"address-distance\"],[12],[1,\"\\n    \"],[1,[33,2,[\"id\"]]],[1,\" | \"],[1,[34,3]],[1,\" from home\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"is-equal\",\"mission\",\"missionDistance\"]]",
    "moduleName": "pilots/templates/components/mission-address.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});
define("pilots/components/input-inplace-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InputInplaceEditComponent;
  InputInplaceEditComponent = Ember.Component.extend({
    classNames: ['form-group', 'form-group-inplace'],
    click: function () {
      if (!this.get('isEditing')) {
        this.set('isEditing', true);
        return Ember.run.scheduleOnce('afterRender', this, this.focusTextField);
      }
    },
    focusTextField: function () {
      var $input, val;
      $input = this.element.querySelectorAll('input, textarea');
      val = $input.val();
      $input.focus();
      $input.val('');
      return $input.val(val);
    },
    flashSuccess: function () {
      return this.element.querySelectorAll('.inline-input').addClass('success');
    },
    actions: {
      save: function () {
        var _this;
        _this = this;
        return this.get('model').save().then(function () {
          if (_this.element.querySelectorAll('input').attr('type') === "password") {
            _this.set('value', null);
          }
          _this.set('isEditing', false);
          return Ember.run.scheduleOnce('afterRender', _this, _this.flashSuccess);
        }, function (response) {
          return _this.element.querySelectorAll('input').addClass('error');
        });
      }
    }
  });
  var _default = InputInplaceEditComponent;
  _exports.default = _default;
});
define("pilots/utils/uploader/file_proxy", ["exports", "plupload"], function (_exports, _plupload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const sanitizeFilename = function (filename, maxFilenameLength) {
    const name = filename.replace(/[^a-zA-Z\d\s\-_\.]/g, '').replace(/\s/g, '_');
    return checkFilenameLength(name, maxFilenameLength);
  };
  const checkFilenameLength = function (name, maxLength) {
    if (name.length > maxLength) {
      const extentionStarts = name.lastIndexOf('.');
      const extentionLength = name.length - extentionStarts;
      const maxNameLength = maxLength - extentionLength;
      return name.substring(0, maxNameLength) + name.substring(extentionStarts, name.length);
    } else {
      return name;
    }
  };
  const FileProxy = Ember.Object.extend({
    rollbar: Ember.inject.service(),
    id: Ember.computed.reads('content.id'),
    name: Ember.computed.alias('content.name'),
    size: Ember.computed.reads('content.size'),
    type: Ember.computed.reads('content.type'),
    uploadNumber: null,
    maxFilenameLength: 200,
    sanitizedName: Ember.computed('content.name', 'uploadNumber', function () {
      return sanitizeFilename(this.get('content.name'), this.maxFilenameLength);
    }),
    thumbnail: null,
    progress: Ember.computed({
      get() {
        return this.get('content.percent');
      }
    }),
    status: Ember.computed({
      get() {
        return this.get('content.status');
      }
    }),
    uploading: Ember.computed('status', function () {
      return this.get('status') === _plupload.default.UPLOADING;
    }),
    notifyPropertyChanges() {
      this.notifyPropertyChange('percent');
      this.notifyPropertyChange('progress');
      this.notifyPropertyChange('status');
    },
    completeUpload(mission_number, shot_id, fileType, authHeaders) {
      this._deferred = Ember.RSVP.defer(`File: '${this.get('id')}' Upload file`);
      if (this.get('status') === _plupload.default.FAILED) {
        this.set('content.status', _plupload.default.QUEUED);
        this.notifyPropertyChange('status');
      }
      this.set('mission_number', mission_number);
      this.set('shot_id', shot_id);
      this.set('file_type', fileType);
      this.set('authHeaders', authHeaders);
      return this._deferred.promise;
    }
  });
  var _default = FileProxy;
  _exports.default = _default;
});
define("pilots/templates/components/pilot-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "EwkgEGCk",
    "block": "[[[10,0],[14,0,\"pilot-details col-sm-3\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"head\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,0,\"pilot-img\"],[14,\"src\",\"/assets/images/v2/Infog_Pilot_Profile_Gray.svg\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"name\"],[12],[1,[33,0,[\"full_name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"fields-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"title\"],[12],[1,\"ID #\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"input\"],[12],[1,[33,0,[\"id\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"fields-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"title\"],[12],[1,\"Based in\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"input\"],[12],[1,[33,0,[\"city\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"title\"],[12],[1,\"License #\"],[13],[1,\"\\n        \"],[10,0],[14,0,\"input\"],[12],[1,[34,1]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[6,[39,2],null,[[\"class\",\"route\"],[\"button-container\",\"profile\"]],[[\"default\"],[[[[1,\"    \"],[8,[39,3],null,[[\"@mode\",\"@type\"],[\"dark\",\"secondary\"]],[[\"default\"],[[[[1,\"Edit profile\"]],[]]]]],[1,\"\\n\"]],[]]]]],[13],[1,\"\\n\"]],[],false,[\"pilot\",\"displayedLicense\",\"link-to\",\"button\"]]",
    "moduleName": "pilots/templates/components/pilot-details.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});